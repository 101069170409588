import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl,
  FormGroupDirective,
  NgForm,
} from "@angular/forms"
import { first } from "rxjs/operators"

import {
  AlertService,
  UserService,
  AuthenticationService,
} from "../../_services"
import { ErrorStateMatcher } from "@angular/material/core"
import { ProfileService } from "../../_services/profile.service"
import { Component, OnInit } from "@angular/core"
import { Router, ActivatedRoute } from "@angular/router"

import { $ } from "protractor"
import { TranslateService, LangChangeEvent } from "@ngx-translate/core"
// For custom Error Validations Messages
export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(
    control: FormControl | null,
    form: FormGroupDirective | NgForm | null
  ): boolean {
    const invalidCtrl = !!(control && control.invalid && control.parent.dirty)
    const invalidParent = !!(
      control &&
      control.parent &&
      control.parent.invalid &&
      control.parent.dirty
    )

    return invalidCtrl || invalidParent
  }
}

@Component({
  selector: "app-dashboard",
  templateUrl: "resetpassword.component.html",
  styleUrls: ["login.scss"],
})
export class ResetPasswordComponent implements OnInit {
  isCheckingValidation: boolean = true
  matcher = new MyErrorStateMatcher() // for my Error state | Colors and other
  loading: boolean = false
  submitted = false
  txt_title: string
  returnUrl: string
  error_current = ""
  current_language
  step0 = true
  step1 = false
  step2 = false
  error_redirect = ""
  permission
  LinkError: string = ""
  // For Login
  Login
  MerchantCode
  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private profileService: ProfileService,
    private route: ActivatedRoute,
    private authenticationService: AuthenticationService,
    private alertService: AlertService,
    public translate: TranslateService
  ) {
    this.ChangePasswordForm = this.formBuilder.group(
      {
        Password: [
          "",
          [
            Validators.required,
            Validators.pattern(
              /^(?=(.*[a-z]){1,})(?=(.*[\d]){1,})(?=(.*[\W]){1,})(?!.*\s).{8,32}$/
            ),
            Validators.minLength(8),
            Validators.maxLength(32),
          ],
        ],
        RepeatPassword: ["", [Validators.required]],
      },
      { validator: this.checkPasswords }
    )
    //console.log("Log init ...");
    //console.log("browser lang is " + translate.getBrowserLang() + " --> " + translate.getBrowserCultureLang());
    translate.addLangs(["en", "tr", "ru"])

    /*
		// translate.setDefaultLang('en');
		if (localStorage.getItem('currentLang')) {
			console.log('Cookie');
			this.current_language = localStorage.getItem('currentLang');
		}
		else {
			this.current_language = translate.getBrowserLang();
		}
        */

    const browserLang = this.route.snapshot.queryParams["l"]
      ? this.route.snapshot.queryParams["l"]
      : "tr" //this.current_language; // This variable change lang
    translate.use(browserLang.match(/en|tr|ru/) ? browserLang : "tr")

    translate.onLangChange.subscribe((event: LangChangeEvent) => {
      console.log(event.translations["HOME"]["TITLE"])
      this.current_language = event.lang
    })
    this.route.queryParams.subscribe((params) => {
      if (typeof params["error"] !== "undefined") {
        this.error_redirect = params["error"]
      }
    })
  }
  // convenience getter for easy access to form fields
  get f() {
    return this.ChangePasswordForm.controls
  }
  ChangePasswordForm: FormGroup

  ngOnInit() {
    //console.log(this.route.snapshot.queryParams);
    // console.log(this.route.snapshot.queryParams['l']);
    // console.log(this.route.snapshot.queryParams.asd);
    // this.translate.use(this.permission.get("l"));

    this.route.queryParamMap.subscribe((queryParams) => {
      this.permission = queryParams
      console.log(this.permission)
      if ((this.permission.keys.length = 3)) {
        this.authenticationService
          .passwordResetCheck(
            this.permission.get("t"),
            this.permission.get("k"),
            this.permission.get("id")
          )
          .pipe(first())
          .subscribe(
            (data) => {
              console.log(data)
              if (data.ResultCode == 0) {
                this.step0 = false
                this.step1 = true
              } else {
                // console.log(data.ResultMessage);
                this.LinkError = data.ResultMessage
              }
              this.isCheckingValidation = false
            },
            (error) => {
              console.log(error)
              this.isCheckingValidation = false
            }
          )
      }
    })
  }

  checkPasswords(group: FormGroup) {
    // here we have the 'passwords' group and check repeat password | Custom validation
    let password = group.controls.Password.value
    let repeatpassword = group.controls.RepeatPassword.value

    return password === repeatpassword ? null : { notSame: true }
  }
  recoveryError: string = ""
  showError: boolean = false
  onSubmit() {
    console.log(this.ChangePasswordForm)
    this.ChangePasswordForm.markAsTouched
    this.submitted = true
    this.loading = true
    this.recoveryError = ""

    console.log(this.ChangePasswordForm)
    // stop here if form is invalid
    if (this.ChangePasswordForm.invalid) {
      this.loading = false
      return
    }
    this.error_current = ""
    this.authenticationService
      .changePassword(
        this.f.Password.value,
        this.f.RepeatPassword.value,
        this.permission.get("t"),
        this.permission.get("k"),
        this.permission.get("id")
      )
      .subscribe(
        (data) => {
          console.log(data)
          if (data.ResultCode == 0) {
            this.step1 = false
            this.step2 = true
            this.Login = data.ReturnObject.Login
            this.MerchantCode = data.ReturnObject.MerchantCode
            this.loading = false
          } else {
            this.showError = true
            let _error = ""
            this.translate.get("RECOVER.DEFAULT_ERROR").subscribe((text) => {
              _error = text
            })
            // console.log(data.ResultMessage);
            this.recoveryError = data.ResultMessage
              ? data.ResultMessage
              : _error
          }
          this.isCheckingValidation = false
        },
        (error) => {
          console.log(error)
          this.isCheckingValidation = false
          this.showError = true
        }
      ),
      () => {
        this.loading = false
      }
  }
  login() {
    this.loading = true // uncomment
    console.log("You are login in")
    this.authenticationService
      .login(
        this.Login,
        this.f.Password.value,
        this.current_language,
        this.MerchantCode,
        false
      )
      .pipe(first())
      .subscribe(
        (data) => {
          console.log(data)
          if (data.resultCode === 0) {
            //-------------------- clear data from localStore --
            localStorage.removeItem("providerTop")
            localStorage.removeItem("providerGroup")
            this.loading = false
            this.router.navigate(["/dashboard"]) // Uncomment this after...
          } else {
            //  alert("error code --> " + data.responseCode);
            this.loading = false
            this.router.navigate(["/login"]) // Uncomment this after...
          }
        },
        (error) => {
          console.log(error)
          this.loading = false
          this.router.navigate(["/login"]) // Uncomment this after...
        }
      )
  }
}
