import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";

// Import Containers
import { DefaultLayoutComponent } from "./containers";

import { P404Component } from "./views/error/404.component";
import { P500Component } from "./views/error/500.component";
import { LoginComponent } from "./views/login/login.component";
import { RegisterComponent } from "./views/register/register.component";

import { AuthGuard } from "./_guards";
import { PaymentPageComponent } from "./views/payments/payment-page.component";
import { TransactionReportComponent } from "./views/reports/transaction-report.component";
import { PaymentStepperComponent } from "./views/payments/payment-stepper.component";
import { SystemRequestComponent } from "./views/reports/system-request.component";
import { ProblemPaymentsComponent } from "./views/reports/problem-payments.component";
import { DepositOperationsComponent } from "./views/reports/deposit-operations.component";
import { PaymentCancelComponent } from "./views/payments/payment-cancel.component";
import { RoleGuard } from "./_guards/role.guard";
import { RecoveryComponent } from "./views/login/recovery.component";
import { NotificationsComponent } from "./views/settings/notifications.component";
import { NewPasswordComponent } from "./views/settings/newpassword.component";
import { ResetPasswordComponent } from "./views/login/resetpassword.component";
import { ProfileComponent } from "./views/settings/profile.component";
import { BankComponent } from "./views/settings/bank.component";
import { RatingComponent } from "./views/settings/rating/rating.component";


import { AccountStatementsComponent } from "./views/transactions/accountstatements.component";
import { PaymentsHistoryComponent } from "./views/transactions/paymentshistory.component";
import { WithdrawComponent } from "./views/withdraw/withdraw.component";
import { WithdrawReportComponent } from "./views/withdraw/withdraw-report.component";
import { WithdrawSettingsComponent } from "./views/withdraw/withdraw-settings.component";
import { RefundsReportComponent } from "./views/transactions/refunds-report.component";
import { InvoiceCreateComponent } from "./views/invoice/invoice-create.component";
import { InvoiceDashboardComponent } from "./views/invoice/invoice-dashboard.component";
import { InvoiceHistory } from "./views/invoice/invoice-history.component";
import { InvoicePayment } from "./views/invoice/invoice-payment.component";
import { InvoiceRefunds } from "./views/invoice/invoice-refunds.component";
import { InvoiceDrafts } from "./views/invoice/invoice-drafts.component";
import { InvoiceContacts } from "./views/invoice/invoice-contacts.component";
import { SalesOperationsComponent } from "./views/sales/sales-operations/sales-operations.component";
import { SalesHistoryComponent } from "./views/sales/sales-history/sales-history.component";
import { OrderHistoryComponent } from "./views/transactions//order-history/order-history.component";
import { StatementComponent } from "./views/transactions/statement/statement.component";
import { AcquiringReportComponent } from "./views/transactions/acquiring-report/acquiring-report.component";

import { CanDeactivateGuard } from "./_guards/deactivate.guard";

export const routes: Routes = [
  // {
  // 	path: 'settings',
  // 	redirectTo: 'change-password'
  // },
  {
    path: "",
    redirectTo: "dashboard",
    pathMatch: "full",
    data: {
      title: "Dashboard",
    },
  },
  {
    path: "404",
    component: P404Component,
    data: {
      title: "Page 404",
    },
  },
  {
    path: "500",
    component: P500Component,
    data: {
      title: "Page 500",
    },
  },
  {
    path: "login",
    component: LoginComponent,
    data: {
      title: "Login Page",
    },
  },
  {
    path: "reset-password",
    component: ResetPasswordComponent,
    data: {
      title: "Reset Password",
    },
  },
  {
    path: "recovery",
    component: RecoveryComponent,
    data: {
      title: "Recovery Page",
    },
  },
  {
    path: "register",
    component: RegisterComponent,
    data: {
      title: "Register Page",
    },
  },
  {
    path: "",
    component: DefaultLayoutComponent,
    data: {
      title: "Home",
    },
    children: [
      {
        path: "change-password",
        component: NewPasswordComponent,
        canActivate: [AuthGuard],
        data: {
          title: "Change Password",
        },
      },
      {
        path: "account-statements",
        canActivate: [AuthGuard],
        component: AccountStatementsComponent,
        data: {
          pathCheck: "account_statements",
          title: "Account Statements",
        },
      },
      {
        path: "payments-history",
        canActivate: [AuthGuard],
        component: PaymentsHistoryComponent,
        data: {
          pathCheck: "payment_history",
          title: "Payments History",
        },
      },
      {
        path: "order-history",
        canActivate: [AuthGuard],
        component: OrderHistoryComponent,
        data: {
          pathCheck: "payment_history",
          title: "Order History",
        },
      },
      {
        path: "statement",
        canActivate: [AuthGuard],
        component: StatementComponent,
        data: {
          pathCheck: "account_statements",
          title: "Statement",
        },
      },
      {
        path: "acquiring-report",
        canActivate: [AuthGuard],
        component: AcquiringReportComponent,
        data: {
          // pathCheck: "payment_history",
          title: "Acquiring Report",
        },
      },
      {
        path: "invoice-create",
        canActivate: [AuthGuard],
        component: InvoiceCreateComponent,
        canDeactivate: [CanDeactivateGuard],
        data: {
          title: "Invoicing",
          pathCheck: "invoice",
        },
      },
      {
        path: "invoice-create/:id",
        canActivate: [AuthGuard],
        component: InvoiceCreateComponent,
        canDeactivate: [CanDeactivateGuard],
        data: {
          title: "Invoicing",
          pathCheck: "invoice",
        },
      },
      {
        path: "invoice-dashboard",
        component: InvoiceDashboardComponent,
        canActivate: [AuthGuard],
        data: {
          title: "Invoice Dashboard",
          pathCheck: "invoice",
        },
      },
      {
        path: "invoice-history",
        component: InvoiceHistory,
        canActivate: [AuthGuard],
        data: {
          title: "Invoice History",
          pathCheck: "invoice",
        },
      },
      {
        path: "invoice-payment",
        component: InvoicePayment,
        canActivate: [AuthGuard],
        data: {
          title: "Invoice Payment",
          pathCheck: "invoice",
        },
      },
      {
        path: "invoice-drafts",
        component: InvoiceDrafts,
        canActivate: [AuthGuard],
        data: {
          title: "Invoice Drafts",
          pathCheck: "invoice",
        },
      },
      {
        path: "contacts",
        component: InvoiceContacts,
        canActivate: [AuthGuard],
        data: {
          title: "Contacts",
          pathCheck: "contact",
        },
      },
      {
        path: "qr-operations",
        component: SalesOperationsComponent,
        canActivate: [AuthGuard],
        data: {
          title: "QR Operations",
          // pathCheck: "sales",
        },
      },
      {
        path: "sales-history",
        component: SalesHistoryComponent,
        canActivate: [AuthGuard],
        data: {
          title: "Sales History",
          // pathCheck: "sales",
        },
      },
      {
        path: "profile",
        canActivate: [AuthGuard],
        component: ProfileComponent,
        data: {
          title: "Profile Page",
        },
      },
      {
        path: "rating",
        canActivate: [AuthGuard],
        component: RatingComponent,
        data: {
          title: "Rating Page",
        },
      },
      {
        path: "bank-details",
        canActivate: [AuthGuard],
        component: BankComponent,
        data: {
          title: "Bank Details Information",
        },
      },
      {
        path: "refunds-report",
        canActivate: [AuthGuard],
        component: RefundsReportComponent,
        data: {
          pathCheck: "refunds_report",
          title: "Refunds Report",
        },
      },
      // {
      // 	path: 'payment/:id',
      // 	canActivate: [AuthGuard],
      // 	component: PaymentStepperComponent,
      // 	data: {
      // 		title: 'Payment Stepper Page'
      // 	},
      // },
      {
        path: "notifications",
        component: NotificationsComponent,
        canActivate: [AuthGuard],
        data: {
          title: "Notifications",
        },
      },
      {
        path: "withdraw",
        component: WithdrawComponent,
        canActivate: [AuthGuard],
        data: {
          pathCheck: "withdraw",
          title: "Withdraw",
        },
      },
      {
        path: "withdraw-report",
        component: WithdrawReportComponent,
        canActivate: [AuthGuard],
        data: {
          pathCheck: "withdraw_report",
          title: "Withdraw Report",
        },
      },
      //{
      //	path: 'withdraw-settings',
      //	component: WithdrawSettingsComponent,
      //	canActivate: [AuthGuard],
      //	data: {
      //		pathCheck: 'withdraw_settings',
      //		title: 'Withdraw Settings'
      //	},
      //},
      {
        path: "base",
        loadChildren: "./views/base/base.module#BaseModule",
        canActivate: [AuthGuard],
      },
      {
        path: "buttons",
        loadChildren: "./views/buttons/buttons.module#ButtonsModule",
        canActivate: [AuthGuard],
      },
      {
        path: "charts",
        loadChildren: "./views/chartjs/chartjs.module#ChartJSModule",
        canActivate: [AuthGuard],
      },
      {
        path: "dashboard",
        loadChildren: "./views/dashboard/dashboard.module#DashboardModule",
        canActivate: [AuthGuard],
        data: {
          title: "Dashboard",
        },
      },
      {
        path: "icons",
        loadChildren: "./views/icons/icons.module#IconsModule",
        canActivate: [AuthGuard],
      },
      {
        path: "notifications",
        loadChildren:
          "./views/notifications/notifications.module#NotificationsModule",
        canActivate: [AuthGuard],
      },
      {
        path: "theme",
        loadChildren: "./views/theme/theme.module#ThemeModule",
        canActivate: [AuthGuard],
      },
      {
        path: "widgets",
        loadChildren: "./views/widgets/widgets.module#WidgetsModule",
        canActivate: [AuthGuard],
      },
    ],
  },
]

export const routing = RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' });
