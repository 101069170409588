import { Component, OnInit, ViewChild, Input } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import {
  FormBuilder,
  FormGroup,
  FormControl,
  Validators,
  AbstractControl,
  ValidatorFn,
  FormGroupDirective,
  NgForm,
} from "@angular/forms";
import { Observable } from "rxjs";
import { map, startWith, first } from "rxjs/operators";
import { AlertService, UserService, ProviderService } from "../../_services";
import { DataSource } from "@angular/cdk/table";
import { DatePipe } from "@angular/common";
/** Constants used to fill up our data base. */
import * as SecureLS from "secure-ls";
import { SettingsService } from "../../_services/settings.service";
import { TranslateService } from "@ngx-translate/core";
import { ToastrService } from "ngx-toastr";
import { Globals } from "../../globals/globals";
import { environment } from "../../../environments/environment";

@Component({
  templateUrl: "profile.component.html",
  styleUrls: ["settings.scss"],
  providers: [DatePipe, Globals],
})
export class ProfileComponent implements OnInit {
  basUrl: string = environment.apiUrl;

  private markFormGroupTouched(formGroup: FormGroup) {
    (<any>Object).values(formGroup.controls).forEach((control) => {
      control.markAsTouched();

      if (control.controls) {
        this.markFormGroupTouched(control);
      }
    });
  }
  constructor(
    public translate: TranslateService,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private settingsService: SettingsService,
    public toastr: ToastrService,
    public datePipe: DatePipe,
    private globals: Globals
  ) {}
  loading: boolean = false;
  Info;
  submitted: boolean = false;
  image_error = "";
  image_error_user = "";
  profile_error = "";
  loading_page: boolean = false;
  ProfileInfo: FormGroup;
  serviceProviders;
  Languages = [{ Key: "", Name: "" }];
  uploadNow = false; // state when photo is uploading
  uploadNowMerchant = false; // state when photo is uploading
  saveProfile() {
    this.markFormGroupTouched(this.ProfileInfo);
    this.profile_error = "";
    this.submitted = true;
    if (this.ProfileInfo.valid === true) {
      this.submitted = false;
      console.log(this.ProfileInfo.value);
      this.loading = true;
      this.settingsService
        .changePersonalDatas(this.ProfileInfo.value)
        .pipe(first())
        .subscribe(
          (data) => {
            console.log(data);
            if (data.ResultCode === 0) {
              let theme = "";
              let body = "";
              this.translate
                .get("PROFILE.THEME_FORM_SUCCESS")
                .subscribe((text) => {
                  theme = text;
                });
              this.translate
                .get("PROFILE.BODY_FORM_SUCCESS")
                .subscribe((text) => {
                  body = text;
                });
              this.toastr.success(body, theme);
            } else {
              data.ResultMessage === null
                ? (this.profile_error = "")
                : (this.profile_error = data.ResultMessage);
              let theme = "";
              this.translate
                .get("PROFILE.THEME_FORM_ERROR")
                .subscribe((text) => {
                  theme = text;
                });
              this.toastr.error(this.profile_error, theme);
            }
            this.loading = false;
          },
          (error) => {
            this.loading = false;
            console.log(error);
          }
        );
    }
  }
  previewImageUser(event) {
    if (event.target.files.length) {
      let file = event.target.files[0];
      this.image_error_user = "";
      this.uploadNow = true;
      if (file.type !== "image/png" && file.type != "image/jpeg") {
        this.translate.get("PROFILE.IMG_FORMAT_ERROR").subscribe((text) => {
          this.image_error_user = text;
        }); // Text in component
        this.uploadNow = false;
      } else if (file.size > 524288) {
        this.translate.get("PROFILE.IMG_SIZE_ERROR").subscribe((text) => {
          this.image_error_user = text;
        }); // Text in component
        this.uploadNow = false;
      } else {
        let type = file.type;
        let reader: any;
        target: EventTarget;
        reader = new FileReader();

        reader.readAsDataURL(event.target.files[0]); // read file as data url
        reader.onload = (event) => {
          // called once readAsDataURL is completed
          let code = event.target.result.split(",")[1];
          this.settingsService
            .saveImage(type, code)
            .pipe(first())
            .subscribe(
              (data) => {
                console.log(data);
                if (data.ResultCode === 0) {
                }
              },
              (error) => {
                console.log(error);
                this.uploadNow = false;
              },
              () => {
                this.uploadNow = false;
                (<HTMLImageElement>document.getElementById("preview")).src =
                  "data:" + type + ";base64," + code;
                (<HTMLImageElement>document.getElementById("user-avatar")).src =
                  "data:" + type + ";base64," + code;
              }
            );
        };
      }
    }
  }
  getProfileSettings() {
    this.settingsService
      .getLanguages()
      .pipe(first())
      .subscribe(
        (data) => {
          console.log(data);
          if (data.ResultCode === 0) {
            this.Languages = data.ReturnObject;
          }
        },
        (error) => {
          console.log(error);
        },
        () => {
          this.settingsService
            .getProfileSettings()
            .pipe(first())
            .subscribe(
              (data) => {
                console.log(data);
                if (data.ResultCode === 0) {
                  this.ProfileInfo.patchValue({
                    ["Login"]: data.ReturnObject.User.Login,
                  });
                  this.ProfileInfo.patchValue({
                    ["Name"]: data.ReturnObject.User.Name,
                  });
                  this.ProfileInfo.patchValue({
                    ["Email"]: data.ReturnObject.User.Email,
                  });
                  this.ProfileInfo.patchValue({
                    ["UserMobile"]: data.ReturnObject.User.UserMobile,
                  });
                  this.ProfileInfo.patchValue({
                    ["UsersJob"]: data.ReturnObject.User.UsersJob,
                  });
                  this.ProfileInfo.patchValue({
                    ["Language"]: data.ReturnObject.User.UserLanguage,
                  });
                }
              },
              (error) => {
                console.log(error);
              },
              () => {
                this.getAccountSettings();
              }
            );
        }
      );
  }

  AccountInfo: FormGroup;
  previewImage(event) {
    if (event.target.files.length) {
      let file = event.target.files[0];
      this.image_error = "";
      this.uploadNowMerchant = true;
      if (file.type !== "image/png" && file.type != "image/jpeg") {
        this.translate.get("PROFILE.IMG_FORMAT_ERROR").subscribe((text) => {
          this.image_error = text;
        }); // Text in component
        this.uploadNowMerchant = false;
      } else if (file.size > 524288) {
        this.translate.get("PROFILE.IMG_SIZE_ERROR").subscribe((text) => {
          this.image_error = text;
        }); // Text in component
        this.uploadNowMerchant = false;
      } else {
        let type = file.type;
        let reader: any;
        target: EventTarget;
        reader = new FileReader();

        reader.readAsDataURL(event.target.files[0]); // read file as data url
        reader.onload = (event) => {
          // called once readAsDataURL is completed
          let code = event.target.result.split(",")[1];
          this.settingsService
            .saveImageMerchant(this.Info.Merchant.Key, type, code)
            .pipe(first())
            .subscribe(
              (data) => {
                console.log(data);
                if (data.ResultCode === 0) {
                } else {
                  data.ResultMessage === null
                    ? (this.image_error = "")
                    : (this.image_error = data.ResultMessage);
                }
              },
              (error) => {
                console.log(error);
                this.uploadNowMerchant = false;
              },
              () => {
                this.uploadNowMerchant = false;
                (<HTMLImageElement>(
                  document.getElementById("preview_merchant")
                )).src = "data:" + type + ";base64," + code;
                (<HTMLImageElement>(
                  document.getElementById("merchant-avatar")
                )).src = "data:" + type + ";base64," + code;
              }
            );
        };
      }
    }
  }
  getAccountSettings() {
    this.settingsService
      .getAccountSettings()
      .pipe(first())
      .subscribe(
        (data) => {
          console.log(data);
          if (data.ResultCode === 0) {
            this.AccountInfo.patchValue({ ["Code"]: data.ReturnObject.Code });
            this.AccountInfo.patchValue({ ["IDNO"]: data.ReturnObject.IDNO });
            this.AccountInfo.patchValue({
              ["Description"]: data.ReturnObject.Description,
            });
            this.AccountInfo.patchValue({ ["Name"]: data.ReturnObject.Name });
            this.AccountInfo.patchValue({
              ["RegDate"]: this.datePipe.transform(
                data.ReturnObject.RegDate,
                this.globals.dateType
              ),
            });
            this.AccountInfo.patchValue({
              ["Contacts_Address"]: data.ReturnObject.Address,
            });
            this.AccountInfo.patchValue({
              ["Contacts_Email"]: data.ReturnObject.Member.Email,
            });
            this.AccountInfo.patchValue({
              ["Contacts_JobTitle"]: data.ReturnObject.Member.JobTitle,
            });
            this.AccountInfo.patchValue({
              ["Contacts_MobileNumber"]: data.ReturnObject.Member.MobileNumber,
            });
            this.AccountInfo.patchValue({
              ["Contacts_FirstName"]: data.ReturnObject.Member.Name,
            });
            this.AccountInfo.patchValue({
              ["Contacts_Surname"]: data.ReturnObject.Member.Surname,
            });
          }
          this.loading_page = true;
        },
        (error) => {
          console.log(error);
          this.loading_page = true;
        }
      );
  }

  ngOnInit() {
    this.ProfileInfo = this.formBuilder.group({
      Login: [{ value: "", disabled: true }, Validators.required],
      Name: ["", Validators.required],
      Email: [
        "",
        [
          Validators.required,
          Validators.pattern(
            /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          ),
        ],
      ],
      UserMobile: ["", [Validators.required, Validators.pattern(/^([0-9]*)$/)]],
      UsersJob: ["", Validators.required],
      Language: ["", Validators.required],
    });
    let ls = new SecureLS(); // for Secure Local storage
    if (ls.get("info") !== null) {
      this.Info = ls.get("info");
    }
    this.AccountInfo = this.formBuilder.group({
      Code: [{ value: "", disabled: true }],
      IDNO: [{ value: "", disabled: true }],
      Description: [{ value: "", disabled: true }],
      Name: [{ value: "", disabled: true }],
      RegDate: [{ value: "", disabled: true }],
      Contacts_Address: [{ value: "", disabled: true }],
      Contacts_Email: [{ value: "", disabled: true }],
      Contacts_JobTitle: [{ value: "", disabled: true }],
      Contacts_MobileNumber: [{ value: "", disabled: true }],
      Contacts_FirstName: [{ value: "", disabled: true }],
      Contacts_Surname: [{ value: "", disabled: true }],
    });
    this.getProfileSettings();
  }
}
