import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, FormControl, Validators, FormArray } from '@angular/forms';
import { first } from 'rxjs/operators';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { AlertService, UserService, PaymentService } from '../../_services';
import { MatStepper } from '@angular/material/stepper';
import { MatDialog } from "@angular/material/dialog";
import { fileURLToPath } from 'url';
import { saveAs } from 'file-saver';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
// import { load } from '@angular/core/src/render3';
@Component({
	templateUrl: 'payment-stepper.component.html',
	styleUrls: ['payment-page.scss']
})
export class PaymentStepperComponent implements OnInit {
	productId: number; // Parameter for initialize custom page
	isLoading: boolean = false; // Disable page when is loading datas
	firstFormGroup: FormGroup; // stepper
	secondFormGroup: FormGroup; // stepper
	isDownloadingFile: boolean = false; // loader for download file
	isCompleted = false;
	transactionDatas = [];

	current_account;

	loading: boolean = false; // loading response (false - disable button)

	error_text1; // for display Errors
	error_text2; // for display Errors

	step1_successful = false; // for road on steps with good datas
	step2_successful = false; // for road on steps with good datas
	step3_successful = false; // for road on steps with good datas

	constructor(public translate: TranslateService, private _formBuilder: FormBuilder, public dialog: MatDialog, private router: Router, private route: ActivatedRoute, public paymentService: PaymentService) {
		this.productId = Number(this.route.snapshot.paramMap.get('id'));
		translate.onLangChange.pipe(untilDestroyed(this)).subscribe((event: LangChangeEvent) => {
			// location.reload();
			console.log(this.router.onSameUrlNavigation);
			let target: any = this.route;
			this.redirectTo(target.url.value[0].path, this.productId);
			// this.router.navigate([target.url.value[0].path], { queryParams: { id: this.productId } });
		})
	}


	redirectTo(uri, params) {
		this.router.onSameUrlNavigation = 'ignore';
		if (params) {
			this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
				this.router.navigate([uri], { queryParams: { id: params } }));
		}
		else {
			this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
				this.router.navigate([uri]));
		}
	}



	final_vouchers = [];
	modal_image_link = 'https://oplata.md/Content/Images/contor-603.png';
	info = {
		'Description': '',
		'Company': '',
		'helpImage': '',
		'Account': '',
		'Info': '',
		'Price': '',
		'Commision': '',
		'PriceToPay': '',
		'OperationId': '',
		'ResultMessage': '',
		'ResultCode': ''
	}; // for use Datas in html

	services = [];

	step1Info = []; // for use Datas in steps
	step2Info = []; // for use Datas in steps
	step2Counter = null; // Counter in step 2

	// get Datas from api
	getFirstDatas() {
		this.paymentService.getProductPage(this.productId)
			.pipe(first())
			.subscribe(
				data => {
					console.log(data);
					if (data.ResultCode === 0) {
						return this.initializeDatas(data.ReturnObject);
					}
					else {
						this.router.navigate(['/payment']);
					}
				},
				error => {
					console.log(error);
					this.router.navigate(['/payment']);
				});
	}
	onTypeChange(searchValue, formValue, max, step, mask, percentage, commisionType) {
		if (searchValue > max || mask === 0) {
			this.secondFormGroup.patchValue({ [formValue]: max });
			searchValue = max;
		}
		if ((searchValue * 100) % (step * 100)) {
			this.secondFormGroup.patchValue({ [formValue]: Math.round(searchValue) });
			// searchValue = Math.round(searchValue);
		}
		if (commisionType === 2) {
			this.info.Commision = (searchValue / 100 * percentage).toFixed(2);
		}
	}

	convertOnBlur(searchValue, formValue, min, max) {
		if (searchValue > 0) {
			this.secondFormGroup.patchValue({ [formValue]: parseFloat(searchValue).toFixed(2) });
		}
	}

	initializeDatas(data) {
		console.log(data);
		// Array from api
		this.info = {
			'Description': data.Description,
			'Company': data.Name,
			'helpImage': '/api/content/getimage/' + data.Key,
			'Account': '',
			'Price': '',
			'Info': '',
			'Commision': '',
			'PriceToPay': '',
			'OperationId': '',
			'ResultMessage': '',
			'ResultCode': ''
		}; // global info


		var steps_datas = []; // initialize
		if (typeof (data.Services[0].Fields) !== 'undefined') {
			// Array from api
			for (let i = 0; i < data.Services[0].Fields.length; i++) {
				steps_datas.push({
					'InputType': data.Services[0].Fields[i].InputType,
					'Name': data.Services[0].Fields[i].Name,
					'defaultValue': '',
					'Description': data.Services[0].Fields[i].Description,
					'variants': null,
					'min': 8,
					'max': 8,
					'required': true,
					'pattern': '([7][986])|([6][012789])\d{6}',
					'validationInput': [],
					'SubServiceID': 0
				});
			}
		}

		if (data.Services.length > 1) {
			for (let i = 0; i < data.Services.length; i++) {
				this.services.push(data.Services[i]);
			}
			this.firstFormGroup.addControl('speciaList1', new FormControl('', Validators.required)); // Initialize form rules
		}
		// Push to array for html
		for (let i = 0; i < steps_datas.length; i++) {
			this.step1Info.push(steps_datas[i]); // push array from test datas(API)
			// Pushing Rules from api
			steps_datas[i].validationInput.push(Validators.required);
			// if (typeof (steps_datas[i].min) === 'number') {
			// 	steps_datas[i].validationInput.push(Validators.minLength(steps_datas[i].min));
			// }
			// if (typeof (steps_datas[i].max) === 'number') {
			// 	steps_datas[i].validationInput.push(Validators.maxLength(steps_datas[i].max));
			// }
			// if (steps_datas[i].pattern.length) {
			// 	steps_datas[i].validationInput.push(Validators.pattern(steps_datas[i].pattern));
			// }
			this.firstFormGroup.addControl(this.step1Info[i].InputType, new FormControl(this.step1Info[i].defaultValue, this.step1Info[i].validationInput)); // Initialize form rules

		}
		if (!this.step1Info.length && !this.services.length) {
			this.loading = true; // if fields no exists disable button
		}
		this.isLoading = true;
		// Disable role tabs tabindex
		setTimeout(() => {
			this.disableTabIndex();
		}, 300);
	}

	// Disable bug with tab roaming on kyeboard
	disableTabIndex(){
        let target = document.querySelectorAll('.mat-horizontal-stepper-header') as NodeListOf<HTMLElement>;
		for(let i = 0; i < target.length; i++){
			target[i].setAttribute('tabindex','-1');
		}
	}

	ngOnInit() {
		
		if (isNaN(this.productId)) {
			this.router.navigate(['/payment']);
			// redirect when id is incorrect or doesn't exist
		}
		if (!isNaN(this.productId)) {
			this.getFirstDatas();
			// Make response
		}
		// Initialize forms for steps
		// Initialize form inputs with validations

		this.firstFormGroup = this._formBuilder.group({
		}); // initialize first step form

		this.secondFormGroup = this._formBuilder.group({
			// price: ['', [Validators.required, Validators.minLength(1), Validators.maxLength(4), Validators.pattern(/^[1-9][0-9]{0,3}$/)]]
			// secondCtrl: ['', Validators.required]
		});
	}
	previous_datas_step1; // check for disable sending more requests
	previous_datas_step2; // check for disable sending more requests
	// For hide code(HTML) in steps when he is'nt activate
	stepIndex: number = 0;
	goForward(stepper: MatStepper) {
		let CurrentStep = stepper.selectedIndex + 1;
		this.checkSteps(CurrentStep);
		console.log(CurrentStep);
		// stepper.next();
	}
	goBack(stepper: MatStepper) {
		let CurrentStep = stepper.selectedIndex - 1;
		this.stepIndex = CurrentStep;
		stepper.previous();
		console.log(CurrentStep);
	}

	// Test (keyup function) for Amount input
	FinalDatas: object = {
		"SubService": null,
		"Account": null,
		"RefStan": null,
		"BillNumber": null,
		"Amount": null,
		"Commission": '',
		"Items": []
	};
	checkSteps(step) {
		if (step === 1) {
			this.firstFormGroup.markAsTouched; // Check form inputs with red marker
			if (this.firstFormGroup.valid === true) {
				this.loading = true;
				if (JSON.stringify(this.previous_datas_step1) !== JSON.stringify(this.firstFormGroup.value)) {
					this.isDownloadingFile = true;
					this.previous_datas_step1 = this.firstFormGroup.value;
					// Make api
					let item = [];
					// let itemsLength = Object.keys(this.firstFormGroup.value).length;
					for (let key in this.firstFormGroup.value) {
						// item[key] = this.firstFormGroup.value[key];
						if (key !== "speciaList1") {
							item.push({ "key": key, "value": this.firstFormGroup.value[key] });
						}
					}
					let subservice = 0;
					if (this.services.length) {
						subservice = this.firstFormGroup.value.speciaList1;
					}
					console.log(item);
					this.paymentService.checkPayment(this.productId, subservice, item)
						.pipe(first())
						.subscribe(
							data => {
								if (data.ResultCode === 0) {
									// Good
									// Correct Datas
									// Settings
									this.step1_successful = true;
									this.stepIndex = step; // hide content on if
									this.stepper.next(); // make next step
									this.loading = false; // disable button
									this.error_text1 = ''; // error message
									// Create second form
									let fields = data.ReturnObject.AmountMask;
									let commision = data.ReturnObject.Commission;
									let items = data.ReturnObject.Items;
									console.log(data);
									console.log(this.info);
									this.info.Account = data.ReturnObject.Account; // for display number at step 2
									this.info.Info = data.ReturnObject.Info; // for display info at step 2
									this.info.OperationId = data.ReturnObject.RefStan;
									// Push to array for html
									this.step2Info = [];
									this.step2Info = [
										{
											'InputType': 'amount',
											'Name': 'Amount', // to put this in html, use this array
											// 'defaultValue': '',
											'Mask': fields.Mask,
											'Percentage': commision.Value,
											'CommisionType': commision.Type,
											'defaultValue': fields.Amount / 100,
											'MinValue': fields.Min / 100,
											'MaxValue': fields.Max / 100,
											'Step': fields.Step / 100,
											'Description': 'Please insert correct money',
											'variants': null,
											'pattern': '',
											'validationInput': [],
											// 'SubServiceID': converted_datas_fields.SubServiceID
											// 'Counter': null,
											// 'Old_Counter': null,
										},
									]; // remove previous form of this step
									this.step2Counter = null;
									if (commision.Type !== 2) {
										this.info.Commision = (commision.Value / 100).toFixed(2);
									}
									else {
										this.info.Commision = ((fields.Amount / 100) / 100 * (commision.Value / 100)).toFixed(2);
									}

									if (items !== null) {
										this.step2Counter = [];
										for (let i = 0; i < items.length; i++) {
											console.log(items);
											this.step2Counter.push({ 'Counter': items[i].InputType + i, 'nrCounter': items[i].FieldName, 'ValueCounter': items[i].Value });
											this.secondFormGroup.addControl(items[i].InputType + i, new FormControl('', [Validators.required, Validators.min(items[i].Value)]));
										}
									}

									this.FinalDatas = { // for final datas to service
										'Account': data.ReturnObject.Account,
										'BillNumber': data.ReturnObject.BillNumber,
										'RefStan': data.ReturnObject.RefStan,
										"Items": [],
										'SubService': subservice
									}
									// this.step2Info.push(steps2_datas[i]); // push array from test datas(API)
									// Pushing Rules from api
									this.step2Info[0].validationInput.push(Validators.required);
									this.step2Info[0].validationInput.push(Validators.min(this.step2Info[0].MinValue));
									this.step2Info[0].validationInput.push(Validators.max(this.step2Info[0].MaxValue));
									this.secondFormGroup.removeControl(this.step2Info[0].InputType);
									if (this.step2Info[0].defaultValue > 0) {
										this.secondFormGroup.addControl(this.step2Info[0].InputType, new FormControl(this.step2Info[0].defaultValue.toFixed(2), this.step2Info[0].validationInput)); // Initialize form rules
										this.secondFormGroup.patchValue({ [this.step2Info[0].InputType]: this.step2Info[0].defaultValue.toFixed(2) });
									}
									else {
										this.secondFormGroup.addControl(this.step2Info[0].InputType, new FormControl('', this.step2Info[0].validationInput)); // Initialize form rules
										// this.secondFormGroup.patchValue(this.step2Info[i].InputType, new FormControl('', this.step2Info[i].validationInput)); // Initialize form rules
									}
								}
								else {
									// Not Good
									this.step1_successful = false;
									// Wrong Datas
									setTimeout(() => {
										this.loading = false;
									}, 3000);
									this.error_text1 = data.ResultMessage;
								}
								console.log(data);
								this.isDownloadingFile = false;
							},
							error => {
								console.log(error);
								this.isDownloadingFile = false;
							});
				}
				else {
					// Correct datas and are identical
					if (this.step1_successful === true) {
						this.stepIndex = step;
						this.stepper.next();
						this.loading = false;
						this.error_text1 = '';
					}
					else {
						// Wrong datas
						this.error_text1 = this.step1Info[0].Description;
						setTimeout(() => {
							this.loading = false;
						}, 3000);
					}
					console.log('Datas are identical');
				}
			}
			else {
				this.error_text1 = this.step1Info[0].Description;
			}
		}
		if (step === 2) {
			console.log(this.secondFormGroup.value);
			this.secondFormGroup.markAsTouched; // Check form inputs with red marker
			if (this.secondFormGroup.valid === true) {
				this.loading = true; // disable button Next
				console.log(this.secondFormGroup);
				this.stepIndex = step;
				this.stepper.next();
				this.loading = false;
				this.error_text2 = '';
				// display in HTML
				this.info.Price = this.secondFormGroup.value.amount;
				// this.info.PriceToPay = this.secondFormGroup.value.amount + this.FinalDatas.step2Info;
				this.info.PriceToPay = (Number(this.secondFormGroup.value.amount) + Number(this.info.Commision)).toFixed(2);
			}
		}
		if (step === 3) {
			this.stepIndex = step;
			this.stepper.next();
		}
	}

	cambiaStep(e, stepper: MatStepper) {
		setTimeout(() => {
			this.disableTabIndex();
		}, 0);
	}
	// End
	// Help image
	helpimage = '';

	// For modal display and sent
	currentStatus: String;

	@ViewChild('stepper') stepper; // for view Stepper and use it in clear form

	clearForm() {
		this.isCompleted = false;
		this.stepper.selectedIndex = 0;
		this.stepIndex = 0;
		this.stepper.reset();
		this.firstFormGroup.reset();
		this.secondFormGroup.reset();
		this.loading = false;
		this.previous_datas_step1 = '';
	}

	SubmitPayment(stepper) {
		this.isDownloadingFile = true;
		this.isCompleted = true;
		this.FinalDatas['Amount'] = this.secondFormGroup.value.amount * 100;
		this.FinalDatas['Commission'] = Number(this.info.Commision) * 100;
		if (this.step2Counter !== null) {
			console.log(this.FinalDatas);
			let target;
			for (let i = 0; i < this.step2Counter.length; i++) {
				target = 'counter' + i;
				this.FinalDatas['Items'].push({ "key": "counter", "value": this.secondFormGroup.value[target], "Data": this.step2Counter[i].nrCounter, "Order": i + 1 });
			}
		}
		console.log(this.FinalDatas);
		this.loading = true;
		console.log('Submit');
		console.log(this.firstFormGroup.value); // First step input value
		console.log(this.secondFormGroup.value); // Second step input value
		this.paymentService.sendPayment(this.productId, this.FinalDatas)
			.pipe(first())
			.subscribe(
				data => {
					console.log(data);
					this.info.ResultMessage = data.ResultMessage;
					this.info.ResultCode = data.ResultCode;
					this.goForward(stepper);
					if (data.ReturnObject.Items.length) {
						this.final_vouchers = data.ReturnObject.Items;
					}
					this.isDownloadingFile = false;
				},
				error => {
					console.log(error);
					this.isDownloadingFile = false;
				});
	}

	download() {
		this.isDownloadingFile = true;
		this.loading = true;
		this.paymentService.downloadPayment(this.info.OperationId).subscribe(
			res => {
				this.isDownloadingFile = false;
				this.loading = false;
				saveAs(res, this.info.OperationId + '.pdf', { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
			},
			error => {
				console.log(error);
				this.loading = false;
				this.isDownloadingFile = false;
			});
	}

	openExampleImage(templateRef, link) {
		this.helpimage = link;
		const dialogRef = this.dialog.open(templateRef, {
			panelClass: 'help_modal',
		});
	}

	ngOnDestroy() {
	}
}