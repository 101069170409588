import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { getStyle, hexToRgba } from "@coreui/coreui/dist/js/coreui-utilities";
import { CustomTooltips } from "@coreui/coreui-plugin-chartjs-custom-tooltips";
import { saveAs } from "file-saver";
import { DatePipe, DecimalPipe } from "@angular/common";
import { TranslateService, LangChangeEvent } from "@ngx-translate/core";
import { DashboardService } from "../../_services/dashboard.service";
import { InvoicingService } from "../../_services/invoicing.service";

import { untilDestroyed } from "ngx-take-until-destroy";
import { MatTableDataSource } from "@angular/material/table";
import { MatPaginator } from "@angular/material/paginator";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { Globals } from "../../globals/globals";

@Component({
  templateUrl: "invoice-dashboard.component.html",
  styleUrls: ["invoice.scss"],
  providers: [DatePipe, DecimalPipe, Globals]
})
export class InvoiceDashboardComponent implements OnInit, OnDestroy {
  mainChartLoad: boolean = false;
  isDownloadingFile: boolean = false;
  loader: boolean = false; // Display dashboard when loading datas
  FilterForm: FormGroup; // Form initial
  FilterFormTable: FormGroup;
  FilterChartForm: FormGroup;
  FilterInput: FormControl;
  currentTableFilter = "";
  periodHistory;
  pageEvent; // for fix error of pageEvent in Visual Studio

  displayedColumns: string[] = [
    "TargetInvoice",
    "Created",
    "Expires",
    "FullName",
    "Amount",
    "Currency",
    "PayStatus",
    "OrderStatus"
  ];
  chartPeriod = 1;
  dataSource = new MatTableDataSource(); // array of datas
  @ViewChild(MatPaginator) paginator: MatPaginator;

  currentTime = new Date();
  minDate = new Date(2000, 0, 1); // Date picker min date
  maxDate = new Date(new Date().getFullYear() + 5, 0, 1);
  totalItems;
  loading: boolean = false;

  // Date picker max date
  constructor(
    private dashboardService: DashboardService,
    private invoicingService: InvoicingService,

    public translate: TranslateService,
    private datePipe: DatePipe,
    private decimalPipe: DecimalPipe,

    private globals: Globals
  ) {
    translate.onLangChange
      .pipe(untilDestroyed(this))
      .subscribe((event: LangChangeEvent) => {}); // Detect changes on the page | MAY BE DELETE
  }

  radioModelTable = 1;
  radioModel = 1; // standart radio value for month
  // mainChart
  public itemsPerPage = 10; // for display pagination from api
  public currentPage = 0;

  public mainChartElements: Number;
  public handlePage(e: any) {
    this.currentPage = e.pageIndex;
    this.itemsPerPage = e.pageSize;
    this.getDatasTable();
  }
  // function for change tables page

  public mainChartData: Array<any> = [];
  public mainChartLabels: Array<any> = [];
  public mainChartOptions: any = {
    tooltips: {
      enabled: false,
      custom: CustomTooltips,
      intersect: true,
      mode: "index",
      position: "nearest",
      xAlign: "center",
      yAlign: "top",
      callbacks: {
        labelColor: function(tooltipItem, chart) {
          return {
            backgroundColor:
              chart.data.datasets[tooltipItem.datasetIndex].borderColor
          };
        }
      }
    },
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      xAxes: [
        {
          gridLines: {
            drawOnChartArea: false
          },
          ticks: {
            callback: function(value: any) {
              return value;
            }
          }
        }
      ],
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
            maxTicksLimit: 5
          }
        }
      ]
    },
    elements: {
      line: {
        borderWidth: 2
      },
      point: {
        radius: 0,
        hitRadius: 10,
        hoverRadius: 4,
        hoverBorderWidth: 3
      }
    },
    legend: {
      display: false
    }
  };
  public mainChartColours: Array<any> = [
    {
      // brandInfo
      backgroundColor: hexToRgba("#02a1ef", 10),
      borderColor: getStyle("--primary"),
      pointHoverBackgroundColor: getStyle("--primary"),
      badge: "bg-primary"
    },
    {
      // brandSuccess
      // backgroundColor: hexToRgba("#fbc500", 10),
      backgroundColor: "transparent",

      borderColor: getStyle("--success"),
      pointHoverBackgroundColor: getStyle("--success"),
      badge: "bg-success"
    },
    {
      // brandSuccess
      // backgroundColor: hexToRgba("#fbc500", 10),
      backgroundColor: "transparent",

      borderColor: getStyle("--warning"),
      pointHoverBackgroundColor: getStyle("--warning"),
      badge: "bg-warning"
    },

    {
      // brandSuccess
      // backgroundColor: hexToRgba("#e7403e", 10),
      backgroundColor: "transparent",

      borderColor: getStyle("--danger"),
      pointHoverBackgroundColor: getStyle("--danger"),
      badge: "bg-danger"
    },
    {
      // brandDanger
      // backgroundColor: hexToRgba(getStyle("--info"), 10),
      backgroundColor: "transparent",

      borderColor: getStyle("--info"),
      pointHoverBackgroundColor: getStyle("--info"),
      borderWidth: 1,
      borderDash: [8, 5]
    },
    {
      // brandSuccess
      backgroundColor: "transparent",
      borderColor: getStyle("--success"),
      pointHoverBackgroundColor: "#fff"
    },
    {
      // brandDanger
      backgroundColor: "transparent",
      borderColor: getStyle("--danger"),
      pointHoverBackgroundColor: "#fff",
      borderWidth: 1,
      borderDash: [8, 5]
    },
    {
      // brandInfo
      // backgroundColor: hexToRgba(getStyle("--info"), 10),
      backgroundColor: "transparent",

      borderColor: getStyle("--info"),
      pointHoverBackgroundColor: "#fff"
    },
    {
      // brandSuccess
      backgroundColor: "transparent",
      borderColor: getStyle("--success"),
      pointHoverBackgroundColor: "#fff"
    },
    {
      // brandDanger
      backgroundColor: "transparent",
      borderColor: getStyle("--danger"),
      pointHoverBackgroundColor: "#fff",
      borderWidth: 1,
      borderDash: [8, 5]
    }
  ];
  public mainChartLegend = false;
  public mainChartType = "line";

  download(format) {
    this.isDownloadingFile = true;
    let start = "";
    let finish = "";
    start = this.datePipe.transform(
      this.FilterChartForm.controls.dateFromChart.value,
      "yyyy-MM-ddT00:00:00"
    );
    finish = this.datePipe.transform(
      this.FilterChartForm.controls.dateToChart.value,
      "yyyy-MM-ddT23:59:59"
    );
    let file_name = "InvoiceReport_" + finish;
    this.invoicingService
      .getFile(start, finish, this.chartPeriod, format)
      .subscribe(
        res => {
          this.isDownloadingFile = false;
          saveAs(res, file_name + "." + format, {
            type:
              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          });
        },
        error => {
          console.log(error);
          this.isDownloadingFile = false;
        }
      );
  }

  topWidgets = [
    {
      color: "bg-primary",
      value: "",
      text: "",
      currency: "TRY",
      percents: 100
    },
    { color: "bg-success", value: "", text: "", currency: "TRY", percents: 0 },
    { color: "bg-warning", value: "", text: "", currency: "TRY", percents: 0 },
    { color: "bg-danger", value: "", text: "", currency: "", percents: 0 }
  ];

  statusList = [
    {
      Text: "Blocked",
      Color: "badge-danger"
    },
    {
      Text: "Active",
      Color: "badge-success"
    },
    {
      Text: "InActive",
      Color: "badge-secondary"
    }
  ];
  getAllDates() {
    this.invoicingService.getWidgetsData().subscribe(
      data => {
        if (data.ResultCode === 0) {
          this.translate.get("INVOICE.TOP_WIDGET_TOTAL").subscribe(text => {
            this.topWidgets[0].text = text;
            this.topWidgets[0].value =
              this.decimalPipe.transform(
                data.ReturnObject.TotalSum / 100,
                ".2"
              ) +
              " " +
              data.ReturnObject.CurrencyName;
          });
          this.translate.get("INVOICE.TOP_WIDGET_PAID").subscribe(text => {
            this.topWidgets[1].text = text;
            this.topWidgets[1].value =
              this.decimalPipe.transform(
                data.ReturnObject.PaidSum / 100,
                ".2"
              ) +
              " " +
              data.ReturnObject.CurrencyName;
          });
          this.translate.get("INVOICE.TOP_WIDGET_UNPAID").subscribe(text => {
            this.topWidgets[2].text = text;
            this.topWidgets[2].value =
              this.decimalPipe.transform(
                data.ReturnObject.UnpaidSum / 100,
                ".2"
              ) +
              " " +
              data.ReturnObject.CurrencyName;
          });
          this.translate.get("INVOICE.TOP_WIDGET_EXPIRED").subscribe(text => {
            this.topWidgets[3].text = text;
            this.topWidgets[3].value =
              this.decimalPipe.transform(
                data.ReturnObject.ExpiredSum / 100,
                ".2"
              ) +
              " " +
              data.ReturnObject.CurrencyName;
          });
        }
      },
      error => {
        console.log(error);
      },
      () => {}
    );

    this.getDatasTable();
  }
  paginationItemPerPage = 50;
  paginationItems = 50;
  statusBadger(index) {
    let statusBadge = "badge-";

    switch (index) {
      case 1:
        statusBadge += "warning";
        break;
      case 2:
        statusBadge += "success";
        break;

      case 3:
        statusBadge += "danger";
        break;

      default:
        statusBadge += "info";
    }
    return statusBadge;
  }

  defaultTableData: [];
  getDatasTable() {
    this.loading = true;
    this.invoicingService.getDashBoardData().subscribe(
      data => {
        this.totalItems = 0;
        if (data.ResultCode === 0) {
          if (data.ReturnObject !== null) {
            let items = data.ReturnObject.Invoices;
            this.defaultTableData = items;
            this.dataSource = new MatTableDataSource(items); // array of datas
          }
        } else {
          this.dataSource = new MatTableDataSource([]); // array of datas
        }
        this.loading = false;
      },
      error => {
        this.loading = false;
        console.log(error);
      },
      () => {}
    );
  }
  mainChart = {
    datas: [],
    interval: [],
    period: "",
    transactions: {},
    amounts: {}
  };
  percentData;

  getPercents(total, item) {
    let newStr = item > 0 ? ((item / total) * 100).toFixed(2) : 0;
    return newStr;
  }
  mainChartInsertData(interval: number) {
    this.mainChartData = []; // for data's in chart
    let start = "";
    let finish = "";
    if (interval === 0) {
      start = this.datePipe.transform(
        this.FilterChartForm.controls.dateFromChart.value,
        "yyyy-MM-ddT00:00:00"
      );
      finish = this.datePipe.transform(
        this.FilterChartForm.controls.dateToChart.value,
        "yyyy-MM-ddT23:59:59"
      );
    }
    this.invoicingService.getMainChartDatas(start, finish, interval).subscribe(
      data => {
        if (data.ResultCode === 0) {
          this.percentData = data.ReturnObject;
          let pop_quantity = this.mainChartLabels.length;
          for (let i = 0; i < pop_quantity; i++) {
            this.mainChartLabels.pop();
          }
          // main object
          this.mainChart = {
            datas: data.ReturnObject.Lines["DateStrings"],
            interval: data.ReturnObject.Lines["Period"],
            period: data.ReturnObject.CurrentTime,
            amounts: data.ReturnObject.SummaryValues,
            transactions: data.ReturnObject.SummaryValues
          };
          let test = [];
          let translate_all = "Successful";
          this.translate.get("INVOICE.SUCCESSFUL").subscribe(text => {
            translate_all = text;
          }); // after all shit delete this row
          this.mainChart.datas.forEach(target => {
            test.push({ data: target.TrnPerDay, label: target.CompanyName }); // after all shit uncomment this row
          });
          setTimeout(() => {
            this.mainChart.interval.forEach(target => {
              this.mainChartLabels.push(target);
            });
            this.mainChartData = test;
            this.loader = true;
          }, 0);
        }
        this.mainChartLoad = false; // for enable radio
      },
      error => {
        this.mainChartLoad = false; // for enable radio
        console.log(error);
      },
      () => {}
    );
    this.chartPeriod = interval;
  }
  isLoadingTable: boolean = false;
  filterTable() {
    let valueNow = this.FilterFormTable.value.filterInput
        ? this.FilterFormTable.value.filterInput
        : "",
      regE = new RegExp(valueNow.replace(".", ".")),
      tableData = this.defaultTableData;
    this.isLoadingTable = true;

    let filtered = [];
    tableData.map((x: any) => {
      if (x.BuyerAccount.match(regE)) {
        filtered.push(x);
      }
    });
    setTimeout(() => {
      this.dataSource = new MatTableDataSource(filtered);
      this.isLoadingTable = false;
    }, 300);
  }
  ngOnInit(): void {
    this.mainChartInsertData(1);
    this.FilterForm = new FormGroup({
      dateFrom: new FormControl(
        { value: this.currentTime, disabled: true },
        Validators.required
      ),
      dateTo: new FormControl(
        { value: this.currentTime, disabled: true },
        Validators.required
      )
    });
    this.FilterChartForm = new FormGroup({
      dateFromChart: new FormControl(
        { value: this.currentTime, disabled: true },
        Validators.required
      ),
      dateToChart: new FormControl(
        { value: this.currentTime, disabled: true },
        Validators.required
      )
    });
    this.FilterFormTable = new FormGroup({
      dateFrom: new FormControl(
        { value: this.currentTime, disabled: true },
        Validators.required
      ),
      dateTo: new FormControl(
        { value: this.currentTime, disabled: true },
        Validators.required
      ),
      filterInput: new FormControl()
    });
    this.getAllDates();
  }

  ngOnDestroy(): void {}
}
