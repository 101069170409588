import { Injectable } from "@angular/core";
import {
  HttpClient,
  HttpEventType,
  HttpHeaders,
  HttpRequest,
  HttpResponse,
  HttpParams,
} from "@angular/common/http";
import { map } from "rxjs/operators";
import { Cacheable } from "ngx-cacheable";
import { AuthenticationService } from "./authentication.service";
import { Observable, Subject } from "rxjs";

@Injectable()
export class InvoicingService {
  constructor(
    private http: HttpClient,
    private authService: AuthenticationService
  ) {}
  downloadPDF(key) {
    return this.http
      .get(
        this.authService.basUrl +
          `/api/invoicing/InvoicingDownloadPdf?pInvoiceKey=` +
          key,
        {
          responseType: "blob",
          headers: { "Accept-Language": this.GetLang() },
        }
      )
      .pipe(
        map((response) => {
          return response;
        })
      );
  }
  // Transactions Datas
  getContactsListForCreate(FetchPage, FetchCount, model) {
    let Filter = {
      Name: model.ContactName,
      Email: model.ContactEmail,
    };
    return this.http
      .get<any>(
        this.authService.basUrl +
          `/api/contacts/gets?FetchPage=${FetchPage}&FetchCount=${FetchCount}&Name=${Filter.Name}&Email=${Filter.Email}`,
        {
          headers: { "Accept-Language": this.GetLang() },
        }
      )
      .pipe(
        map((response) => {
          if (this.authService.checkTokenResponse()) {
            return response;
          }
        })
      );
  }
  makeRefund(key, comment) {
    return this.http
      .get<any>(
        this.authService.basUrl +
          `/api/Invoicing/Refund?id=${key}&Comment=${comment}`,
        {
          headers: { "Accept-Language": this.GetLang() },
        }
      )
      .pipe(
        map((response) => {
          return response;
        })
      );
  }
  getTransactionsDatasCurrentPage(
    date_from,
    date_to,
    filter,
    dealerId,
    currentPage,
    item_per_page
  ) {
    return this.http
      .get<any>(
        this.authService.basUrl +
          `/api/invoicing/Transactions?DateFrom=` +
          date_from +
          `&DateTo=` +
          date_to +
          "&Account=" +
          filter.Account +
          "&UserName=" +
          filter.UserName +
          "&DealerId=" +
          dealerId +
          "&CompanyId=" +
          filter.ServiceDrop +
          "&Status=&RefStan=" +
          filter.RefStan +
          "&SalePointId=" +
          filter.ChannelDrop.Value +
          "&OpType=" +
          filter.PaidTypeDrop +
          "&CurPage=" +
          currentPage +
          "&ItemPerPage=" +
          item_per_page,
        {
          headers: { "Accept-Language": this.GetLang() },
        }
      )
      .pipe(
        map((response) => {
          return response;
        })
      );
  }
  getAllEmails() {
    return this.http
      .get<any>(this.authService.basUrl + `/api/contacts/GetAll`,
        {
          headers: { "Accept-Language": this.GetLang() },
        })
      .pipe(
        map((response) => {
          return response;
        })
      );
  }
  getDuplicatedInvoice(key) {
    return this.http
      .get<any>(this.authService.basUrl + `/api/invoicing/Copy?Key=` + key,
        {
          headers: { "Accept-Language": this.GetLang() },
        })
      .pipe(
        map((response) => {
          if (this.authService.checkTokenResponse()) {
            return response;
          }
        })
      );
  }
  getDashBoardData() {
    return this.http
      .get<any>(this.authService.basUrl + `/api/invoicingDashBoard/RecentGets`,
        {
          headers: { "Accept-Language": this.GetLang() },
        })
      .pipe(
        map((response) => {
          if (this.authService.checkTokenResponse()) {
            return response;
          }
        })
      );
  }
  // For get advance datas in modal.
  getAdvanceTransaction(refstan) {
    return this.http
      .get<any>(
        this.authService.basUrl + `/api/invoicing/Transaction/` + refstan,
        {
          headers: { "Accept-Language": this.GetLang() },
        }
      )
      .pipe(
        map((response) => {
          return response;
        })
      );
  }
  getFile(date_from, date_to, interval, format) {
    if (format === "pdf") {
      return this.http.get(
        this.authService.basUrl +
          `/api/invoicing/InvoicingGetHistoryToPDF?PeriodType=` +
          interval +
          `&DateStart=` +
          date_from +
          `&DateFinish=` +
          date_to,
        {
          responseType: "blob",
          headers: { "Accept-Language": this.GetLang() },
        }
      );
    }
    return this.http.get(
      this.authService.basUrl +
        `/api/invoicing/InvoicingGetHistoryToExcel?PeriodType=` +
        interval +
        `&DateStart=` +
        date_from +
        `&DateFinish=` +
        date_to,
      {
        responseType: "blob",
        headers: { "Accept-Language": this.GetLang() },
      }
    );
  }
  getPaymentFile(date_from, date_to, interval, status, format) {
    if (format === "pdf") {
      return this.http.get(
        this.authService.basUrl +
          `/api/invoicing/PaymentGetsToPDF?PeriodType=` +
          interval +
          `&DateStart=` +
          date_from +
          `&DateFinish=` +
          date_to +
          "&Status=" +
          status,
        {
          responseType: "blob",
          headers: { "Accept-Language": this.GetLang() },
        }
      );
    }
    return this.http.get(
      this.authService.basUrl +
        `/api/invoicing/PaymentGetsToExcel?PeriodType=` +
        interval +
        `&DateStart=` +
        date_from +
        `&DateFinish=` +
        date_to +
        "&Status=" +
        status,
      {
        responseType: "blob",
        headers: { "Accept-Language": this.GetLang() },
      }
    );
  }

  getCopyPathInvoicing(key) {
    return this.http
      .get<any>(this.authService.basUrl + `/api/invoicing/PayLink/` + key,
        {
          headers: { "Accept-Language": this.GetLang() },
        })
      .pipe(
        map((response) => {
          if (this.authService.checkTokenResponse()) {
            return response;
          }
        })
      );
  }
  //New Views
  getCreateInvoice(key) {
    return this.http
      .get<any>(
        this.authService.basUrl + `/api/invoicing/start?invoiceKey=` + key,
        {
          headers: { "Accept-Language": this.GetLang() },
        }
      )
      .pipe(
        map((response) => {
          if (this.authService.checkTokenResponse()) {
            return response;
          }
        })
      );
  }
  rejectInvoice(key) {
    return this.http
      .get<any>(
        this.authService.basUrl + `/api/invoicing/cancel?invoiceKey=` + key,
        {
          headers: { "Accept-Language": this.GetLang() },
        }
      )
      .pipe(
        map((response) => {
          if (this.authService.checkTokenResponse()) {
            return response;
          }
        })
      );
  }
  getEditInvoiceFiles(key) {
    return this.http
      .get<any>(
        this.authService.basUrl +
          `/api/invoicing/getattachments?invoiceKey=` +
          key,
        {
          headers: { "Accept-Language": this.GetLang() },
        }
      )
      .pipe(
        map((response) => {
          if (this.authService.checkTokenResponse()) {
            return response;
          }
        })
      );
  }
  // /api/invoicing / DashboardSummaryAmount
  getMainChartDatas(start, finish, period) {
    return this.http
      .get<any>(
        this.authService.basUrl +
          `/api/invoicingDashBoard/chart?PeriodType=` +
          period +
          `&DateStart=` +
          start +
          `&DateEnd=` +
          finish,
        { headers: { "Accept-Language": this.GetLang() } }
      )
      .pipe(
        map((response) => {
          if (this.authService.checkTokenResponse()) {
            return response;
          }
        })
      );
  }

  getWidgetsData() {
    return this.http
      .get<any>(
        this.authService.basUrl + `/api/invoicingDashBoard/SummaryAmount`,
        {
          headers: { "Accept-Language": this.GetLang() },
        }
      )
      .pipe(
        map((response) => {
          if (this.authService.checkTokenResponse()) {
            return response;
          }
        })
      );
  }
  saveInvoice(data, dateDue, total, items, files, state, key) {
    let body = {
      Invoice: {
        Number: data.InvoiceNumber,
        DateDue: dateDue,

        Amount: total,
        Note: data.Comments,
        DiscountValue: Math.round(Number(data.Discount) * 100),
        TaxesValue: Math.round(Number(data.Taxes) * 100),
        Key: key,
      },
      SendImmediately: state,
      ProductList: [],
      BuyerList: [
        {
          Email: data.ClientEmail,
        },
      ],
    };

    items.forEach((target) => {
      body.ProductList.push({
        UnitPrice: Math.round(Number(target.Amount) * 100),
        Quantity: Number(target.Quantity),
        Description: target.Description,
      });
    });
    if (files.length) {
      body.Invoice["Attachments"] = files;
    }
    return this.http
      .post<any>(this.authService.basUrl + `/api/invoicing/save`, body, {
        headers: {
          "Content-Type": "application/json",
          "Accept-Language": this.GetLang(),
        },
      })
      .pipe(
        map((response) => {
          if (this.authService.checkTokenResponse()) {
            return response;
          }
        })
      );
  }

  withDrawGets(start, finish, period, order, currentPage, itemsPerPage) {
    return this.http
      .get<any>(
        this.authService.basUrl +
          `/api/invoicing/Gets/?StartDate=` +
          start +
          `&FinishDate=` +
          finish +
          `&PeriodType=` +
          period +
          `&OrderId=` +
          order +
          `&FetchCount=` +
          itemsPerPage +
          `&FetchPage=` +
          currentPage,
        {
          headers: { "Accept-Language": this.GetLang() },
        }
      )
      .pipe(
        map((response) => {
          if (this.authService.checkTokenResponse()) {
            return response;
          }
        })
      );
  }

  getInvoiceHistory(
    period,
    start,
    finish,
    status,
    invoiceEmail,
    invoiceNumber,
    itemsPerPage,
    currPage
  ) {
    return this.http
      .get<any>(
        this.authService.basUrl +
          `/api/invoicing/GetHistory/?PeriodType=` +
          period +
          `&DateStart=` +
          start +
          `&DateFinish=` +
          finish +
          `&Status=` +
          status +
          `&BuyerEmail=` +
          invoiceEmail +
          `&InvoiceNumber=` +
          invoiceNumber +
          `&FetchCount=` +
          itemsPerPage +
          `&FetchPage=` +
          currPage,
        {
          headers: { "Accept-Language": this.GetLang() },
        }
      )
      .pipe(
        map((response) => {
          if (this.authService.checkTokenResponse()) {
            return response;
          }
        })
      );
  }
  getInvoicePdf(start, finish, period, order, currentPage, itemsPerPage) {
    return this.http.get(
      this.authService.basUrl +
        `/api/invoicing/DownloadPdf?StartDate=` +
        start +
        `&FinishDate=` +
        finish +
        `&PeriodType=` +
        period +
        `&OrderId=` +
        order,
      {
        responseType: "blob",
        headers: { "Accept-Language": this.GetLang() },
      }
    );
  }

  getInvoiceDrafts(
    period,
    start,
    finish,
    status,
    invoiceEmail,
    invoiceNumber,
    itemsPerPage,
    currPage
  ) {
    return this.http
      .get<any>(
        this.authService.basUrl +
          `/api/invoicing/getdrafts/?PeriodType=` +
          period +
          `&DateStart=` +
          start +
          `&DateFinish=` +
          finish +
          `&SearchKey=` +
          invoiceNumber +
          `&FetchCount=` +
          itemsPerPage +
          `&FetchPage=` +
          currPage,
        {
          headers: { "Accept-Language": this.GetLang() },
        }
      )
      .pipe(
        map((response) => {
          if (this.authService.checkTokenResponse()) {
            return response;
          }
        })
      );
  }
  removeInvoice(key) {
    let httpParams = new HttpParams().set("Id", key);
    let options = { params: httpParams };
    return this.http
      .delete<any>(this.authService.basUrl + `/api/invoicing/remove`, options)
      .pipe(
        map((response) => {
          if (this.authService.checkTokenResponse()) {
            return response;
          }
        })
      );
  }

  sendInvoice(key) {
    let body = new HttpParams().set("Key", key);
    let headers = new HttpHeaders({
      "Accept-Language": this.GetLang(),
    });

    return this.http
      .post<any>(this.authService.basUrl + `/api/invoicing/send`, body, {
        headers: headers,
      })
      .pipe(
        map((response) => {
          if (this.authService.checkTokenResponse()) {
            return response;
          }
        })
      );
  }

  getInvoicePayments(
    period,
    start,
    finish,
    status,
    invoiceEmail,
    invoiceNumber,
    itemsPerPage,
    currPage
  ) {
    return this.http
      .get<any>(
        this.authService.basUrl +
          `/api/invoicing/paymentgets/?PeriodType=${period}&DateStart=${start}&DateFinish=${finish}&Status=${status}&BuyerEmail=${invoiceEmail}&InvoiceNumber=${invoiceNumber}&FetchCount=${itemsPerPage}&FetchPage=${currPage}`
      )
      .pipe(
        map((response) => {
          if (this.authService.checkTokenResponse()) {
            return response;
          }
        })
      );
  }

  getInvoicePaymentDetails(Id) {
    return this.http
      .get<any>(this.authService.basUrl + `/api/invoicing/paymentget/?Id=${Id}`,
        {
          headers: { "Accept-Language": this.GetLang() },
        })
      .pipe(
        map((response) => {
          if (this.authService.checkTokenResponse()) {
            return response;
          }
        })
      );
  }

    private GetLang() {
    switch (localStorage.getItem("currentLang")) {
      case "ru":
        return "ru-RU";
      case "tr":
        return "tr-TR";
    }
    return "en-US";
  }
}
