import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { ChartsModule } from "ng2-charts";
import { BsDropdownModule } from "ngx-bootstrap/dropdown";
import { ButtonsModule } from "ngx-bootstrap/buttons";

import { CommonModule, DatePipe } from "@angular/common";
import { TranslateModule } from "@ngx-translate/core";

import { MatAutocompleteModule } from '@angular/material/autocomplete'
import { MatBadgeModule } from '@angular/material/badge'
import { MatButtonModule } from '@angular/material/button'
import { MatButtonToggleModule } from '@angular/material/button-toggle'
import { MatCardModule } from '@angular/material/card'
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog'
import { MatDatepickerModule } from '@angular/material/datepicker'
import { MatGridListModule } from '@angular/material/grid-list'
import { MatIconModule } from '@angular/material/icon'
import { MatInputModule } from '@angular/material/input'
import { MatListModule } from '@angular/material/list'
import { MatNativeDateModule, DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core'
import { MatPaginatorModule } from '@angular/material/paginator'
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner'
import { MatProgressBarModule } from '@angular/material/progress-bar'
import { MatSelectModule } from '@angular/material/select'
import { MatSortModule } from '@angular/material/sort'
import { MatTableModule } from '@angular/material/table'
import { MatTabsModule } from '@angular/material/tabs'
import { CustomPipeModule } from "../../_helpers/CustomPipes";
import { MyDateAdapter, APP_DATE_FORMATS } from "../../_helpers/myDateAdapter";
import { InvoiceDashboardComponent } from "./invoice-dashboard.component";
import { RouterModule } from "@angular/router";
import { InvoiceCreateComponent } from "./invoice-create.component";
import { InvoiceHistory } from "./invoice-history.component";
import { InvoicePayment } from "./invoice-payment.component";
import { InvoiceRefunds } from "./invoice-refunds.component";
import { InvoiceDrafts } from "./invoice-drafts.component";
import { InvoiceContacts } from "./invoice-contacts.component";
import { CanDeactivateGuard } from "../../_guards/deactivate.guard";
import { CollapseModule } from "ngx-bootstrap/collapse";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { BrowserModule } from "@angular/platform-browser";
import { MatTooltipModule } from "@angular/material/tooltip";

@NgModule({
  imports: [
    FormsModule,
    ChartsModule,
    BsDropdownModule,
    CommonModule,
    TranslateModule,
    MatProgressSpinnerModule,
    MatTableModule,
    MatTabsModule,
    ButtonsModule.forRoot(),
    CustomPipeModule,
    MatAutocompleteModule,
    MatDatepickerModule,
    ReactiveFormsModule,
    MatBadgeModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatSelectModule,
    MatSortModule,
    RouterModule,
    MatProgressBarModule,
    MatDialogModule,
    CollapseModule,
    BrowserModule,
    BrowserAnimationsModule,
    MatTooltipModule
  ],
  declarations: [
    InvoiceDashboardComponent,
    InvoiceCreateComponent,
    InvoiceHistory,
    InvoicePayment,
    InvoiceRefunds,
    InvoiceDrafts,
    InvoiceContacts
  ],
  providers: [
    { provide: DateAdapter, useClass: MyDateAdapter },
    {
      provide: MAT_DATE_FORMATS,
      useValue: APP_DATE_FORMATS
    },
    { provide: MatDialogRef, useValue: {} },
    CanDeactivateGuard
  ],
  exports: [TranslateModule]
})
export class InvoiceModule {}
