import { Component, OnInit, ViewChild, Input } from "@angular/core";
import { Router } from "@angular/router";
import {
  FormBuilder,
  FormGroup,
  FormControl,
  Validators,
  AbstractControl,
  ValidatorFn,
  FormGroupDirective,
  NgForm,
} from "@angular/forms";
import { Observable } from "rxjs";
import { map, startWith, first } from "rxjs/operators";
import { AlertService, UserService, ProviderService } from "../../../_services";
import { DataSource } from "@angular/cdk/table";
import { DatePipe } from "@angular/common";
/** Constants used to fill up our data base. */
import * as SecureLS from "secure-ls";
import { SettingsService } from "../../../_services/settings.service";
import { TranslateService } from "@ngx-translate/core";
import { ToastrService } from "ngx-toastr";
import { Globals } from '../../../globals/globals';
import { environment } from "../../../../environments/environment";

@Component({
  templateUrl: "./rating.component.html",
  styleUrls: ["./rating.component.scss", "../settings.scss"],
  providers: [DatePipe, Globals],
})
export class RatingComponent implements OnInit {
  constructor(
    public translate: TranslateService,
    private formBuilder: FormBuilder,
    private datePipe: DatePipe,
    private settingsService: SettingsService,
    public toastr: ToastrService,
    private router: Router
  ) {}
  basUrl: string = environment.apiUrl;

  loading: boolean = false;
  Info;
  profile_error = "";
  loading_page: boolean = false;
  RatingInfo: FormGroup;
  serviceProviders;
  alreadyRated: boolean = false;
  rate: number = 3;
  globalInfo = {
    User: {},
    Merchant: {},
  };
  currentName = "Error";
  currentVersion = "Error";
  currentDescription = "Error";
  currentDate = new Date();
  getRating() {
    this.settingsService.getAppRating().subscribe(
      (data) => {
        if (data.ResultCode === 0) {
          this.RatingInfo.patchValue({
            ["Name"]: data.ReturnObject.Name,
          });
          this.RatingInfo.patchValue({
            ["Version"]: data.ReturnObject.Version,
          });

          this.currentName = data.ReturnObject.Name;
          this.currentVersion = data.ReturnObject.Version;
          this.currentDescription = data.ReturnObject.Description;
          if (data.ReturnObject.Status !== 1) {
            this.RatingInfo.patchValue({
              ["FeedbackMsg"]: data.ReturnObject.FeedbackMsg,
            });
            this.RatingInfo.controls["FeedbackMsg"].disable();
            this.rate = data.ReturnObject.Value;
            this.currentDate = data.ReturnObject.DateReg;
            this.alreadyRated = true;
          }
        } else {
        }
        this.loading_page = true;
      },
      (error) => {
        console.log(error);
        this.loading_page = true;
      }
    );
  }
  saveRating(f: NgForm, event) {
    event.preventDefault();
    let msg = this.RatingInfo.value.FeedbackMsg,
      rate = this.rate,
      version = "WEB";
    this.loading = true;
    this.settingsService.saveAppRating(version, rate, msg).subscribe(
      (data) => {
        console.log(data);
        if (data.ResultCode === 0) {
          this.loading = false;
          let theme = "";
          let body = "";
          this.translate
            .get("PROFILE.THEME_RATING_SUCCESS")
            .subscribe((text) => {
              theme = text;
            });
          this.translate
            .get("PROFILE.BODY_RATING_SUCCESS")
            .subscribe((text) => {
              body = text;
            });
          this.toastr.success(body, theme);
          setTimeout(() => {
            this.router.navigate(["/dashboard"]); // Uncomment this after...
          }, 3000);
        } else {
          this.loading = false;
          let theme = "";
          let body = "";
          data.ResultMessage === null
            ? (body = "")
            : (body = data.ResultMessage);
          this.translate.get("PROFILE.THEME_RATING_ERROR").subscribe((text) => {
            theme = text;
          });
          this.toastr.error(body, theme);
        }
      },
      (error) => {
        this.loading = false;
        let theme = "";
        this.translate.get("PROFILE.THEME_RATING_ERROR").subscribe((text) => {
          theme = text;
        });
        this.toastr.error(error, theme);
        console.log(error);
      }
    );
  }
  ngOnInit() {
    this.RatingInfo = this.formBuilder.group({
      Name: [{ value: "", disabled: this.alreadyRated }],
      Version: [{ value: "", disabled: this.alreadyRated }],
      FeedbackMsg: [{ value: "", disabled: this.alreadyRated }],
    });
    let ls = new SecureLS(); // for Secure Local storage
    this.globalInfo = ls.get("info");
    console.log(this.globalInfo);
    // public string Name { get; set; }
    // public string Version { get; set; }
    // public string Description { get; set; }
    // public string Code { get; set; }
    // public int Value { get; set; }
    // public int Status { get; set; }
    // public string FeedbackMsg { get; set; }
    // public string Info { get; set; }
    // public DateTime DateReg { get; set; }
    this.getRating();
  }
}
