import { Component, OnInit, ViewChild, Input } from "@angular/core";
import { Router } from "@angular/router";
import {
  FormBuilder,
  FormGroup,
  FormControl,
  Validators,
  AbstractControl,
  ValidatorFn,
  FormGroupDirective,
  NgForm,
} from "@angular/forms";
import { Observable } from "rxjs";
import { map, startWith, first } from "rxjs/operators";
import { AlertService, UserService, ProviderService } from "../../_services";
import { MatPaginator } from "@angular/material/paginator";
import { MatTableDataSource } from "@angular/material/table";
import { MatDialog } from "@angular/material/dialog";
import { DataSource } from "@angular/cdk/table";
import { ReportsDatasService } from "../../_services/reportsdata.service";
import { DatePipe } from "@angular/common";
/** Constants used to fill up our data base. */
import * as SecureLS from "secure-ls";
import { TransactionService } from "../../_services/transaction.service";
import { TranslateService } from "@ngx-translate/core";
import { saveAs } from "file-saver";
import { ToastrService } from "ngx-toastr";
import { Globals } from "../../globals/globals";

import { ActivatedRoute } from "@angular/router";
import { environment } from "../../../environments/environment";

@Component({
  templateUrl: "paymentshistory.component.html",
  styleUrls: ["transactions.scss"],
  providers: [DatePipe, Globals],
})
export class PaymentsHistoryComponent implements OnInit {
  basUrl: string = environment.apiUrl;

  public paginationItems; // for display pagination from api
  public paginationItemPerPage; // for display pagination from api
  allPageLoad: boolean = false;
  isDownloadingFile: boolean = false;
  permissions;
  modalDatas = {
    RegDate: "",
    ExternalId: "",
    CurrencyCode: "",
    Id: "",
    Status: 0,
    Fee: 0,
    Amount: 0,
    MerchantName: "",
    MoneyProviderName: "",
    Note: "",
    TotalAmount: 0,
    ClearingDate: null,
  };
  modalTransactionsList = [];

  moneyProviderList = [{ ProviderId: 0, ProviderName: "ALL" }];

  radioModel = 1;
  public currentPage = 0;
  isLoadingDetailInfo: boolean = true; // spinner
  Info;
  loading = false; // for disable fast response click
  pageEvent; // for fix error of pageEvent in Visual Studio
  panelOpenState = true;
  // For change pages
  minDate = new Date(2000, 0, 1); // Date picker min date
  maxDate = new Date(new Date().getFullYear() + 5, 0, 1); // Date picker max date
  events: string[] = []; // Date picker
  currentTime = new Date();
  AccountCodes;
  refundError = "";
  displayedColumns: string[] = [
    "RegDate",
    "ExternalId",
    "MoneyProviderName",
    "Note",
    "TotalAmount",
    "Fee",
    "ClearingDate",
    "Status",
    "Action",
  ];
  dataSource = new MatTableDataSource(); // array of datas
  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(
    public dialog: MatDialog,
    // Make service for post api datas
    public translate: TranslateService,
    private datePipe: DatePipe,
    private transactionService: TransactionService,
    public toastr: ToastrService,
    private globals: Globals,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute
  ) {}

  FilterForm: FormGroup; // Form initial
  RefundForm: FormGroup;

  CloseDialog(): void {
    this.dialog.closeAll();
  }

  getFirstDatas(period) {
    this.loading = true;
    this.radioModel = period;
    let start = this.datePipe.transform(
      this.FilterForm.controls.dateFrom.value,
      "yyyy-MM-ddT00:00:00"
    );
    let finish = this.datePipe.transform(
      this.FilterForm.controls.dateTo.value,
      "yyyy-MM-ddT23:59:59"
    );
    let filter_status = null;
    if (this.FilterForm.value.Status !== "null") {
      filter_status = this.FilterForm.value.Status;
    }
    this.transactionService
      .getTransactionReport(
        start,
        finish,
        period,
        this.FilterForm.value.PaymentType,
        Math.round(Number(this.FilterForm.value.Amount) * 100),
        this.FilterForm.value.OrderId,
        this.paginationItemPerPage,
        this.currentPage + 1,
        filter_status
      )
      .pipe(first())
      .subscribe(
        (data) => {
          console.log(data);
          let datas = [];
          this.paginationItems = 0;
          if (data.ResultCode === 0) {
            if (data.ReturnObject !== null) {
              datas = data.ReturnObject.List;
              this.paginationItems = data.ReturnObject.Fetch.Cnt;
            }
          }
          this.dataSource = new MatTableDataSource(datas); // array of datas
          this.loading = false;
        },
        (error) => {
          this.loading = false;
          console.log(error);
        },
        () => {
          this.allPageLoad = true;
        }
      );
  }

  makeRefund() {
    if (this.RefundForm.valid === true) {
      this.loading = true;
      this.refundError = "";
      console.log(this.RefundForm.value);
      let Type = this.RefundForm.value.Type;
      this.transactionService
        .makeRefund(
          this.modalDatas.Id,
          Type,
          null,
          Math.round(Number(this.RefundForm.value.Amount) * 100),
          this.RefundForm.value.Comment
        )
        .pipe(first())
        .subscribe(
          (data) => {
            console.log(data);
            if (data.ResultCode === 0) {
              let theme = "";
              let body = "";
              this.translate
                .get("REPORT.THEME_SUCCESS_REFUND")
                .subscribe((text) => {
                  theme = text;
                });
              this.translate
                .get("REPORT.BODY_SUCCESS_REFUND")
                .subscribe((text) => {
                  body = text;
                });
              this.toastr.success(body, theme);
              this.CloseDialog();
              this.getFirstDatas(this.radioModel);
            } else {
              let theme = "";
              data.ResultMessage === null
                ? (this.refundError = "")
                : (this.refundError = data.ResultMessage);
              this.translate
                .get("REPORT.THEME_ERROR_REFUND")
                .subscribe((text) => {
                  theme = text;
                });
              this.toastr.error(this.refundError, theme);
            }
            this.loading = false;
          },
          (error) => {
            this.loading = false;
            this.toastr.error();
            console.log(error);
          },
          () => {
            this.allPageLoad = true;
          }
        );
    }
  }

  getMoneyProviders() {
    this.transactionService
      .getMoneyProviders()
      .pipe(first())
      .subscribe(
        (data) => {
          console.log(data);
          if (data.ResultCode === 0) {
            if (data.ReturnObject !== null) {
              this.moneyProviderList = data.ReturnObject;
            }
          }
          this.loading = false;
        },
        (error) => {
          this.loading = false;
          console.log(error);
        },
        () => {
          this.FilterForm.patchValue({
            ["PaymentType"]: this.moneyProviderList[0].ProviderId,
          });
          this.getFirstDatas(this.radioModel);
        }
      );
  }

  convertOnBlur(searchValue, form_group) {
    if (searchValue > 0) {
      this[form_group].patchValue({
        ["Amount"]: parseFloat(searchValue).toFixed(2),
      });
      searchValue = parseFloat(searchValue).toFixed(2);
    }
  }

  openDetailsModal(templateRef, Id) {
    this.refundError = "";
    this.isLoadingDetailInfo = true;
    this.RefundForm.reset();
    this.dialog.open(templateRef, {
      panelClass: "payments_table_modal",
    });
    this.transactionService
      .getAdvanceTransaction(Id)
      .pipe(first())
      .subscribe(
        (data) => {
          console.log(data);
          if (data.ResultCode === 0) {
            this.modalDatas = data.ReturnObject.Acquring;
            this.modalTransactionsList = data.ReturnObject.Transactions;
            this.RefundForm.removeControl("Amount");
            this.RefundForm.removeControl("Type");
            this.RefundForm.removeControl("Comment");
            if (this.modalDatas.Status !== 3) {
              this.RefundForm.addControl("Amount", new FormControl(""));
              this.RefundForm.addControl("Type", new FormControl(""));
              this.RefundForm.addControl("Comment", new FormControl(""));
              this.RefundForm.disable();
            } else {
              this.RefundForm.addControl(
                "Type",
                new FormControl(this.refundList[0].value)
              );
              this.RefundForm.addControl(
                "Comment",
                new FormControl("", Validators.required)
              );
              this.RefundForm.addControl(
                "Amount",
                new FormControl(
                  (this.modalDatas.TotalAmount / 100).toFixed(2),
                  Validators.required
                )
              );
              this.RefundForm.controls.Amount.disable();
              this.RefundForm.controls.Type.enable();
              this.RefundForm.controls.Comment.enable();
              // Initialize form rules
            }
          } else {
            this.CloseDialog();
          }
          this.isLoadingDetailInfo = false;
        },
        (error) => {
          this.isLoadingDetailInfo = false;
          console.log(error);
        }
      );
  }

  changeTypeRefund(type) {
    console.log(type);
    if (type === 1) {
      this.RefundForm.removeControl("Amount");
      this.RefundForm.addControl(
        "Amount",
        new FormControl("", [
          Validators.required,
          Validators.min(1),
          Validators.max(this.modalDatas.TotalAmount / 100),
        ])
      );
    } else {
      this.RefundForm.removeControl("Amount");
      this.RefundForm.addControl(
        "Amount",
        new FormControl((this.modalDatas.TotalAmount / 100).toFixed(2))
      ); // Initialize form rules
      this.RefundForm.controls.Amount.disable();
    }
  }

  download(format) {
    if (this.loading === false && this.isDownloadingFile === false) {
      this.isDownloadingFile = true;
      let start = this.datePipe.transform(
        this.FilterForm.controls.dateFrom.value,
        "yyyy-MM-ddT00:00:00"
      );
      let finish = this.datePipe.transform(
        this.FilterForm.controls.dateTo.value,
        "yyyy-MM-ddT23:59:59"
      );
      let filter_status = null;
      if (this.FilterForm.value.Status !== "null") {
        filter_status = this.FilterForm.value.Status;
      }
      this.isDownloadingFile = true;
      this.transactionService
        .getTransactionsFile(
          start,
          finish,
          this.radioModel,
          this.FilterForm.value.PaymentType,
          Math.round(Number(this.FilterForm.value.Amount) * 100),
          this.FilterForm.value.OrderId,
          this.paginationItemPerPage,
          this.currentPage + 1,
          filter_status,
          format
        )
        .pipe(first())
        .subscribe(
          (res) => {
            this.isDownloadingFile = false;
            saveAs(res, finish + "." + format, {
              type:
                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            });
          },
          (error) => {
            console.log(error);
            this.isDownloadingFile = false;
          }
        );
    }
  }

  statusLists = [
    {
      text: "All",
      value: "null",
      color: "",
    },
    {
      text: "Init",
      value: 0,
      color: "badge-warning",
    },
    {
      text: "Pay",
      value: 3,
      color: "badge-success",
    },
    {
      text: "Close",
      value: 6,
      color: "badge-secondary",
    },
    {
      text: "Refund",
      value: 4,
      color: "badge-danger",
    },
    {
      text: "Refund_partial",
      value: 7,
      color: "badge-danger",
    },
  ];

  refundList = [
    {
      text: "Full",
      value: 0,
      color: "badge-info",
    },
    //{
    //    text: 'Partial',
    //    value: 1,
    //    color: 'badge-warning'
    //},
  ];

  //----------------------
  PaginationCurentPageReset(e: any) {
    this.currentPage = 0;
  }

  //------------------- private helpers
  // convenience getter for easy access to form fields
  get f() {
    return this.FilterForm.controls;
  }

  public handlePage(e: any) {
    this.currentPage = e.pageIndex;
    this.paginationItemPerPage = e.pageSize;
    this.getFirstDatas(this.radioModel);
  }

  ngOnInit() {
    // console.log(this.permissions);
    this.paginationItemPerPage = 10;

    let ls = new SecureLS(); // for Secure Local storage
    if (ls.get("info") !== null) {
      this.Info = ls.get("info");
    }
    this.permissions = ls.get("permissions")[
      this.route.data["value"].pathCheck
    ];

    this.translate.get("REPORT.PAYMENT_STATUS_LBL_ALL").subscribe((text) => {
      this.statusLists[0].text = text;
    });
    this.translate.get("REPORT.STATUS_INIT_TEXT").subscribe((text) => {
      this.statusLists[1].text = text;
    });
    this.translate.get("REPORT.STATUS_PAY_TEXT").subscribe((text) => {
      this.statusLists[2].text = text;
    });
    this.translate.get("REPORT.STATUS_CLOSE_TEXT").subscribe((text) => {
      this.statusLists[3].text = text;
    });
    this.translate.get("REPORT.STATUS_REFUND_TEXT").subscribe((text) => {
      this.statusLists[4].text = text;
    });
    this.translate.get("REPORT.STATUS_PARTIAL_TEXT").subscribe((text) => {
      this.statusLists[5].text = text;
    });

    this.translate.get("REPORT.REFUND_FULL_TEXT").subscribe((text) => {
      this.refundList[0].text = text;
    });
    // this.translate.get('REPORT.REFUND_PARTIAL_TEXT').subscribe((text) => {
    // 	this.refundList[1].text = text;
    // });

    this.FilterForm = new FormGroup({
      dateFrom: new FormControl(
        { value: this.currentTime, disabled: true },
        Validators.required
      ),
      dateTo: new FormControl(
        { value: this.currentTime, disabled: true },
        Validators.required
      ),
      Status: new FormControl(this.statusLists[0].value, Validators.required),
      Amount: new FormControl(""),
      OrderId: new FormControl(""),
      PaymentType: new FormControl("", Validators.required),
    }); // for header Filter

    this.RefundForm = this.formBuilder.group({
      Type: ["", [Validators.required]],
      Amount: [null, [Validators.required, Validators.min(1)]],
      Comment: ["", [Validators.required]],
    });
    this.getMoneyProviders();
  }
}
