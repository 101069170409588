import {Component, OnInit, ViewChild} from '@angular/core';
import {FormGroup, FormControl, Validators} from '@angular/forms';
import {Observable} from 'rxjs';
import {map, startWith, first} from 'rxjs/operators';
import {MatPaginator} from '@angular/material/paginator';
import {MatTableDataSource} from '@angular/material/table';
import {MatDialog} from '@angular/material/dialog';
import {ReportsDatasService} from '../../_services/reportsdata.service';
import {DatePipe} from '@angular/common';
import {TransactionService} from '../../_services/transaction.service';
import {TranslateService} from '@ngx-translate/core';
/** Constants used to fill up our data base. */
import {saveAs} from 'file-saver';
import {Globals} from '../../globals/globals';
import * as SecureLS from 'secure-ls';

import {ActivatedRoute} from '@angular/router';

export interface ItemList {
	Value: string;
	Name: string;
}


@Component({
	templateUrl: 'withdraw-report.component.html',
	styleUrls: ['withdraw.scss'],
	providers: [DatePipe, Globals]
})
export class WithdrawReportComponent implements OnInit {

	public totalItems; // for display pagination from api
	public itemsPerPage = 10; // for display pagination from api
	allPageLoad: boolean = false;
	public currentPage = 0;
	isLoadingDetailInfo: boolean = true; // spinner
	isDownloadingFile: boolean = false; // spinner
	permissions;
	all_permissions;

	radioModel = 1;
	//-------------------------------------
	dealerListOptions: ItemList[]; // dealer List
	loading = false; // for disable fast response click
	pageEvent; // for fix error of pageEvent in Visual Studio
	// For change pages
	modalDatas = {
		'Confirmed': '',
		'Processes': '',
		'BankDetails': {
			'IBAN': '',
			'TVA': '',
			'AccountNumber': '',
			'AccountName': '',
			'BankCode': '',
			'BankBranch': '',
			'CountryName': null,
			'Address': null
		},
		'Amount': 0,
		'Fee': 0,
		'CurrencyCode': '',
		'Registered': '',
		'Status': 0,
		'MerchantId': 0
	};// for display datas in modal

	minDate = new Date(2000, 0, 1); // Date picker min date
	maxDate = new Date(new Date().getFullYear() + 5, 0, 1); // Date picker max date
	events: string[] = []; // Date picker
	dealerFControl = new FormControl(); // Dealer


	dealerFilteredOptions: Observable<ItemList[]>; // dealer

	currentTime = new Date();
	//----------------- list of columns
	displayedColumns: string[] = ['Id', 'Date', 'Amount', 'BankInfo', 'Fee', 'Status', 'Note'];

	dataSource = new MatTableDataSource(); // array of datas
	@ViewChild(MatPaginator) paginator: MatPaginator;

	constructor(public dialog: MatDialog,
				// Make service for post api datas
				private datePipe: DatePipe,
				private reportsDataService: ReportsDatasService,
				public translate: TranslateService,
				private transactionService: TransactionService,
				private globals: Globals,
				private route: ActivatedRoute
	) {

	}

	FilterForm: FormGroup; // Form initial

	makePDF() {
		if (this.loading === false && this.isDownloadingFile === false) {
			let start = this.datePipe.transform(this.FilterForm.controls.dateFrom.value, 'yyyy-MM-ddT00:00:00');
			let finish = this.datePipe.transform(this.FilterForm.controls.dateTo.value, 'yyyy-MM-ddT23:59:59');
			this.isDownloadingFile = true;
			this.reportsDataService.getWithdrawPdf(start, finish, this.radioModel, this.FilterForm.value.OrderId, this.currentPage + 1, this.itemsPerPage).pipe(first())
				.subscribe(
					res => {
						this.isDownloadingFile = false;
						saveAs(res, finish + '.' + 'pdf', {type: 'application/pdf'});
					},
					error => {
						console.log(error);
						this.isDownloadingFile = false;
					});
		}
	}

	CloseDialog(): void {
		this.dialog.closeAll();
	}

	detailWithdraw(templateRef, Id) {
		this.isLoadingDetailInfo = true;
		this.dialog.open(templateRef, {
			panelClass: 'modal_transaction',
		});
		this.transactionService.getAdvanceWithdraw(Id)
			.pipe(first())
			.subscribe(
				data => {
					console.log(data);
					if (data.ResultCode === 0) {
						this.modalDatas = data.ReturnObject;
					} else {
						this.CloseDialog();
					}
					this.isLoadingDetailInfo = false;
				},
				error => {
					this.isLoadingDetailInfo = false;
					console.log(error);
				});
	}

	getFirstDatas(period) {
		this.loading = true;
		this.radioModel = period;
		let start = this.datePipe.transform(this.FilterForm.controls.dateFrom.value, 'yyyy-MM-ddT00:00:00');
		let finish = this.datePipe.transform(this.FilterForm.controls.dateTo.value, 'yyyy-MM-ddT23:59:59');
		// Take data from form

		this.reportsDataService.withDrawGets(start, finish, period, this.FilterForm.value.OrderId, this.currentPage + 1, this.itemsPerPage)
			.pipe(first())
			.subscribe(
				data => {
					console.log(data);
					this.totalItems = 0;
					let datas = [];
					if (data.ResultCode === 0) {
						if (data.ReturnObject !== null) {
							datas = data.ReturnObject.List;
							this.totalItems = data.ReturnObject.Fetch.Cnt;
						}
					}
					this.dataSource = new MatTableDataSource(datas); // array of data
					this.loading = false;
				},
				error => {
					this.loading = false;
					console.log(error);
				},
				() => {
					this.allPageLoad = true;
				});
	}

	statusLists = [
		{
			text: 'New',
			color: 'badge-secondary'
		},
		{
			text: 'Initialized',
			color: 'badge-light'
		},
		{
			text: 'InProgress',
			color: 'badge-warning'
		},
		{
			text: 'Successful',
			color: 'badge-success'
        },
        {
            text: 'Reject',
            color: 'badge-danger'
        },
	];

	ngOnInit() {
		let ls = new SecureLS();

		this.permissions = ls.get('permissions')[this.route.data['value'].pathCheck];
		this.all_permissions = ls.get('permissions');

		this.FilterForm = new FormGroup({
			'dateFrom': new FormControl({value: this.currentTime, disabled: true}, Validators.required),
			'dateTo': new FormControl({value: this.currentTime, disabled: true}, Validators.required),
			'OrderId': new FormControl('')
		});
		this.translate.get('REPORT.WD_ST_NEW_TEXT').subscribe((text) => {
			this.statusLists[0].text = text;
		});
		this.translate.get('REPORT.WD_ST_INITIALIZED_TEXT').subscribe((text) => {
			this.statusLists[1].text = text;
		});
		this.translate.get('REPORT.WD_ST_INPROGRESS_TEXT').subscribe((text) => {
			this.statusLists[2].text = text;
		});
		this.translate.get('REPORT.WD_ST_SUCCESSFUL_TEXT').subscribe((text) => {
			this.statusLists[3].text = text;
        });
        this.translate.get('REPORT.WD_ST_REJECT_TEXT').subscribe((text) => {
            this.statusLists[4].text = text;
        });
		this.getFirstDatas(this.radioModel);
	}

	//----------------------
	public handlePage(e: any) {
		this.currentPage = e.pageIndex;
		this.itemsPerPage = e.pageSize;
		this.getFirstDatas(this.radioModel);

	}

	// function for change tables page
}
