import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthenticationService } from '../_services';
import { Title } from "@angular/platform-browser";
import { TranslateService } from "@ngx-translate/core";

import * as SecureLS from 'secure-ls';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private titleService: Title,
    private translate: TranslateService,
    private authService: AuthenticationService
  ) {}
  public setTitle(newTitle: string) {
    this.titleService.setTitle(newTitle);
  }
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    let currentPath = route.data.pathCheck,
      title = route.data.title,
      titleForTranslate = title.toUpperCase().replace(/\s/g, "_"),
      dmsForTranslate = "Cardplus Cep Pos";
    this.translate.get(`GLOBAL.TITLE_POPCY`).subscribe((text) => {
      dmsForTranslate = text;
    });
    if (title) {
      this.translate
        .get(`GLOBAL.TITLE_${titleForTranslate}`)
        .subscribe((text) => {
          title = text;
        });
      this.setTitle(`${title} | ${dmsForTranslate}`);
    } else {
      this.setTitle(dmsForTranslate);
    }
    let ls = new SecureLS(); // for read secure local storage
    if (!this.authService.isTokenExpired()) {
      if (
        null !== ls.get("permissions") &&
        typeof ls.get("permissions") === "object"
      ) {
        let permissions = ls.get("permissions")[currentPath];
        if (
          currentPath === "dashboard" ||
          (typeof permissions !== "undefined" && permissions) ||
          typeof currentPath === "undefined"
        ) {
          return true;
        }
        this.router.navigate(["/dashboard"]);
        return false;
      } else {
        this.router.navigate(["/login"]);
        return false;
      }
    }
    let user = JSON.parse(localStorage.getItem("currentUser"));
    if (user === null) {
      this.router.navigate(["/login"], {
        queryParams: { returnUrl: state.url },
      });
      return false;
    }
    // not logged in so redirect to login page with the return url
    this.router.navigate(["/login"], {
      queryParams: { returnUrl: state.url, error: "401" },
    });
    return false;
  }
}
