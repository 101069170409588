import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first, catchError } from 'rxjs/operators';

import { AlertService, AuthenticationService } from '../../_services';
import { $ } from 'protractor';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';

@Component({
    selector: 'app-dashboard',
    templateUrl: 'login.component.html',
    styleUrls: ['login.scss']
})
export class LoginComponent implements OnInit {
    loginForm: FormGroup;
    loading = false;
    submitted = false;
    returnUrl: string;
    error_current = '';
    loadingLogin = false;
    current_language;
    error_redirect = '';
    constructor(
        private formBuilder: FormBuilder,
        private route: ActivatedRoute,
        private router: Router,
        public toastr: ToastrService,
        private authenticationService: AuthenticationService,
        private alertService: AlertService,
        public translate: TranslateService) {
        //console.log("Log init ...");
        //console.log("browser lang is " + translate.getBrowserLang() + " --> " + translate.getBrowserCultureLang());


        translate.addLangs(['en', 'tr','ru']);
        // translate.setDefaultLang('en');
        if (localStorage.getItem('currentLang')) {
            console.log(
              "Current Language:",
              localStorage.getItem("currentLang")
            );
            this.current_language = localStorage.getItem('currentLang');
        }
        else {
            this.current_language = translate.getBrowserLang();
        }
        const browserLang = this.current_language; // This variable change lang
        translate.use(browserLang.match(/en|tr|ru/) ? browserLang : 'tr');

        translate.onLangChange.subscribe((event: LangChangeEvent) => {
            this.current_language = event.lang;
            localStorage.setItem('currentLang', this.current_language);
        })
        this.route.queryParams.subscribe(params => {
            if (typeof params['error'] !== 'undefined') {
                this.error_redirect = params['error'];
            }
        });
    }

    ngOnInit() {
        // Reset login status
        this.authenticationService.logout(); // Reset Logout

        if (this.error_redirect.length && this.error_redirect === '401') {
            let body = '';
            let theme = '';
            this.translate.get('HOME').subscribe((home) => {
                body = home.SESSION_EXPIRED;
                theme = home.SESSION_EXPIRED_THEME;
                console.log(home)
                this.toastr.warning(body, theme);
                console.log(body);
                console.log(theme);
                this.loadingLogin = true;
            }); // Text in component
        }
        else {
            this.loadingLogin = true;
        }

        // Initial form
        this.loginForm = this.formBuilder.group({
            username: ['', Validators.required],
            password: ['', Validators.required],
            merchantCode: ['', Validators.required],
            remember: [false]
        });

        // get return url from route parameters or default to '/'
        this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
    }

    // convenience getter for easy access to form fields
    get f() { return this.loginForm.controls; }

    onSubmit() {
        this.submitted = true;

        // stop here if form is invalid
        if (this.loginForm.invalid) {
            return;
        }

        this.loading = true; // uncomment
        this.authenticationService.login(this.f.username.value, this.f.password.value, this.current_language, this.f.merchantCode.value, this.f.remember.value)
            .pipe(first())
            .subscribe(
                data => {
                    this.error_current = '';

                    console.log(data);
                    if (data.resultCode === 0) {
                        //-------------------- clear data from localStore --
                        localStorage.removeItem('providerTop');
                        localStorage.removeItem('providerGroup');
                        this.router.navigate(['/dashboard']); // Uncomment this after...
                    } else {
                        //  alert("error code --> " + data.responseCode);
                        this.loading = false;
                        this.error_current = data.ResultMessage;
                        setTimeout(function () { this.alertService.error("ERROR"); }, 1);
                    }
                },
                error => {
                    let obj = JSON.parse(error.error.error);
                    this.translate.get('LOGIN.WRONG_CREDENTIONALS_MERCHANT').subscribe((text) => { this.error_current = obj.Message }); // Text in component
                    //this.alertService.error(error);
                    this.loading = false;
                });
    }
}