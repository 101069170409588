import { Component, OnInit, ViewChild, Input } from "@angular/core";
import { Router } from "@angular/router";
import {
  FormBuilder,
  FormGroup,
  FormControl,
  Validators,
  AbstractControl,
  ValidatorFn,
  FormGroupDirective,
  NgForm,
} from "@angular/forms";
import { Observable } from "rxjs";
import { map, startWith, first } from "rxjs/operators";
import { AlertService, UserService, ProviderService } from "../../_services";
// import {
//   MatPaginator,
//   MatTableDataSource,
//   MatDatepickerInputEvent,
//   MatDialog,
//   DateAdapter,
//   ErrorStateMatcher,
// } from "@angular/material";
import { DataSource } from "@angular/cdk/table";
import { DatePipe } from "@angular/common";
/** Constants used to fill up our data base. */
import * as SecureLS from "secure-ls";
import { SettingsService } from "../../_services/settings.service";
import { TranslateService } from "@ngx-translate/core";

@Component({
  templateUrl: "bank.component.html",
  styleUrls: ["settings.scss"],
  providers: [DatePipe],
})
export class BankComponent implements OnInit {
  private markFormGroupTouched(formGroup: FormGroup) {
    (<any>Object).values(formGroup.controls).forEach((control) => {
      control.markAsTouched();

      if (control.controls) {
        this.markFormGroupTouched(control);
      }
    });
  }
  constructor(
    public translate: TranslateService,
    private formBuilder: FormBuilder,
    private datePipe: DatePipe,
    private settingsService: SettingsService
  ) {}
  loading: boolean = false;
  Info;
  profile_error = "";
  loading_page: boolean = false;
  BankInfo: FormGroup;
  serviceProviders;
  getProfileSettings() {
    this.settingsService
      .getBankSettings()
      .pipe(first())
      .subscribe(
        (data) => {
          if (data.ResultCode === 0) {
            this.BankInfo.patchValue({
              ["AccountNumber"]: data.ReturnObject.BankDetails.AccountNumber,
            });
            this.BankInfo.patchValue({
              ["BankBranch"]: data.ReturnObject.BankDetails.BankBranch,
            });
            this.BankInfo.patchValue({
              ["BankCode"]: data.ReturnObject.BankDetails.BankCode
                ? data.ReturnObject.BankDetails.BankCode
                : "-",
            });
            this.BankInfo.patchValue({
              ["CountryName"]: data.ReturnObject.BankDetails.CountryName,
            });
            this.BankInfo.patchValue({
              ["IBAN"]: data.ReturnObject.BankDetails.IBAN,
            });
            this.BankInfo.patchValue({
              ["Address"]: data.ReturnObject.BankDetails.Address,
            });
          }
          this.loading_page = true;
        },
        (error) => {
          console.log(error);
          this.loading_page = true;
        }
      );
  }

  ngOnInit() {
    this.BankInfo = this.formBuilder.group({
      AccountNumber: [{ value: "", disabled: true }],
      BankBranch: [{ value: "", disabled: true }],
      BankCode: [{ value: "", disabled: true }],
      CountryName: [{ value: "", disabled: true }],
      IBAN: [{ value: "", disabled: true }],
      Address: [{ value: "", disabled: true }],
    });
    let ls = new SecureLS(); // for Secure Local storage
    if (ls.get("info") !== null) {
      this.Info = ls.get("info");
    }
    this.getProfileSettings();
  }
}
