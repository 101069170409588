import {
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewEncapsulation,
  Input,
} from "@angular/core";
import { first } from "rxjs/operators";
import { DatePipe } from "@angular/common";
import { TranslateService, LangChangeEvent } from "@ngx-translate/core";
import { InvoicingService } from "../../_services/invoicing.service";
import { untilDestroyed } from "ngx-take-until-destroy";
import { MatTableDataSource } from "@angular/material/table";
import { MatPaginator } from "@angular/material/paginator";
import { MatDialog } from "@angular/material/dialog";
import {
  FormGroup,
  FormControl,
  Validators,
  FormBuilder,
} from "@angular/forms";
import { Globals } from "../../globals/globals";
import { Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import * as SecureLS from "secure-ls";
import { saveAs } from "file-saver";
import { environment } from "../../../environments/environment";

@Component({
  selector: "app-invoice-payment",
  templateUrl: "./invoice-payment.component.html",
  styleUrls: ["../base/base.scss", "invoice.scss"],
  providers: [DatePipe, Globals],
})
export class InvoicePayment implements OnInit, OnDestroy {
  basUrl: string = environment.apiUrl;

  isDownloadingFile: boolean = false;
  loader: boolean = false;
  Status: 0;
  FilterForm: FormGroup; // Form initial
  FilterFormTable: FormGroup;
  RefundForm: FormGroup;

  pageEvent; // for fix error of pageEvent in Visual Studio
  refundError = "";
  displayedColumns: string[] = [
    "PaidDate",
    "TargetInvoice",
    "RegisteredDate",
    "PaymentMethod",
    "Amount",
    "Fee",
    "ClearingDate",
    "Status",
    "Actions",
  ];
  modalDatas = {
    RegisteredDate: "",
    InvoiceBuyerAccount: "",
    CheckoutId: "",

    Status: 0,
    Fee: 0,
    Amount: 0,
    MerchantName: "",
    MoneyProviderName: "",
    Note: "",
    TotalAmount: 0,
    ClearingDate: null,
  };
  modalTransactionsList = [];

  dataSource = new MatTableDataSource(); // array of datas
  @ViewChild(MatPaginator) paginator: MatPaginator;

  currentTime = new Date();

  minDate = new Date(2000, 0, 1); // Date picker min date
  maxDate = new Date(new Date().getFullYear() + 5, 0, 1);
  totalItems;
  loading: boolean = false;
  isLoadingDetailInfo: boolean = false; // spinner

  // Date picker max date
  constructor(
    private invoicingService: InvoicingService,
    public dialog: MatDialog,
    public translate: TranslateService,
    private datePipe: DatePipe,
    private globals: Globals,
    private router: Router,
    private formBuilder: FormBuilder,
    public toastr: ToastrService
  ) {
    translate.onLangChange
      .pipe(untilDestroyed(this))
      .subscribe((event: LangChangeEvent) => {
        // this.test = event.translations["HOME"]["TITLE"];
        // console.log(this.test);
      }); // Detect changes on the page | MAY BE DELETE
  }

  radioModel = 1; // standart radio value for month

  public itemsPerPage = 10; // for display pagination from api
  public currentPage = 0;
  public currentItem;
  public mainChartElements: Number;
  public handlePage(e: any) {
    this.currentPage = e.pageIndex;
    this.itemsPerPage = e.pageSize;
    this.getDatasTable(this.radioModel);
  }
  download(format) {
    this.isDownloadingFile = true;
    let start = this.datePipe.transform(
      this.FilterForm.controls.dateFrom.value,
      "yyyy-MM-ddT00:00:00"
    );
    let finish = this.datePipe.transform(
      this.FilterForm.controls.dateTo.value,
      "yyyy-MM-ddTHH:mm:ss"
    );
    let file_name = "InvoicePayment_" + finish,
      status = this.FilterForm.value.Status;
    console.log("FForm::", this.FilterForm);
    this.invoicingService
      .getPaymentFile(start, finish, this.radioModel, status, format)
      .subscribe(
        (res) => {
          this.isDownloadingFile = false;
          saveAs(res, file_name + "." + format, {
            type:
              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });
        },
        (error) => {
          console.log(error);
          this.isDownloadingFile = false;
        }
      );
  }
  makeRefund(key) {
    this.loading = true;
    let comment = this.tabComments.value;
    this.invoicingService.makeRefund(key, comment).subscribe(
      (data) => {
        console.log(data);
        let body = data.ResultMessage;

        if (data.ResultCode === 0) {
          let theme = "";
          this.translate
            .get("INVOICE.THEME_REFUND_SUCCESS")
            .subscribe((text) => {
              theme = text;
            });

          this.toastr.success(body, theme);
        } else {
          let theme = "";
          this.translate.get("INVOICE.THEME_REFUND_ERROR").subscribe((text) => {
            theme = text;
          });

          this.toastr.error(body, theme);
        }
        this.loading = false;
        this.CloseDialog();
        this.getDatasTable(this.radioModel);
      },
      (error) => {
        this.loading = false;
        console.log(error);
      }
    );
  }
  openConfirmationDialog(dialogName, row) {
    this.currentItem = row;
    this.openModal(dialogName);
    //console.log(row.Key);
  }
  openModal(templateRef) {
    this.dialog.open(templateRef);
  }

  CloseDialog(): void {
    this.dialog.closeAll();
  }

  getDatasTable(interval) {
    this.loading = true;
    this.radioModel = interval;
    let start = this.datePipe.transform(
      this.FilterForm.controls.dateFrom.value,
      "yyyy-MM-ddT00:00:00"
    );
    let finish = this.datePipe.transform(
      this.FilterForm.controls.dateTo.value,
      "yyyy-MM-ddT23:59:59"
    );
    let filter_status = null;
    if (this.FilterForm.value.Status !== "null") {
      filter_status = this.FilterForm.value.Status;
    }

    this.invoicingService
      .getInvoicePayments(
        interval,
        start,
        finish,
        filter_status,
        this.FilterForm.value.InvoiceEmail,
        this.FilterForm.value.InvoiceNumber,
        this.itemsPerPage,
        this.currentPage + 1
      )
      .pipe(first())
      .subscribe(
        (data) => {
          console.log(data);
          this.totalItems = 0;
          if (data.ResultCode === 0) {
            if (data.ReturnObject !== null) {
              let items = data.ReturnObject.Invoices.PaymentOperationList;
              this.dataSource = new MatTableDataSource(items); // array of datas
              this.totalItems = data.ReturnObject.Fetch.Cnt;
            }
          } else {
            this.totalItems = 0;
            this.dataSource = new MatTableDataSource([]); // array of datas
          }
          this.loading = false;
        },
        (error) => {
          this.loading = false;
          console.log(error);
        },
        () => {
          this.loader = true;
        }
      );
  }

  openDetailsModal(templateRef, Id) {
    this.refundError = "";
    this.isLoadingDetailInfo = true;
    this.RefundForm.reset();
    this.dialog.open(templateRef);
    this.invoicingService
      .getInvoicePaymentDetails(Id)
      .pipe(first())
      .subscribe(
        (data) => {
          console.log(data);
          if (data.ResultCode === 0) {
            this.modalDatas = data.ReturnObject;
            this.modalTransactionsList = data.ReturnObject.Transactions;
            this.RefundForm.removeControl("Amount");
            this.RefundForm.removeControl("Type");
            this.RefundForm.removeControl("Comment");
            if (this.modalDatas.Status !== 3) {
              this.RefundForm.addControl("Amount", new FormControl(""));
              this.RefundForm.addControl("Type", new FormControl(""));
              this.RefundForm.addControl("Comment", new FormControl(""));
              this.RefundForm.disable();
            } else {
              this.RefundForm.addControl(
                "Type",
                new FormControl(this.refundList[0].value)
              );
              this.RefundForm.addControl(
                "Comment",
                new FormControl("", Validators.required)
              );
              this.RefundForm.addControl(
                "Amount",
                new FormControl(
                  (this.modalDatas.TotalAmount / 100).toFixed(2),
                  Validators.required
                )
              );
              this.RefundForm.controls.Amount.disable();
              this.RefundForm.controls.Type.enable();
              this.RefundForm.controls.Comment.enable();
              // Initialize form rules
            }
          } else {
            this.CloseDialog();
          }
          this.isLoadingDetailInfo = false;
        },
        (error) => {
          this.isLoadingDetailInfo = false;
          console.log(error);
        }
      );
  }
  tabComments: FormControl;
  ngOnInit(): void {
    console.log("Invoice payment component!");
    this.tabComments = new FormControl("");

    this.FilterForm = new FormGroup({
      dateFrom: new FormControl(
        { value: this.currentTime, disabled: true },
        Validators.required
      ),
      dateTo: new FormControl(
        { value: this.currentTime, disabled: true },
        Validators.required
      ),

      InvoiceNumber: new FormControl(""),
      InvoiceEmail: new FormControl(""),
      Status: new FormControl(this.statusLists[0].value, Validators.required),
    });

    this.FilterFormTable = new FormGroup({
      dateFrom: new FormControl(
        { value: this.currentTime, disabled: true },
        Validators.required
      ),
      dateTo: new FormControl(
        { value: this.currentTime, disabled: true },
        Validators.required
      ),
    });

    this.RefundForm = this.formBuilder.group({
      Type: ["", [Validators.required]],
      Amount: [null, [Validators.required, Validators.min(1)]],
      Comment: ["", [Validators.required]],
    });

    this.getDatasTable(this.radioModel);

    this.translate.get("INVOICE.INV_STATUS_ALL").subscribe((text) => {
      this.statusLists[0].text = text;
    });
    this.translate.get("INVOICE.STATUS_PENDING").subscribe((text) => {
      this.statusLists[1].text = text;
    });
    this.translate.get("INVOICE.STATUS_PAID").subscribe((text) => {
      this.statusLists[2].text = text;
    });
    this.translate.get("INVOICE.STATUS_CANCEL").subscribe((text) => {
      this.statusLists[3].text = text;
    });
    this.translate.get("INVOICE.STATUS_EXPIRED").subscribe((text) => {
      this.statusLists[4].text = text;
    });
  }
  ngOnDestroy(): void {}
  statusLists = [
    {
      text: "All",
      value: "null",
    },

    {
      text: "Initiated",
      value: 1,
    },
    {
      text: "Paid/Confirmed",
      value: 2,
    },
    {
      text: "Closed",
      value: 3,
    },
    {
      text: "Refunded",
      value: 4,
    },
    {
      text: "PartialRefunded",
      value: 5,
    },
  ];

  refundList = [
    {
      text: "Full",
      value: 0,
      color: "badge-info",
    },
    //{
    //    text: 'Partial',
    //    value: 1,
    //    color: 'badge-warning'
    //},
  ];
}
