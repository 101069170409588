import { Component, OnInit, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Observable } from "rxjs";
import { map, startWith, first } from "rxjs/operators";
import { MatDialog } from "@angular/material/dialog";
import { MatStepper } from "@angular/material/stepper";
import { DatePipe } from "@angular/common";
import { Globals } from "../../../globals/globals";
import { ReportsDatasService } from "../../../_services/reportsdata.service";
import { saveAs } from "file-saver";
import { TranslateService } from "@ngx-translate/core";

@Component({
  templateUrl: "./sales-operations.component.html",
  styleUrls: ["./sales-operations.component.scss"],
  providers: [DatePipe, Globals],
})
export class SalesOperationsComponent implements OnInit {
  firstFormGroup: FormGroup;
  secondFormGroup: FormGroup;
  thirdFormGroup: FormGroup;
  isEditable: boolean = false;
  isPhoneNeeded: boolean = false;
  isRequestLoading: boolean = false;
  productValue = "";
  amountValue = "";
  phoneValue = "";
  salePaymentType = 1;
  installmentId = null;
  isSalePaymentType: boolean = true;
  resultObj = {
    Code: "",
    Description: "",
    MobTelephone: "",
    Amount: "",
  };
  salePaymentTypeLists = [];

  constructor(
    private _formBuilder: FormBuilder,
    public dialog: MatDialog,
    private datePipe: DatePipe,
    private globals: Globals,
    private reportsDataService: ReportsDatasService,
    public translate: TranslateService
  ) {}
  showVals() {
    // this.isRequestLoading = !this.isRequestLoading;

    console.log(this.productValue, this.amountValue, this.phoneValue);
  }

  makeSale(formEvent, stepper: MatStepper) {
    this.isRequestLoading = true;
    let product = this.productValue,
      amount = this.amountValue.replace(/[^0-9]/gi, ""),
      phone = this.isPhoneNeeded ? this.phoneValue : "";
    this.resultObj = {
      Code: "",
      Description: "",
      MobTelephone: "",
      Amount: "",
    };
    this.reportsDataService.createSale(product, amount, phone, this.salePaymentType, this.installmentId).subscribe(
      (data) => {
        console.log(data);
        if (data.ResultCode === 0) {
          let obj = data.ReturnObject;
          this.resultObj = obj;
        }
        this.isRequestLoading = false;
      },
      (error) => {
        this.isRequestLoading = false;
        console.log(error);
      },
      () => {        
        stepper.next();
        this.installmentId = null;
        this.salePaymentType = 1; 
      }
    );
  }
  nextStepper(stepper: MatStepper) {
    this.stepper.next();
  }
  falseClick(event) {
    event.preventDefault();
  }
  @ViewChild("stepper") stepper: MatStepper;

  isDownloadingFile:boolean = false;
  downloadSale() {
    this.isDownloadingFile = true;
    this.reportsDataService.downloadSaleQRPDF(this.resultObj.Code).subscribe(
      (res) => {
        this.isDownloadingFile = false;
        saveAs(res, "QR-Code_" + this.resultObj.Code + ".pdf", {
          type:
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
      },
      (error) => {
        console.log(error);
        this.isDownloadingFile = false;
      }
    );
  }
  numberOnly(event): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }
  convertOnBlur(event) {
    let currentVal = event.target.value;
    event.target.value = currentVal.replace(/[0-9]|\./gi, "");
    if (currentVal.containt(".")) {
    }
  }
  convertNumberToCommas(event) {
    let val = event.target.value;
    val = val.replace(",", ".");
    val = parseFloat(val);
    event.target.value = val.toFixed(2);
  }
  blurFunc(event) {
    let val = event.target.value;
    val = val.replace(",", ".");
    val = parseFloat(val);
    if (val) {
      event.target.value = val.toFixed(2);
      this.amountValue = event.target.value;
    } else {
      event.target.value = "0.00";
      this.amountValue = "0.00";
    }
  }

  isNumberWithComma(evt) {
    evt = evt ? evt : window.event;
    var charCode = evt.which ? evt.which : evt.keyCode;
    if (
      charCode > 31 &&
      (charCode < 48 || charCode > 57) &&
      charCode != 46 &&
      charCode != 44
    ) {
      return false;
    }
    return true;
  }
  updateValidator(): void {
    if (this.isPhoneNeeded) {
      this.firstFormGroup.get("phoneCtrl").setValidators(Validators.required);
    } else {
      this.firstFormGroup.get("phoneCtrl").clearValidators();
    }
    this.firstFormGroup.get("phoneCtrl").updateValueAndValidity();
    // this.firstFormGroup.updateValueAndValidity();
  }
  ngOnInit() {
    this.firstFormGroup = this._formBuilder.group({
      productCtrl: ["", Validators.required],
      amountCtrl: ["", Validators.required],
      salePaymentTypeCntrl: [1, Validators.required],
      installmentIdCntrl: [""],
      phoneCtrl: [""],
      isPhoneNeededCtrl: [false],
    });

    this.secondFormGroup = this._formBuilder.group({});
    this.thirdFormGroup = this._formBuilder.group({});
    //---------------------------------------
    
    this.functionInit();
  }

  functionInit(){ 
    this.salePaymentTypeLists = [ 
    {
      text: "Sale",
      value: 1,
    },
    {
      text: "Installment",
      value: 2,
    },
    {
      text: "Account",
      value: 3,
    }];

    for(let i=0; i< this.salePaymentTypeLists.length; i++)
    {      
      this.translate.get("SALES.SALE_TYPE_"+(i+1)).subscribe((text) => {
        this.salePaymentTypeLists[i].text = text;
      });
    } 
  }

  onSalePaymentTypeChange(event)
  {
        console.log("event",  event.value);    
        if(event.value !== 2)
        {
          this.installmentId = null;
          this.firstFormGroup.controls["installmentIdCntrl"].clearValidators()
        }
        else
          this.firstFormGroup.controls["installmentIdCntrl"].setValidators([Validators.required]);

        this.firstFormGroup.controls["installmentIdCntrl"].updateValueAndValidity();
  }
}
