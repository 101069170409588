import { NativeDateAdapter } from '@angular/material/core';
import { Injectable } from '@angular/core';

@Injectable()
export class MyDateAdapter extends NativeDateAdapter {
    format(date: Date, displayFormat: string): string {
        // console.log(displayFormat);
        if (displayFormat == "DD MMM") {
            let day = date.getDate();
            let month = date.getMonth() + 1;
            let year = date.getFullYear();
            return this._to2digit(month) + '.' + year;
        } else {
            let day = date.getDate();
            let month = date.getMonth() + 1;
            let year = date.getFullYear();
            return this._to2digit(day) + '.' + this._to2digit(month) + '.' + year;
        }
    }
    // Закончить с выводом месяца нормально
    private _to2digit(n: number) {
        return ('00' + n).slice(-2);
    }
    getFirstDayOfWeek(): number {
        return 1;
    }

}

export const APP_DATE_FORMATS =
{
    parse: {
        dateInput: 'LL',
    },
    display: {
        dateInput: 'DD.MM.YYYY',
        monthYearLabel: 'DD MMM',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'YYYY',
    },
}