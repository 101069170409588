import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, FormControl, Validators, AbstractControl, ValidatorFn, FormGroupDirective, NgForm } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith, first } from 'rxjs/operators';
import { AlertService, UserService, ProviderService } from '../../_services';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';

import { DataSource } from '@angular/cdk/table';
import { ReportsDatasService } from '../../_services/reportsdata.service';
import { DatePipe } from '@angular/common';
import { saveAs } from 'file-saver';

/** Constants used to fill up our data base. */
import * as SecureLS from 'secure-ls';
import { TransactionService } from '../../_services/transaction.service';
import { TranslateService } from '@ngx-translate/core';
import { Globals } from '../../globals/globals';


@Component({
    templateUrl: 'accountstatements.component.html',
    styleUrls: ['transactions.scss'],
    providers: [DatePipe, Globals]
})



export class AccountStatementsComponent implements OnInit {
    public paginationItems; // for display pagination from api
    public paginationItemPerPage; // for display pagination from api

    statusLists = [
        {
            text: 'All',
            value: 'null',
            color: ''
        },
        {
            text: 'Init',
            value: 0,
            color: 'badge-warning'
        },
        {
            text: 'Pending',
            value: 1,
            color: 'badge-success'
        },
        {
            text: 'Error',
            value: 2,
            color: 'badge-secondary'
        },
        {
            text: 'Pay',
            value: 3,
            color: 'badge-success'
        },
        {
            text: 'Refund',
            value: 4,
            color: 'badge-danger'
        },
        {
            text: 'Cancel',
            value: 5,
            color: 'badge-secondary'
        },
        {
            text: 'Close',
            value: 6,
            color: 'badge-success'
        },
        {
		  text: 'Refund partial',
            value: 7,
            color: 'badge-danger'
        }
    ];

    radioModel = 1;
    public currentPage = 0;
    isLoadingDetailInfo: boolean = true; // spinner
    isDownloadingFile: boolean = false; // spinner
    Info;
    loading = false; // for disable fast response click
    pageEvent; // for fix error of pageEvent in Visual Studio
    panelOpenState = true;
    // For change pages
    minDate = new Date(2000, 0, 1); // Date picker min date
    maxDate = new Date(new Date().getFullYear() + 5, 0, 1); // Date picker max date
    events: string[] = []; // Date picker
    currentTime = new Date();
    allPageLoad: boolean = false;
    AccountCodes = [
        { Code: '', Name: '' }
    ];

    displayedColumns: string[] = ['Details', 'RegDate', 'Expenses', 'Receipt', 'Balance'];
    dataSource = new MatTableDataSource(); // array of datas
    @ViewChild(MatPaginator) paginator: MatPaginator;

    constructor(public dialog: MatDialog,
        // Make service for post api datas
        public translate: TranslateService,
        private transactionService: TransactionService,
        private datePipe: DatePipe,
        private globals: Globals,
        private formBuilder: FormBuilder
    ) {

    }
    FilterForm: FormGroup; // Form initial

    modalDatas = {
        Id: 0,
        ExternalId: 0,
        Status: 0,
        Amount: 0,
        Fee: 0,
        CurrencyCode: '',
        MerchantName: '',
        MoneyProviderName: '',
        Note: '',
        RegDate: '',
    };
    withdrawDetail = {
        Amount: 0,
        CurrencyCode: '',
        Fee: 0,
        ID: 0,
        MerchantName: '',
        Note: '',
        OperationAccountingTypeName: '',
        Confirmed: '',
        Registered: '',
        Status: 0,
    };

    totalExpenses: Number = 0;
    totalReceipt: Number = 0;
    totalBalance: Number = 0;
    startBalance: Number = 0;

    AccountCodeGets() {
        this.loading = true;
        this.transactionService.getAccountCodes().pipe(first())
            .subscribe(
                data => {
                    console.log(data);
                    if (data.ResultCode === 0) {
                        this.AccountCodes = data.ReturnObject;
                        this.FilterForm.patchValue({ ['AccountCode']: this.AccountCodes[0].Code });
                    }
                },
                error => {
                    console.log(error);
                },
                () => {
                    this.allPageLoad = true;
                    this.getFirstDatas(this.radioModel);
                });
    }
    makePDF() {
        if (this.loading === false && this.isDownloadingFile === false) {
            let start = this.datePipe.transform(this.FilterForm.controls.dateFrom.value, 'yyyy-MM-ddT00:00:00');
            let finish = this.datePipe.transform(this.FilterForm.controls.dateTo.value, 'yyyy-MM-ddT23:59:59');
            this.isDownloadingFile = true;
            this.transactionService.getStatementsPDF(this.FilterForm.value.AccountCode, start, finish, this.radioModel, this.paginationItemPerPage, this.currentPage + 1).pipe(first())
                .subscribe(
                    res => {
                        this.isDownloadingFile = false;
                        saveAs(res, finish + '.' + 'pdf', { type: 'application/pdf' })
                    },
                    error => {
                        console.log(error);
                        this.isDownloadingFile = false;
                    });
        }
    }
    getFirstDatas(period) {
        this.loading = true;
        this.radioModel = period;
        let start = this.datePipe.transform(this.FilterForm.controls.dateFrom.value, 'yyyy-MM-ddT00:00:00');
        let finish = this.datePipe.transform(this.FilterForm.controls.dateTo.value, 'yyyy-MM-ddT23:59:59');
        this.transactionService.getStatementsDatas(this.FilterForm.value.AccountCode, period, start, finish, this.paginationItemPerPage, this.currentPage + 1).pipe(first())
            .subscribe(
                data => {
                    console.log(data);
                    console.log(this.dataSource.data);
                    let datas = [];
                    this.paginationItems = 0;
                    this.totalExpenses = 0;
                    this.totalReceipt = 0;
                    this.totalBalance = 0;
                    this.startBalance = 0;
                    if (data.ResultCode === 0) {
                        if (data.ReturnObject !== null) {
                            datas = data.ReturnObject.Statement;
                            this.totalExpenses = data.ReturnObject.Turnover.TotalExpenses;
                            this.totalReceipt = data.ReturnObject.Turnover.TotalReceipt;
                            this.totalBalance = data.ReturnObject.Turnover.EndBalance;
                            this.startBalance = data.ReturnObject.Turnover.BeginBalance;
                            this.paginationItems = data.ReturnObject.Fetch.Cnt;
                        }
                    }
                    this.translate.get('REPORT.BEFORE_BALANCE').subscribe((total_translate) => {
                        datas.unshift({
                            AccountId: '',
                            Amount: '',
                            BeginBalance: this.startBalance,
                            Description: '',
                            EndBalance: '',
                            Expenses: null,
                            FOId: null,
                            Receipt: null,
                            RegDate: '',
                            Title: total_translate,
                            Footer: 1
                        });
                    });
                    
                    this.translate.get('REPORT.TOTAL_BALANCE').subscribe((total_translate) => {
                        datas.push({
                            AccountId: '',
                            Amount: '',
                            BeginBalance: this.totalBalance,
                            Description: '',
                            EndBalance: '',
                            Expenses: null,
                            FOId: null,
                            Receipt: null,
                            RegDate: '',
                            Title: total_translate,
                            Footer: 1
                        });
                    });

                    if(datas.length > 2){
                        this.translate.get('REPORT.TOTAL_EXPENSES_RECEIPT').subscribe((total_translate) => {
                            datas.push({
                                AccountId: '',
                                Amount: '',
                                BeginBalance: null,
                                Description: '',
                                EndBalance: '',
                                Expenses: this.totalExpenses,
                                FOId: null,
                                Receipt: this.totalReceipt,
                                RegDate: '',
                                Title: total_translate,
                                Footer: 1
                            });
                        });
                    }

                    this.dataSource = new MatTableDataSource(datas); // array of datas	
                    this.loading = false;
                    console.log(datas);
                },
                error => {
                    this.loading = false;
                    console.log(error);
                });
    }

    //----------------------
    PaginationCurentPageReset(e: any) {
        this.currentPage = 0;
    }
    //------------------- private helpers 
    // convenience getter for easy access to form fields
    get f() { return this.FilterForm.controls; }

    public handlePage(e: any) {
        this.currentPage = e.pageIndex;
        this.paginationItemPerPage = e.pageSize;
        this.getFirstDatas(this.radioModel);
    }

    CloseDialog(): void {
        this.dialog.closeAll();
    }

    openDetailsModal(details, withdrawDetails, type, Id) {
        this.isLoadingDetailInfo = true;
        if (type === 'ACQUIRING') {
            const dialogRef = this.dialog.open(details, {
                panelClass: 'modal_transaction',
            });
            this.transactionService.getAdvanceTransaction(Id)
                .pipe(first())
                .subscribe(
                    data => {
                        console.log(data);
                        if (data.ResultCode === 0) {
                            this.modalDatas = data.ReturnObject.Acquring;
                        }
                        else {
                            this.CloseDialog();
                        }
                        this.isLoadingDetailInfo = false;
                    },
                    error => {
                        this.isLoadingDetailInfo = false;
                        this.CloseDialog();
                        console.log(error);
                    });
        }
        else if (type === 'WALLET_TO_ACCOUNT_TRANSFER') {
            const dialogRef = this.dialog.open(withdrawDetails, {
                panelClass: 'modal_transaction',
            });
            this.transactionService.getAdvanceWithdraw(Id)
                .pipe(first())
                .subscribe(
                    data => {
                        console.log(data);
                        if (data.ResultCode === 0) {
                            this.withdrawDetail = data.ReturnObject;
                        }
                        else {
                            this.CloseDialog();
                        }
                        this.isLoadingDetailInfo = false;
                    },
                    error => {
                        this.isLoadingDetailInfo = false;
                        this.CloseDialog();
                        console.log(error);
                    });
        }
        else {
            this.isLoadingDetailInfo = false;
        }
    }

    ngOnInit() {
        // console.log(this.permissions);
        this.translate.get('REPORT.LBL_ALL').subscribe((text) => { this.statusLists[0].text = text; });
        this.translate.get('REPORT.STATUS_INIT_TEXT').subscribe((text) => { this.statusLists[1].text = text; });
        this.translate.get('REPORT.STATUS_PENDING_TEXT').subscribe((text) => { this.statusLists[2].text = text; });
        this.translate.get('REPORT.STATUS_ERROR_TEXT').subscribe((text) => { this.statusLists[3].text = text; });
        this.translate.get('REPORT.STATUS_PAY_TEXT').subscribe((text) => { this.statusLists[4].text = text; });
        this.translate.get('REPORT.STATUS_REFUND_TEXT').subscribe((text) => { this.statusLists[5].text = text; });
        this.translate.get('REPORT.STATUS_CANCEL_TEXT').subscribe((text) => { this.statusLists[6].text = text; });
        this.translate.get('REPORT.STATUS_CLOSE_TEXT').subscribe((text) => { this.statusLists[7].text = text; });
	   this.translate.get('REPORT.STATUS_PARTIAL_TEXT').subscribe((text) => { this.statusLists[8].text = text; });

        this.paginationItemPerPage = 10;
        this.FilterForm = new FormGroup({
            'dateFrom': new FormControl({ value: this.currentTime, disabled: true }, Validators.required),
            'dateTo': new FormControl({ value: this.currentTime, disabled: true }, Validators.required),
            'AccountCode': new FormControl('', Validators.required),
        }); // for header Filter
        this.AccountCodeGets();

        let ls = new SecureLS(); // for Secure Local storage
        if (ls.get('info') !== null) {
            this.Info = ls.get('info');
        }
    }
}