import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { map, first } from "rxjs/operators";
import { AuthenticationService } from "./authentication.service";
import { Cacheable, CacheBuster } from "ngx-cacheable";
import { Subject } from "rxjs";

// const httpOptions = {
//   headers: new HttpHeaders({
//     'Content-Type':  'application/x-www-form-urlencoded;charset=UTF-8',
//     'Accept-Language': 'en-EN',
// })
// };
const cacheBuster$ = new Subject<void>();
@Injectable()
export class TransactionService {
  constructor(
    private http: HttpClient,
    private authService: AuthenticationService
  ) {}

  // _______________   Dealer page

  //Statement
  getsStatements(
    start,
    finish,
    period,
    ordernumber,
    amount,
    paytype,
    opertype,
    ItemsPerPage,
    CurrentPage
  ) {
    return this.http
      .get<any>(
        this.authService.basUrl +
          `/api/Statement/Gets?pStartDate=${start}&pEndDate=${finish}&PeriodType=${period}&pAccountCode=&pOrderNumber=${ordernumber}&pAmount=${amount}&pPaymentMethod=${paytype}&pOperationType=${opertype}&pFetchPage=${CurrentPage}&pFetchCount=${ItemsPerPage}`,
        { headers: { "Accept-Language": this.GetLang() } }
      )
      .pipe(
        map((response) => {
          if (this.authService.checkTokenResponse()) {
            return response;
          }
        })
      );
  }

  getsStatementFile(
    start,
    finish,
    period,
    ordernumber,
    amount,
    paytype,
    opertype,
    format
  ) {
    let _format = format == "pdf" ? 0 : 1;
    return this.http.get(
      this.authService.basUrl +
        `/api/Statement/Export?pStartDate=${start}&pEndDate=${finish}&PeriodType=${period}&pOrderNumber=${ordernumber}&pAmount=${amount}&pPaymentMethod=${paytype}&pOperationType=${opertype}&ExportType=${_format}`,
      {
        responseType: "blob",
        headers: { "Accept-Language": this.GetLang() },
      }
    );
  }
  getAccountCodes() {
    return this.http
      .get<any>(this.authService.basUrl + `/api/Accounting/Gets`, {
        headers: { "Accept-Language": this.GetLang() },
      })
      .pipe(
        map((response) => {
          if (this.authService.checkTokenResponse()) {
            return response;
          }
        })
      );
  }

  getStatementsDatas(
    AccountCode,
    period,
    DateStart,
    DateFinish,
    ItemsPerPage,
    CurrentPage
  ) {
    return this.http
      .get<any>(
        this.authService.basUrl +
          `/api/Accounting/Statement?AccountCode=` +
          AccountCode +
          `&DateStart=` +
          DateStart +
          `&DateFinish=` +
          DateFinish +
          `&PeriodType=` +
          period +
          `&FetchCount=` +
          ItemsPerPage +
          `&FetchPage=` +
          CurrentPage,
        { headers: { "Accept-Language": this.GetLang() } }
      )
      .pipe(
        map((response) => {
          if (this.authService.checkTokenResponse()) {
            return response;
          }
        })
      );
  }

  getStatementsPDF(
    AccountCode,
    DateStart,
    DateFinish,
    period,
    ItemsPerPage,
    CurrentPage
  ) {
    return this.http.get(
      this.authService.basUrl +
        `/api/Accounting/StatementToPdf?AccountCode=` +
        AccountCode +
        `&DateStart=` +
        DateStart +
        `&DateFinish=` +
        DateFinish +
        `&PeriodType=` +
        period +
        `&FetchCount=` +
        ItemsPerPage +
        `&FetchPage=` +
        CurrentPage,
      {
        responseType: "blob",
        headers: { "Accept-Language": this.GetLang() },
      }
    );
  }

  getTransactionReport(
    DateStart,
    DateFinish,
    period,
    payment_type,
    amount,
    orderid,
    ItemsPerPage,
    CurrentPage,
    Status
  ) {
    return this.http
      .get<any>(
        this.authService.basUrl +
          `/api/Ecom/GetReport?DateStart=` +
          DateStart +
          `&DateEnd=` +
          DateFinish +
          "&OrderId=" +
          orderid +
          `&MoneyProviderId=` +
          payment_type +
          `&PeriodType=` +
          period +
          `&Amount=` +
          amount +
          `&FetchCount=` +
          ItemsPerPage +
          `&FetchPage=` +
          CurrentPage +
          `&Status=` +
          Status,
        { headers: { "Accept-Language": this.GetLang() } }
      )
      .pipe(
        map((response) => {
          if (this.authService.checkTokenResponse()) {
            return response;
          }
        })
      );
  }

  getsStatement(
    DateStart,
    DateFinish,
    period,
    ordernumber,
    amount,
    status,
    paytype,
    ItemsPerPage,
    CurrentPage
  ) {
    return this.http
      .get<any>(
        this.authService.basUrl +
          `/api/Order/Gets?pStartDate=${DateStart}&pEndDate=${DateFinish}&PeriodType=${period}&pCode=${ordernumber}&pStatus=${status}&FetchPage=${CurrentPage}&FetchCount=${ItemsPerPage}`,
        { headers: { "Accept-Language": this.GetLang() } }
      )
      .pipe(
        map((response) => {
          if (this.authService.checkTokenResponse()) {
            return response;
          }
        })
      );
  }
  // Orders
  getsOrderHistory(
    DateStart,
    DateFinish,
    period,
    code,
    phone,
    status,
    productType,
    salePayType,
    ItemsPerPage,
    CurrentPage
  ) {
    return this.http
      .get<any>(
        this.authService.basUrl +
          `/api/Order/Gets?pStartDate=${DateStart}&pEndDate=${DateFinish}&PeriodType=${period}&pCode=${code}&pMobilePhone=${phone}&pStatus=${status}&pProductType=${productType}&pSalePaymentType=${salePayType}&FetchPage=${CurrentPage}&FetchCount=${ItemsPerPage}`,
        { headers: { "Accept-Language": this.GetLang() } }
      )
      .pipe(
        map((response) => {
          if (this.authService.checkTokenResponse()) {
            return response;
          }
        })
      );
  }
  getsOrderHistoryFile(
    DateStart,
    DateFinish,
    period,
    code,
    phone,
    status,
    productType,
    salePayType, 
    format
  ) {
    let _format = format == "pdf" ? 0 : 1;
    return this.http.get(
      this.authService.basUrl +
        `/api/Order/Export?pStartDate=${DateStart}&pEndDate=${DateFinish}&PeriodType=${period}&pCode=${code}&pMobilePhone=${phone}&pStatus=${status}&pProductType=${productType}&ExportType=${_format}&pSalePaymentType=${salePayType}`,
      {
        responseType: "blob",
        headers: { "Accept-Language": this.GetLang() },
      }
    );
  }
  getOrderDetails(order) {
    return this.http
      .get<any>(
        this.authService.basUrl + `/api/Order/Get?pOrderNumber=${order}`,

        { headers: { "Accept-Language": this.GetLang() } }
      )
      .pipe(
        map((response) => {
          if (this.authService.checkTokenResponse()) {
            return response;
          }
        })
      );
  }
  getMoneyProviders() {
    return this.http
      .get<any>(this.authService.basUrl + `/api/Merchant/Providers`, {
        headers: { "Accept-Language": this.GetLang() },
      })
      .pipe(
        map((response) => {
          if (this.authService.checkTokenResponse()) {
            return response;
          }
        })
      );
  }

  getAdvanceTransaction(id) {
    return this.http
      .get<any>(
        this.authService.basUrl + `/api/Ecom/GetDetail?OperationId=` + id,
        { headers: { "Accept-Language": this.GetLang() } }
      )
      .pipe(
        map((response) => {
          if (this.authService.checkTokenResponse()) {
            return response;
          }
        })
      );
  }

  getAdvanceWithdraw(id) {
    return this.http
      .get<any>(this.authService.basUrl + `/api/Withdraw/Get/` + id, {
        headers: { "Accept-Language": this.GetLang() },
      })
      .pipe(
        map((response) => {
          if (this.authService.checkTokenResponse()) {
            return response;
          }
        })
      );
  }

  getTransactionsFile(
    DateStart,
    DateFinish,
    period,
    payment_type,
    amount,
    orderid,
    ItemsPerPage,
    CurrentPage,
    Status,
    format
  ) {
    if (format === "pdf") {
      return this.http.get(
        this.authService.basUrl +
          `/api/Ecom/ExportPdf?DateStart=` +
          DateStart +
          `&DateEnd=` +
          DateFinish +
          "&OrderId=" +
          orderid +
          `&MoneyProviderId=` +
          payment_type +
          `&PeriodType=` +
          period +
          `&Amount=` +
          amount +
          `&Status=` +
          Status,
        {
          responseType: "blob",
          headers: { "Accept-Language": this.GetLang() },
        }
      );
    } else {
      return this.http.get(
        this.authService.basUrl +
          `/api/Ecom/ExportExcel?DateStart=` +
          DateStart +
          `&DateEnd=` +
          DateFinish +
          "&OrderId=" +
          orderid +
          `&MoneyProviderId=` +
          payment_type +
          `&PeriodType=` +
          period +
          `&Amount=` +
          amount +
          `&Status=` +
          Status,
        {
          responseType: "blob",
          headers: { "Accept-Language": this.GetLang() },
        }
      );
    }
  }

  getRefundsReport(
    start,
    finish,
    period,
    orderId,
    refundType,
    ItemsPerPage,
    CurrentPage
  ) {
    refundType = refundType === "null" ? "" : refundType;

    return this.http
      .get<any>(
        this.authService.basUrl +
          `/api/Refund/Gets?pStartDate=` +
          start +
          `&pEndDate=` +
          finish +
          `&PeriodType=` +
          period +
          `&OrderId=` +
          orderId +
          `&pRefundType=${refundType}` +
          `&FetchCount=` +
          ItemsPerPage +
          `&FetchPage=` +
          CurrentPage,
        { headers: { "Accept-Language": this.GetLang() } }
      )
      .pipe(
        map((response) => {
          if (this.authService.checkTokenResponse()) {
            return response;
          }
        })
      );
  }

  getRefundPdf(start, finish, period, orderId, refundType) {
    refundType = refundType === "null" ? "" : refundType;
    return this.http.get(
      this.authService.basUrl +
        `/api/Refund/Export?pStartDate=` +
        start +
        `&pEndDate=` +
        finish +
        `&PeriodType=` +
        period +
        `&pOrderID=` +
        orderId +
        `&pRefundType=${refundType}`,
      {
        responseType: "blob",
        headers: { "Accept-Language": this.GetLang() },
      }
    );
  }

  makeRefund(orderNumber, refType, factID, amount, comment) {
    //  public long Amount { get; set; }

    // public string Comment { get; set; }

    // public RefundType RefundType { get; set; }

    // public long FactID { get; set; }
    return this.http
      .post<any>(
        this.authService.basUrl + `/api/Refund/RefundRequest`,
        {
          RefundType: refType,
          OrderNumber: orderNumber,
          Amount: amount,
          FactID: factID,
          Comment: comment,
        },
        {
          headers: {
            "Content-Type": "application/json",
            "Accept-Language": this.GetLang(),
          },
        }
      )
      .pipe(
        map((response) => {
          if (this.authService.checkTokenResponse()) {
            return response;
          }
        })
      );
  }

  private GetLang() {
    switch (localStorage.getItem("currentLang")) {
      case "ru":
        return "ru-RU";
      case "tr":
        return "tr-TR";
    }
    return "en-US";
  }
}
