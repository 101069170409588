import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, FormControl, Validators, AbstractControl, ValidatorFn, FormGroupDirective, NgForm } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith, first } from 'rxjs/operators';
import { AlertService, UserService, ProviderService } from '../../_services';
//import { MatPaginator, MatTableDataSource, MatDatepickerInputEvent, MatDialog, DateAdapter, ErrorStateMatcher } from '@angular/material';
import { DataSource } from '@angular/cdk/table';
import { DatePipe } from '@angular/common';
/** Constants used to fill up our data base. */
import * as SecureLS from 'secure-ls';
import { SettingsService } from '../../_services/settings.service';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';


@Component({
    templateUrl: 'withdraw.component.html',
    styleUrls: ['withdraw.scss'],
    providers: [DatePipe]
})



export class WithdrawComponent implements OnInit {
    private markFormGroupTouched(formGroup: FormGroup) {
        (<any>Object).values(formGroup.controls).forEach(control => {
            control.markAsTouched();

            if (control.controls) {
                this.markFormGroupTouched(control);
            }
        });
    }
    constructor(
        public translate: TranslateService,
        public toastr: ToastrService,
        private formBuilder: FormBuilder,
        private settingsService: SettingsService,
    ) {

    }
    BankInfo: FormGroup;

    modalDatas = {
        'AccountName': '',
        'AccountNumber': '',
        'BankBranch': '',
        'BankCode': '',
        'CountryName': '',
        'IBAN': '',
        'TVA': ''
    };

    stepActive = 1;
    requestId = 0;
    currentCommission = 0;
    currentBalance = 0;
    loading: boolean = false;
    Info;
    loading_page: boolean = false;
    Withdraw: FormGroup;
    maxValue = 0;
    getProfileSettings() {
        this.settingsService.getBankSettings().pipe(first())
            .subscribe(
                data => {
                    console.log(data);
                    if (data.ResultCode === 0) {
                        this.modalDatas = data.ReturnObject.BankDetails;
                    }
                },
                error => {
                    console.log(error);
                },
                () => {
                    this.getCommision();
                });
    }

    getCommision() {
        this.settingsService.getCommission().pipe(first())
            .subscribe(
                data => {
                    console.log(data);
                    if (data.ResultCode === 0) {
                        this.currentCommission = data.ReturnObject.WithDrawCommissionValue;
                        this.maxValue = data.ReturnObject.WithDrawAmountMax;
                        this.currentBalance =
                          data.ReturnObject.WithDrawAvailableAmount;
                    }
                },
                error => {
                    this.loading_page = true;
                    console.log(error);
                },
                () => {
                    this.loading_page = true;
                });
    }

    sendData(step) {
        this.markFormGroupTouched(this.Withdraw);
        if (step === 1) {
            this.requestId = 0;
            if (this.Withdraw.valid === true) {
                console.log(this.Withdraw.value);
                this.loading = true;
                this.settingsService.sendOTP(Math.round(Number(this.Withdraw.value.Amount) * 100))
                    .pipe(first())
                    .subscribe(
                        data => {
                            console.log(data);
                            if (data.ResultCode === 0) {
                                this.requestId = data.ReturnObject.Id;
                                this.Withdraw.removeControl('OTP'); // Initialize form rules
                                this.Withdraw.addControl('OTP', new FormControl('', Validators.required)); // Initialize form rules
                                this.Withdraw.controls.Amount.disable();
                                this.stepActive = 2;
                            }
                            else {
                                let theme = '';
                                let body = '';
                                data.ResultMessage === null ? body = '' : body = data.ResultMessage;
                                this.translate.get('PROFILE.WITHDRAW_OTP_ERROR').subscribe((text) => { theme = text; });
                                this.toastr.error(body, theme);
                            }
                            this.loading = false;
                        },
                        error => {
                            this.loading = false;
                            console.log(error);
                        });
            }
        }
        else if (step === 2) {
            if (this.Withdraw.valid === true) {
                console.log(this.Withdraw.value);
                this.loading = true;
                this.settingsService.confirmRequest(this.requestId, String(this.Withdraw.value.OTP))
                    .pipe(first())
                    .subscribe(
                        data => {
                            console.log(data);
                            if (data.ResultCode === 0) {
                                this.stepActive = 3;
                                let theme = '';
                                let body = '';
                                this.translate.get('PROFILE.THEME_SUCCESS_WITHDRAW').subscribe((text) => { theme = text; });
                                this.translate.get('PROFILE.BODY_SUCCESS_WITHDRAW').subscribe((text) => { body = text; });
                                this.toastr.success(body, theme);
                            }
                            else {
                                let theme = '';
                                let body = '';
                                data.ResultMessage === null ? body = '' : body = data.ResultMessage;
                                this.translate.get('PROFILE.THEME_ERROR_WITHDRAW').subscribe((text) => { theme = text; });
                                this.toastr.error(body, theme);
                            }
                            this.loading = false;
                        },
                        error => {
                            this.loading = false;
                            console.log(error);
                        });
            }
        }

    }

    clearData() {
        this.Withdraw.removeControl('OTP');
        this.Withdraw.controls.Amount.enable();
        this.Withdraw.addControl('OTP', new FormControl(''));
        this.Withdraw.reset();
        this.requestId = 0;
        this.stepActive = 1;
    }

    convertOnBlur(searchValue) {
        if (searchValue > 0) {
            this.Withdraw.patchValue({ ['Amount']: parseFloat(searchValue).toFixed(2) });
            searchValue = parseFloat(searchValue).toFixed(2);
        }
    }
    onTypeChange(searchValue) {
        if (searchValue > this.maxValue || this.maxValue === 0) {
			this.Withdraw.patchValue({ ['Amount']: this.maxValue });
			searchValue = this.maxValue;
        }
    }
    ngOnInit() {

        this.Withdraw = this.formBuilder.group({
            'Amount': [null, [Validators.required, Validators.min(1)]],
            'OTP': [''],
        });

        let ls = new SecureLS(); // for Secure Local storage
        if (ls.get('info') !== null) {
            this.Info = ls.get('info');
        }
        this.getProfileSettings();
    }
}
