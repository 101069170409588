import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';


import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

// used to create fake backend
import { fakeBackendProvider } from './_helpers';

import { AppComponent } from './app.component';
import { routing } from './app.routing';

import { AlertComponent } from './_directives';
import { AuthGuard } from './_guards';
import { JwtInterceptor, ErrorInterceptor } from './_helpers';
import { AlertService, AuthenticationService, UserService, PaymentService } from './_services';

import { LocationStrategy, HashLocationStrategy, PathLocationStrategy } from '@angular/common';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';


const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
    suppressScrollX: true
};


// Import containers

import { P404Component } from './views/error/404.component';
import { P500Component } from './views/error/500.component';
import { RegisterComponent } from './views/register/register.component';


import {
    AppAsideModule,
    AppBreadcrumbModule,
    AppHeaderModule,
    AppFooterModule,
    AppSidebarModule,
} from '@coreui/angular';


// Import 3rd party components
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { ChartsModule } from 'ng2-charts';
// Import 3rd party modules
import { PaymentsModule } from './views/payments/payments.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ReportsModule } from './views/reports/reports.module';

// Translate
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { from } from 'rxjs';
import { DefaultLayoutModule } from './containers/default-layout/default-layout.module';
import { DefaultLayoutComponent } from './containers';
import { ReportsDatasService } from './_services/reportsdata.service';
import { ProviderService } from './_services/provider.service';

import { ToastrModule } from 'ngx-toastr';
import { LoginModule } from './views/login/login.module';
import { LoginComponent } from './views/login/login.component';
import { ProfileService } from './_services/profile.service';
import { MainService } from './_services/main.service';
import { DashboardService } from './_services/dashboard.service';
import { InvoicingService } from './_services/invoicing.service';
import { ContactsService } from './_services/contacts.service';

import { CustomPipeModule } from './_helpers/CustomPipes';
import { RoleGuard } from './_guards/role.guard';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { RecoveryModule } from './views/login/recovery.module';
import { RecoveryComponent } from './views/login/recovery.component';
import { SettingsModule } from './views/settings/settings.module';
import { SettingsService } from './_services/settings.service';
import { ResetPasswordModule } from './views/login/resetpassword.module';
import { ResetPasswordComponent } from './views/login/resetpassword.component';
import { TransactionsModule } from './views/transactions/transactions.module';
import { TransactionService } from './_services/transaction.service';
import { WithdrawModule } from './views/withdraw/withdraw.module';
import { InvoiceModule } from './views/invoice/invoice.module';
import { SalesModule } from "./views/sales/sales.module";


import { RecaptchaModule } from "ng-recaptcha";
import { environment } from "./../environments/environment";

export function HttpLoaderFactory(httpClient: HttpClient) {
    //return new TranslateHttpLoader(httpClient);
   // return new TranslateHttpLoader(httpClient, '/home/lang/', 'json');
    return new TranslateHttpLoader(
      httpClient,
      environment.apiUrl + "/home/lang/",
      "json"
    );
}
import { registerLocaleData } from "@angular/common";
import localeTr from "@angular/common/locales/tr";
import localeRu from "@angular/common/locales/ru";
registerLocaleData(localeRu);
registerLocaleData(localeTr);
@NgModule({
  imports: [
    BrowserModule,
    AppAsideModule,
    AppBreadcrumbModule.forRoot(),
    AppFooterModule,
    AppHeaderModule,
    AppSidebarModule,
    PerfectScrollbarModule,
    BsDropdownModule.forRoot(),
    TabsModule.forRoot(),
    ChartsModule,
    ReactiveFormsModule,
    HttpClientModule,
    PaymentsModule,
    ReportsModule,
    routing,
    BrowserAnimationsModule,
    LoginModule,
    RecoveryModule,
    SettingsModule,
    TransactionsModule,
    CKEditorModule,
    FormsModule,
    ResetPasswordModule,
    WithdrawModule,
    InvoiceModule,
    SalesModule,
    ToastrModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),

    CustomPipeModule,
    RecaptchaModule,
  ],
  declarations: [
    AppComponent,
    AlertComponent,
    DefaultLayoutComponent,
    P404Component,
    P500Component,
    LoginComponent,
    ResetPasswordComponent,
    RecoveryComponent,
    RegisterComponent,
  ],
  providers: [
    {
      provide: LocationStrategy,
      //useClass: PathLocationStrategy,
      useClass: HashLocationStrategy,
    },
    AuthGuard,
    RoleGuard,
    AlertService,
    AuthenticationService,
    ReportsDatasService,
    UserService,
    ProviderService,
    PaymentService,
    TransactionService,
    ProfileService,
    MainService,
    DashboardService,
    InvoicingService,
    ContactsService,
    SettingsService,
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },

    // provider used to create fake backend
    fakeBackendProvider,
    // {
    //   provide: RECAPTCHA_SETTINGS,
    //   useValue: {
    //     siteKey: "6LeX4akUAAAAAH34lfLLirZqTZku402eH1F8uSRJ",
    //   } as RecaptchaSettings,
    // },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
