import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, FormControl, Validators, AbstractControl, ValidatorFn } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith, first } from 'rxjs/operators';
import { AlertService, UserService, ProviderService } from '../../_services';
import { DateAdapter } from '@angular/material/core';
import { MatPaginator } from "@angular/material/paginator";
import { MatDialog } from "@angular/material/dialog";
import { MatTableDataSource } from "@angular/material/table";
import { DataSource } from '@angular/cdk/table';
import { ReportsDatasService } from '../../_services/reportsdata.service';
import { DatePipe } from '@angular/common';
import { TransactionService } from '../../_services/transaction.service';
import { Globals } from '../../globals/globals';
/** Constants used to fill up our data base. */

export interface ItemList {
	Value: string;
	Name: string;
}


@Component({
	templateUrl: 'problem-payments.component.html',
	styleUrls: ['reports.scss'],
	providers: [DatePipe, Globals]
})
export class ProblemPaymentsComponent implements OnInit {

	public paginationItems; // for display pagination from api
	public paginationItemPerPage; // for display pagination from api

	public transactionCurrentID;
	public transactionCurrentDealerName;
	public transactionCurrentServiceName;
	public currentPage = 0;
	isLoadingDetailInfo: boolean = true; // spinner


	//-------------------------------------
	providersList;
	Transactions_Datas;
	dealerListOptions: ItemList[]; // = [{ Value: '1', Name: 'Mase 1' }, { Value: '2', Name: 'Dealer 222' }, { Value: '3', Name: 'Feadea' }, { Value: '4', Name: 'Grisha' }];
	loading = false; // for disable fast response click
	pageEvent; // for fix error of pageEvent in Visual Studio
	panelOpenState = true;
	// For change pages

	services: ItemList[];

	account = ''; // Account input
	refStan = ''; // refStan input
	serviceSelected = ''; // Service selected
	userLogin = ''; // User Login input

	minDate = new Date(2000, 0, 1); // Date picker min date
	maxDate = new Date(new Date().getFullYear() + 5, 0, 1); // Date picker max date
	events: string[] = []; // Date picker
	dealerFControl = new FormControl(); // Dealer


	dealerFilteredOptions: Observable<ItemList[]>; // dealer
	currentTime = new Date();
	//----------------- list of columns 
	displayedColumns: string[] = ['RefStan', 'Status', 'UserLogin', 'Channel', 'Dealer', 'CompanyId', 'SubCompanyID', 'Account', 'Amount', 'Commission', 'CurrencyCode', 'RegDate'];

	dataSource = new MatTableDataSource(); // array of datas
	@ViewChild(MatPaginator) paginator: MatPaginator;

	constructor(public dialog: MatDialog,
		// Make service for post api datas
		private datePipe: DatePipe,
		private reportsDataService: ReportsDatasService,
		private providerService: ProviderService,
		private transactionService: TransactionService,
		private dateAdapter: DateAdapter<Date>,
        private globals: Globals
	) {
		//dateAdapter.setLocale('ru-RU'); // change this for change format date in select

		// Assign the data to the data source for the table to render
		// this.dataSource = new MatTableDataSource(users);
	}

	FilterForm: FormGroup; // Form initial
	//dealerDropValidator(controls): ValidatorFn {
	//    return (control: AbstractControl): { [key: string]: boolean } | null => {
	//        if ((control.value.length > 0) && (controls.indexOf(control.value) < 0)) {
	//            return { 'dealer': true };
	//        }
	//        return null;
	//    };
	//}
	ngOnInit() {

		this.paginationItemPerPage = 10;

		this.InitProviderFilter();
		this.InitDealerFilter();

		this.FilterForm = new FormGroup({
			'Dealer': new FormControl(), //, [this.dealerDropValidator(this.options)]),
			'dateFrom': new FormControl({ value: this.currentTime, disabled: true }, Validators.required),
			'dateTo': new FormControl({ value: this.currentTime, disabled: true }, Validators.required),
			'Account': new FormControl(''),
			'RefStan': new FormControl(''),
			'UserName': new FormControl(''),
			'ServiceDrop': new FormControl(''),
		});
		this.getFirstDatas();
	}
	//----------------------  
	public handlePage(e: any) {
		this.currentPage = e.pageIndex;
		this.paginationItemPerPage = e.pageSize;
		this.getFirstDatas();

	}
	// function for change tables page
	InitProviderFilter() {

		let p = localStorage.getItem('providerAll');
		if (p === null) {
			this.providerService.AllList().pipe(first())
				.subscribe(
					data => {
						localStorage.setItem('providerAll', JSON.stringify(data));
						this.services = data.map(x => ({ Value: x.ID.toString(), Name: x.Name }));
						this.services.unshift({ Value: '', Name: 'All' });
					},
					error => {
						console.log(error);
					});
		} else {
			let prvs = JSON.parse(p);
			this.services = prvs.map(x => ({ Value: x.ID.toString(), Name: x.Name }));
			this.services.unshift({ Value: '', Name: 'All' });
		}
	}
	//----------------------- get a dealer list and put to option's 
	InitDealerFilter() {
		// let dealers = localStorage.getItem('dealerAllList');
		// if (dealers === null) {
		// 	this.transactionService.getDealerDatasAsList().pipe(first())
		// 		.subscribe(
		// 			data => {
		// 				localStorage.setItem('dealerAllList', JSON.stringify(data.ReturnObject));
		// 				this.dealerListOptions = data.ReturnObject.map(x => ({ Value: x.Id.toString(), Name: x.Name }));
		// 				this.dealerListOptions.unshift({ Value: null, Name: 'All' });
		// 				this.dealerFilteredOptions = this.dealerFControl.valueChanges
		// 					.pipe(
		// 						startWith<string | ItemList>(''),
		// 						map(value => typeof value === 'string' ? value : value.Name),
		// 						map(name => name ? this._filter(name) : this.dealerListOptions.slice())
		// 					);//dealer

		// 			},
		// 			error => {
		// 				console.log(error);
		// 			});
		// } else {
		// 	let ds = JSON.parse(dealers);
		// 	this.dealerListOptions = ds.map(x => ({ Value: x.Id.toString(), Name: x.Name }));
		// 	this.dealerListOptions.unshift({ Value: null, Name: 'All' });
		// 	this.dealerFilteredOptions = this.dealerFControl.valueChanges
		// 		.pipe(
		// 			startWith<string | ItemList>(''),
		// 			map(value => typeof value === 'string' ? value : value.Name),
		// 			map(name => name ? this._filter(name) : this.dealerListOptions.slice())
		// 		);//dealer
		// }
	}

	getFirstDatas() {
		this.loading = true;
		let start = this.datePipe.transform(this.FilterForm.controls.dateFrom.value, 'yyyy-MM-ddT00:00:00');
		let finish = this.datePipe.transform(this.FilterForm.controls.dateTo.value, 'yyyy-MM-ddT23:59:59');
		// Take datas from form
		let dealerId = this.dealerFControl.value === null ? null : this.dealerFControl.value.Value;
		let filter = this.FilterForm.value;
		let currentPage = this.currentPage + 1;

		this.reportsDataService.getProblemrequestDatas(start, finish, filter, dealerId, currentPage, this.paginationItemPerPage)
			.pipe(first())
			.subscribe(
				data => {
					if (data.ResultCode === 0) {
						let obj = data.ReturnObject;
						console.log(obj);
						if (obj !== null) {
							this.Transactions_Datas = obj.Payments;
							this.dataSource = new MatTableDataSource(this.Transactions_Datas); // array of datas	

							//this.dataSource.sort = this.sort; // activate sort after insert datas
							// this.dataSource.paginator = this.paginator; // activate paginator after insert datas
							this.paginationItems = obj.Pagination.ItemTotal;
						}
						else {
							this.paginationItems = 0;
							this.Transactions_Datas = [];
							this.dataSource = new MatTableDataSource(this.Transactions_Datas); // array of datas	
						}
					}
					this.loading = false;
				},
				error => {
					this.loading = false;
					console.log(error);
				});
	}
	onSubmit() {
		this.getFirstDatas();
	}

	displayDealer(item?: ItemList): string | undefined {
		return item ? item.Name : undefined;
	}

	private _filter(item: string): ItemList[] {
		const filterValue = item.toLowerCase();
		return this.dealerListOptions.filter(option => option.Name.toLowerCase().indexOf(filterValue) === 0);
	}
	//----------------------
	PaginationCurentPageReset(e: any) {
		this.currentPage = 0;
	}
	//------------------- private helpers 
	// convenience getter for easy access to form fields
	get f() { return this.FilterForm.controls; }


    modalDatas = {};// for display datas in modal

    statusStates = [
        { value: 2, viewValue: 'Success' },
        { value: 6, viewValue: 'Close' },
        { value: 5, viewValue: 'Retransmit' },
    ];

	selectRow(templateRef, row) {
		this.isLoadingDetailInfo = true;
		let start = this.datePipe.transform(this.FilterForm.controls.dateFrom.value, 'yyyy-MM-ddT00:00:00');
		let finish = this.datePipe.transform(this.FilterForm.controls.dateTo.value, 'yyyy-MM-ddT23:59:59');
		this.reportsDataService.getAdvanceProblem(row.RefStan, start, finish)
			.pipe(first())
			.subscribe(
				data => {
					console.log(data);
					if (data.ResultCode === 0) {
						if (data.ReturnObject !== null) {
							this.modalDatas = data.ReturnObject;
							this.isLoadingDetailInfo = false;
							console.log(this.modalDatas);
						}
						else {
							this.isLoadingDetailInfo = false;
						}
					}
				},
				error => {
					this.isLoadingDetailInfo = false;
					console.log(error);
				});
		const dialogRef = this.dialog.open(templateRef, {
		});
	}

	private GetAllDealerFromStorage() {
		// let dealers = localStorage.getItem('dealerAllList');
		// if (dealers === null) {
		// 	this.administrationService.getDealerDatasAsList().pipe(first())
		// 		.subscribe(
		// 			data => {
		// 				localStorage.setItem('dealerAllList', JSON.stringify(data));
		// 				return data;
		// 			},
		// 			error => {
		// 				console.log(error);
		// 			});
		// } else
		// 	return JSON.parse(dealers);
	}
	CloseDialog(): void {
        this.dialog.closeAll();
    }
}