import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { AuthenticationService } from './authentication.service';


@Injectable()

export class MainService {

    constructor(private http: HttpClient, private authService: AuthenticationService) {

    }
    getProfile() {
        return this.http.get<any>(`${this.authService.basUrl}/api/profile/Get`)
            .pipe(map(response => {
                if (this.authService.checkTokenResponse()) {
                    return response;
                }
                // login successful if there's a jwt token in the response
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                
            }));
    }
    getQuantityNotification() {
        return this.http.get<any>(`${this.authService.basUrl}/api/notification/Get`)
            .pipe(map(response => {
                if (this.authService.checkTokenResponse()) {
                    return response;
                }
            }));
    }
    getNotifications() {
        return this.http.get<any>(`${this.authService.basUrl}/api/notification/gets/20`)
            .pipe(map(response => {
                if (this.authService.checkTokenResponse()) {
                    return response;
                }
            }));
    }
    markAsRead(id) {
        let body = {
			'Id' : id,
			'Type': 'one'
		}
        return this.http.post<any>(`${this.authService.basUrl}/api/notification/mark/${id}`, body, { headers: { 'Content-Type': 'application/json' } })
            .pipe(map(response => {
                if (this.authService.checkTokenResponse()) {
                    return response;
                }
            }));
    }
}


