import { NgModule } from '@angular/core';
import {CommonModule} from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { BrowserModule } from '@angular/platform-browser';
import { ReactiveFormsModule, FormsModule } from "@angular/forms";

import {
  RecaptchaModule,
  RECAPTCHA_SETTINGS,
  RecaptchaSettings,
} from "ng-recaptcha";
import { environment } from "../../../environments/environment";

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
    RecaptchaModule,
    
  ],
  providers: [
    {
      provide: RECAPTCHA_SETTINGS,
      useValue: {
          siteKey: environment.recaptchaSiteKey,
      } as RecaptchaSettings,
    },
  ],
  exports: [TranslateModule],
})
export class RecoveryModule {}
