import { Injectable } from "@angular/core";
import {
  HttpClient,
  HttpEventType,
  HttpHeaders,
  HttpRequest,
  HttpResponse,
} from "@angular/common/http";
import { map } from "rxjs/operators";
import { Cacheable } from "ngx-cacheable";
import { AuthenticationService } from "./authentication.service";
import { Observable, Subject } from "rxjs";

// const httpOptions = {
//   headers: new HttpHeaders({
//     'Content-Type':  'application/x-www-form-urlencoded;charset=UTF-8',
//     'Accept-Language': 'en-EN',
// })
// };
@Injectable()
export class ReportsDatasService {
  constructor(
    private http: HttpClient,
    private authService: AuthenticationService
  ) {}
  // Sales
  createSale(product, amount, phone, sale_type = 1, installment_id = null) {
    let body = {
      Description: product,
      Amount: amount,
      MobTelephone: phone,
      SalePaymentType: sale_type,
      InstallmentId: installment_id
    };
    return this.http
      .post<any>(this.authService.basUrl + `/api/sale/create`, body, {
        headers: {
          "Content-Type": "application/json",
          "Accept-Language": this.GetLang(),
        },
      })
      .pipe(
        map((response) => {
          if (this.authService.checkTokenResponse()) {
            return response;
          }
        })
      );
  }
  salesGets(
    start,
    finish,
    period,
    code,
    status,
    type,
    currentPage,
    itemsPerPage
  ) {
    return this.http
      .get<any>(
        this.authService.basUrl +
          `/api/sale/Gets/?pStartDate=` +
          start +
          `&pEndDate=` +
          finish +
          `&PeriodType=` +
          period +
          `&pCode=` +
          code +
          `&pStatus=` +
          status +
          "&type=" +
          type +
          `&FetchCount=` +
          itemsPerPage +
          `&FetchPage=` +
          currentPage
      )
      .pipe(
        map((response) => {
          if (this.authService.checkTokenResponse()) {
            return response;
          }
        })
      );
  }
  getSalesPdf(start, finish, period, code, status, type) {
    return this.http.get(
      this.authService.basUrl +
        `/api/sale/Export/?pStartDate=` +
        start +
        `&pEndDate=` +
        finish +
        `&PeriodType=` +
        period +
        `&pCode=` +
        code +
        `&pStatus=` +
        status +
        "&type=" +
        type,
      {
        responseType: "blob",
        headers: { "Accept-Language": this.GetLang() },
      }
    );
  }
  downloadSaleQRPDF(code: string) {
    return this.http.get(
      this.authService.basUrl + `/api/sale/DownloadPdf/` + code,
      {
        responseType: "blob",
        headers: { "Accept-Language": this.GetLang() },
      }
    );
  }

  // Transactions Datas

  // For get datas in all pages of table
  getTransactionsDatasCurrentPage(
    date_from,
    date_to,
    filter,
    dealerId,
    currentPage,
    item_per_page
  ) {
    return this.http
      .get<any>(
        this.authService.basUrl +
          `/api/report/Transactions?DateFrom=` +
          date_from +
          `&DateTo=` +
          date_to +
          "&Account=" +
          filter.Account +
          "&UserName=" +
          filter.UserName +
          "&DealerId=" +
          dealerId +
          "&CompanyId=" +
          filter.ServiceDrop +
          "&Status=&RefStan=" +
          filter.RefStan +
          "&SalePointId=" +
          filter.ChannelDrop.Value +
          "&OpType=" +
          filter.PaidTypeDrop +
          "&CurPage=" +
          currentPage +
          "&ItemPerPage=" +
          item_per_page
      )
      .pipe(
        map((response) => {
          return response;
        })
      );
  }
  // For get advance datas in modal.
  getAdvanceTransaction(refstan) {
    return this.http
      .get<any>(this.authService.basUrl + `/api/report/Transaction/` + refstan)
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  getFile(date_from, date_to, filter, currentPage, item_per_page, format) {
    if (format === "pdf") {
      return this.http.get(
        this.authService.basUrl +
          `/api/report/TransactionListToPDF?DateFrom=` +
          date_from +
          `&DateTo=` +
          date_to +
          "&Account=" +
          filter.Account +
          "&UserName=" +
          filter.UserName +
          "&DealerId=" +
          filter.Dealer +
          "&CompanyId=" +
          filter.ServiceDrop +
          "&Status=&RefStan=" +
          filter.RefStan +
          "&SalePointId=" +
          filter.ChannelDrop +
          "&OpType=" +
          filter.PaidTypeDrop +
          "&CurPage=" +
          currentPage +
          "&ItemPerPage=" +
          item_per_page,
        {
          responseType: "blob",
          headers: { "Accept-Language": this.GetLang() },
        }
      );
    }
    return this.http.get(
      this.authService.basUrl +
        `/api/report/TransactionListToExcel?DateFrom=` +
        date_from +
        `&DateTo=` +
        date_to +
        "&Account=" +
        filter.Account +
        "&UserName=" +
        filter.UserName +
        "&DealerId=" +
        filter.Dealer +
        "&CompanyId=" +
        filter.ServiceDrop +
        "&Status=&RefStan=" +
        filter.RefStan +
        "&SalePointId=" +
        filter.ChannelDrop +
        "&OpType=" +
        filter.PaidTypeDrop +
        "&CurPage=" +
        currentPage +
        "&ItemPerPage=" +
        item_per_page,
      {
        responseType: "blob",
        headers: { "Accept-Language": this.GetLang() },
      }
    );
    // .pipe(map(res => {
    //     console.log("getExcell !!!");
    //     console.log(res);
    //     return {
    //         filename: 'filename.pdf',
    //         data: res.blob()
    //     };
    // }));
  }

  // Problem-payments

  getProblemrequestDatas(
    dateFrom,
    dateTo,
    filter,
    dealerId,
    currentPage,
    item_per_page
  ) {
    return this.http
      .get<any>(
        this.authService.basUrl +
          `/api/report/ProblemPaymentsReport?DateFrom=` +
          dateFrom +
          `&DateTo=` +
          dateTo +
          "&Account=" +
          filter.Account +
          "&UserName=" +
          filter.UserName +
          "&Dealer=" +
          dealerId +
          "&CompanyId=" +
          filter.ServiceDrop +
          "&Status=-1&RefStan=&CurPage=" +
          currentPage +
          "&ItemPerPage=" +
          item_per_page
      )
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  getAdvanceProblem(refstan, dateFrom, dateTo) {
    return this.http
      .get<any>(
        this.authService.basUrl +
          `/api/report/ProblemPaymentInfo?pRefStan=` +
          refstan +
          "&pDateStart=" +
          dateFrom +
          "&pDateFinish=" +
          dateTo
      )
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  // Deposit operations

  getAllDeposit(dealerId, dateFrom, dateTo, curPage, itemPerPage) {
    return this.http
      .get<any>(
        this.authService.basUrl +
          `/api/report/DepositReport?pDealerId=` +
          dealerId +
          "&pDateStart=" +
          dateFrom +
          "&pDateFinish=" +
          dateTo +
          "&CurPage=" +
          curPage +
          "&ItemPerPage=" +
          itemPerPage
      )
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  // System Request

  getSystemDatas(
    dateFrom,
    dateTo,
    filter,
    dealerId,
    currentPage,
    item_per_page
  ) {
    return this.http
      .get<any>(
        this.authService.basUrl +
          `/api/report/SystemRequestsReport?DateFrom=` +
          dateFrom +
          `&DateTo=` +
          dateTo +
          "&Account=" +
          filter.Account +
          "&UserName=" +
          filter.UserName +
          "&DealerId=" +
          dealerId +
          "&CompanyId=" +
          filter.ServiceDrop +
          "&ResponseCode=&ExternalId=&MethodType=&CurPage=" +
          currentPage +
          "&ItemPerPage=" +
          item_per_page
      )
      .pipe(
        map((response) => {
          return response;
        })
      );
  }
  // // For get advance datas in modal.
  getAdvanceSystem(pKey) {
    return this.http
      .get<any>(
        this.authService.basUrl + `/api/report/SystemRequestInfo?pKey=` + pKey
      )
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  //New Views
  getCreateInvoice(key) {
    return this.http
      .get<any>(
        this.authService.basUrl + `/api/invoicing/start?invoiceKey=` + key
      )
      .pipe(
        map((response) => {
          if (this.authService.checkTokenResponse()) {
            return response;
          }
        })
      );
  }

  getEditInvoiceFiles(key) {
    return this.http
      .get<any>(
        this.authService.basUrl +
          `/api/invoicing/getattachments?invoiceKey=` +
          key
      )
      .pipe(
        map((response) => {
          if (this.authService.checkTokenResponse()) {
            return response;
          }
        })
      );
  }

  saveInvoice(
    data,
    email,
    dateDue,
    total,
    items,
    files,
    state,
    key,
    phone,
    address,
    name,
    salePaymentType = 1,
    installmentId = null
  ) {
    let body = {
      Invoice: {
        Number: data.InvoiceNumber,
        DateDue: dateDue,
        Amount: total,
        Note: data.Comments,
        DiscountValue: Math.round(Number(data.Discount) * 100),
        TaxesValue: Math.round(Number(data.Taxes) * 100),
        Key: key,
        SalePaymentType: salePaymentType,
        InstallmentId: installmentId

      },
      SendImmediately: state,
      ProductList: [],
      BuyerList: [
        {
          Email: email,
          PhoneNumber: phone,
          Address: address,
          Name: name,
        },
      ],
    };

    items.forEach((target) => {
      body.ProductList.push({
        UnitPrice: Math.round(Number(target.Amount) * 100),
        Quantity: Number(target.Quantity),
        Description: target.Description,
      });
    });
    if (files.length) {
      body.Invoice["Attachments"] = files;
    }
    return this.http
      .post<any>(this.authService.basUrl + `/api/invoicing/save`, body, {
        headers: {
          "Content-Type": "application/json",
          "Accept-Language": this.GetLang(),
        },
      })
      .pipe(
        map((response) => {
          if (this.authService.checkTokenResponse()) {
            return response;
          }
        })
      );
  }

  withDrawGets(start, finish, period, order, currentPage, itemsPerPage) {
    return this.http
      .get<any>(
        this.authService.basUrl +
          `/api/withdraw/Gets/?StartDate=` +
          start +
          `&FinishDate=` +
          finish +
          `&PeriodType=` +
          period +
          `&OrderId=` +
          order +
          `&FetchCount=` +
          itemsPerPage +
          `&FetchPage=` +
          currentPage
      )
      .pipe(
        map((response) => {
          if (this.authService.checkTokenResponse()) {
            return response;
          }
        })
      );
  }
  getInvoiceHistore(
    period,
    start,
    finish,
    status,
    invoiceEmail,
    invoiceNumber,
    itemsPerPage,
    currPage
  ) {
    return this.http
      .get<any>(
        this.authService.basUrl +
          `/api/invoicing/GetHistory/?PeriodType=` +
          period +
          `&DateStart=` +
          start +
          `&DateFinish=` +
          finish +
          `&Status=` +
          status +
          `&BuyerEmail=` +
          invoiceEmail +
          `&InvoiceNumber=` +
          invoiceNumber +
          `&FetchCount=` +
          itemsPerPage +
          `&FetchPage=` +
          currPage
      )
      .pipe(
        map((response) => {
          if (this.authService.checkTokenResponse()) {
            return response;
          }
        })
      );
  }

  getWithdrawPdf(start, finish, period, order, currentPage, itemsPerPage) {
    return this.http.get(
      this.authService.basUrl +
        `/api/withdraw/DownloadPdf?StartDate=` +
        start +
        `&FinishDate=` +
        finish +
        `&PeriodType=` +
        period +
        `&OrderId=` +
        order,
      {
        responseType: "blob",
        headers: { "Accept-Language": this.GetLang() },
      }
    );
  }

  private GetLang() {
    switch (localStorage.getItem("currentLang")) {
      case "ru":
        return "ru-RU";
      case "tr":
        return "tr-TR";
    }
    return "en-US";
  }
}
