import { Component, OnInit, ViewChild, ViewEncapsulation } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import {
  FormGroup,
  FormControl,
  Validators,
  FormGroupDirective,
  NgForm
} from "@angular/forms";
import { first } from "rxjs/operators";
import { ContactsService } from "../../_services/contacts.service";
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';
import { ErrorStateMatcher } from '@angular/material/core';
import { DatePipe } from "@angular/common";
/** Constants used to fill up our data base. */
import * as SecureLS from "secure-ls";
import { ToastrService } from "ngx-toastr";
import { TranslateService } from "@ngx-translate/core";
import { Globals } from "../../globals/globals";

// For custom Error Validations Messages
export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(
    control: FormControl | null,
    form: FormGroupDirective | NgForm | null
  ): boolean {
    const invalidCtrl = !!(control && control.invalid && control.parent.dirty);
    const invalidParent = !!(
      control &&
      control.parent &&
      control.parent.invalid &&
      control.parent.dirty
    );

    return invalidCtrl || invalidParent;
  }
}
export interface Countries {
  Key: string;
  Name: string;
}
@Component({
  templateUrl: "./invoice-contacts.component.html",
  styleUrls: ["../base/base.scss", "invoice.scss"],
  providers: [DatePipe, Globals]
})
export class InvoiceContacts implements OnInit {
  matcher = new MyErrorStateMatcher(); // for my Error state | Colors and other
  permissions;
  allPageLoad: boolean = false;
  isLoadingDetailInfo: boolean = false; // spinner
  public paginationItems; // for display pagination from api
  public paginationItemPerPage; // for display pagination from api

  public currentPage = 0;

  loading = false; // for disable fast response click
  pageEvent; // for fix error of pageEvent in Visual Studio
  panelOpenState = true;
  createContactError = ""; // Validation on User
  Key = "";
  pasteFalse(event) {
    event.preventDefault();
  }
      allNumbers = [48,49,50,51,52,53,54,55,56,57,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,187,189,192];

  onlyAlphabs(evt) {
    evt = evt ? evt : event;
    let charCode = evt.charCode
        ? evt.charCode
        : evt.keyCode
        ? evt.keyCode
        : evt.which
        ? evt.which
        : 0;
    console.log("CharCode::", charCode);
    if ( this.allNumbers.indexOf(charCode) !== -1) {
      return false;
    }
  }
  onlyNumbers(e) {
    if (
      (e.keyCode >= 48 && e.keyCode <= 57) ||
      (e.keyCode >= 96 && e.keyCode <= 105) ||
      e.keyCode == 187 ||
      e.keyCode == 107 ||
      e.keyCode == 8
    ) {
      return true;
    } else {
      return false;
    }
  }
  //----------------- list of columns
  displayedColumns: string[] = [
    "Name",
    "Email",
    "Phone",
    "Address",
    "Note",    
  ];
  dataSource = new MatTableDataSource(); // array of datas
  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(
    public dialog: MatDialog,
    // Make service for post api datas
    private contactsService: ContactsService,
    public translate: TranslateService,

    public toastr: ToastrService,
    public route: ActivatedRoute
  ) {
    //this.ChangePassword = this.formBuilder.group({
    //    'Password': ['', [Validators.required, Validators.pattern(/^(?=(.*[a-z]){1,})(?=(.*[\d]){1,})(?=(.*[\W]){1,})(?!.*\s).{8,16}$/), Validators.minLength(8), Validators.maxLength(16)]],
    //    'RepeatPassword': ['', [Validators.required]]
    //
    //}, { validator: this.checkPasswords });
    // Assign the data to the data source for the table to render
    // this.dataSource = new MatTableDataSource(users);
  }
  editContactError = "";
  FilterForm: FormGroup; // Form initial
  CreateContact: FormGroup; // Form initial
  EditContact: FormGroup; // Form initial
  countryList: Array<Countries> = [];

  FilterGender = [
    { Key: "", Name: "All" },
    { Key: 0, Name: "M" },
    { Key: 1, Name: "F" }
  ];

  getFirstDatas() {
    this.loading = true;
    this.contactsService
      .getContactsList(
        this.currentPage + 1,
        this.paginationItemPerPage,
        this.FilterForm.value
      )
      .subscribe(
        data => {
          //console.log(data);
          let datas = [];
          this.paginationItems = 0;
          if (data.ResultCode === 0) {
            if (data.ReturnObject !== null) {
              datas = data.ReturnObject.Contacts;
              this.paginationItems = data.ReturnObject.Fetch.Cnt;
            }
          }
          this.dataSource = new MatTableDataSource(
            datas.length === 0 ? [] : datas
          ); // array of datas
          this.loading = false;
        },
        error => {
          this.loading = false;
          console.log(error);
        },
        () => {
          this.allPageLoad = true;
        }
      );
  }

  onSubmit() {
    this.getFirstDatas();
  }

  openCreateContact(templateRef) {
    this.createContactError = "";
    if (this.loading === false) {
      //this.loading = true;
      this.openModal(templateRef);
      //this.isLoadingDetailInfo = true;
      //this.administrationService.getRolesUser()
      //    .pipe(first())
      //    .subscribe(
      //        data => {
      //            console.log(data);
      //            if (data.ResultCode === 0) {
      //                this.rolesList = data.ReturnObject;
      //                if (this.rolesList.length) {
      //                    let counter = 0;
      //                    this.rolesList.forEach(role => {
      //                        role['Id'] = counter;
      //                        this.CreateUser.removeControl('Key_' + role.Id);
      //                        this.CreateUser.removeControl('Value_' + role.Id);
      //                        this.CreateUser.addControl('Key_' + role.Id, new FormControl(role.Key)); // Initialize form rules
      //                        this.CreateUser.addControl('Value_' + role.Id, new FormControl(role.IsOn)); // Initialize form rules
      //                        counter++;
      //                    });
      //                }
      //                console.log(this.rolesList);
      //            }
      //            else {
      //                this.createUserError = data.ResultMessage;
      //            }
      //            this.isLoadingDetailInfo = false;
      //            this.loading = false;

      //        },
      //        error => {
      //            this.isLoadingDetailInfo = false;
      //            this.loading = false;
      //            console.log(error);
      //        });
      //Api for modal detail information

      this.isLoadingDetailInfo = false;
      this.loading = false;
    }
  }

  openEditContact(templateRef, Id) {
    this.editContactError = "";
    if (this.loading === false) {
      this.loading = true;
      this.openModal(templateRef);
      this.isLoadingDetailInfo = true;
      this.contactsService
        .getContactInfo(Id)
        .pipe(first())
        .subscribe(
          data => {
            console.log(data);
            if (data.ResultCode === 0) {
              if (data.ReturnObject !== null) {
                //this.modalDatas = data.ReturnObject;
                //this.editDatasPush(this.modalDatas)
                this.EditContact.patchValue({
                  ["Name"]: data.ReturnObject.Name
                });
                this.EditContact.patchValue({
                  ["Email"]: data.ReturnObject.Email
                });
                this.EditContact.patchValue({
                  ["Address"]: data.ReturnObject.Address
                });
                this.EditContact.patchValue({
                  ["City"]: data.ReturnObject.City
                });
                this.EditContact.patchValue({
                  ["State"]: data.ReturnObject.State
                });
                this.EditContact.patchValue({
                  ["CountryKey"]: data.ReturnObject.CountryKey
                });
                this.EditContact.patchValue({
                  ["ZipCode"]: data.ReturnObject.ZipCode
                });
                this.EditContact.patchValue({
                  ["Phone"]: data.ReturnObject.Phone
                });
                this.EditContact.patchValue({
                  ["Note"]: data.ReturnObject.Note
                });
                this.EditContact.patchValue({ ["Key"]: data.ReturnObject.Key });
              }
            }
            this.isLoadingDetailInfo = false;
            this.loading = false;
          },
          error => {
            this.isLoadingDetailInfo = false;
            this.loading = false;
            console.log(error);
          }
        );
      // Api for modal detail information
    }
  }

  openDeleteContact(templateRef, key) {
    //this.createContactError = '';
    //if (this.loading === false) {
    //
    this.openModal(templateRef);
    //	this.isLoadingDetailInfo = false;
    //	this.loading = false;
    //}
    this.Key = key;
  }

  newContact() {
    this.createContactError = "";
    if (this.CreateContact.valid === true) {
      // Make again gets all users()
      this.loading = true;
      let sended_datas = [];
      //this.finally_array.forEach(final => {
      //	if (final.Changed == 1) {
      //		sended_datas.push(final);
      //		final.Value = this.CreateContact.value[final.Key];
      //	}
      //});
      this.contactsService
        .createContact(this.CreateContact.value)
        .subscribe(
          data => {
            console.log(data);
            if (data.ResultCode === 0) {
              //console.log('User Datas Changed');
              this.loading = false;
              this.CreateContact.reset();
              this.CloseDialog();
              this.getFirstDatas();
            } else {
              this.loading = false;
              this.editContactError = data.ResultMessage;
            }
          },
          error => {
            this.loading = false;
            console.log(error);
          }
        );
    }
  }

  saveContact() {
    console.log(this.EditContact);
    //this.markFormGroupTouched(this.EditForm);
    this.editContactError = "";
    if (this.EditContact.valid === true) {
      this.loading = true;
      this.contactsService
        .saveContact(this.EditContact.value)
        .pipe(first())
        .subscribe(
          data => {
            console.log(data);
            if (data.ResultCode === 0) {
              //console.log('User Datas Changed');
              this.loading = false;
              this.getFirstDatas();
              this.CloseDialog();
            } else {
              this.loading = false;
              this.editContactError = data.ResultMessage;
            }
          },
          error => {
            this.loading = false;
            console.log(error);
          }
        );
    }
  }

  deleteContact_r() {
    //this.editContactError = '';
    this.loading = true;
    this.contactsService
      .deleteContact(this.Key)
      .subscribe(
        data => {
          console.log(data);
          if (data.ResultCode === 0) {
            this.loading = false;
            let theme = "";
            let body = "";
            this.translate
              .get("INVOICE.THEME_CONTACTDELETE_SUCCESS")
              .subscribe((text) => {
                theme = text;
              });
            this.translate
              .get("INVOICE.BODY_CONTACTDELETE_SUCCESS")
              .subscribe((text) => {
                body = text;
              });
            this.toastr.success(body, theme);
            this.getFirstDatas();
            
            this.CloseDialog();
          } else {
            let theme = "";
            let body = "";
            this.translate
              .get("INVOICE.THEME_CONTACTDELETE_ERROR")
              .subscribe((text) => {
                theme = text;
              });
            body = data.ResultMessage;
            this.toastr.error(body, theme);
            this.loading = false;
            //this.editContactError = data.ResultMessage;
          }
        },
        error => {
          this.loading = false;
          console.log(error);
        }
      );
  }

  openModal(templateRef) {
    this.isLoadingDetailInfo = false;
    const dialogRef = this.dialog.open(templateRef, {
      panelClass: "modal_transaction"
    });
  }

  PaginationCurentPageReset(e: any) {
    this.currentPage = 0;
  }
  //------------------- private helpers
  // convenience getter for easy access to form fields
  get f() {
    return this.FilterForm.controls;
  }

  statusStates = [
    { value: false, viewValue: "InActive" },
    { value: true, viewValue: "Active" }
  ];

  CloseDialog(): void {
    this.dialog.closeAll();
  }

  public handlePage(e: any) {
    this.currentPage = e.pageIndex;
    this.paginationItemPerPage = e.pageSize;
    this.getFirstDatas();
  }

  //makeChanged(target) {
  //	this.finally_array.find(x => x.Key == target).Changed = 1;
  //}

  ngOnInit() {
    this.paginationItemPerPage = 10;
    let ls = new SecureLS();
    this.permissions = ls.get("permissions")[
      this.route.data["value"].pathCheck
    ];
    if(this.permissions.write){
      this.displayedColumns.push("Actions");
    }
    this.translate.get("INVOICE.GENDER_ALL").subscribe(text => {
      this.FilterGender[0].Name = text;
    });
    this.translate.get("INVOICE.GENDER_MALE").subscribe(text => {
      this.FilterGender[1].Name = text;
    });
    this.translate.get("INVOICE.GENDER_FEMALE").subscribe(text => {
      this.FilterGender[2].Name = text;
    });
    this.FilterForm = new FormGroup({
      Name: new FormControl(""),
      Email: new FormControl("", Validators.email),
      Phone: new FormControl(""),
      Gender: new FormControl(this.FilterGender[0].Key)
    }); // for header Filter

    this.CreateContact = new FormGroup({
      Name: new FormControl("", Validators.required),
      Email: new FormControl("", [
        Validators.required,
        Validators.pattern(
          /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        )
      ]),
      Address: new FormControl(""),
      City: new FormControl(""),
      State: new FormControl(""),
      ZipCode: new FormControl(""),
      CountryKey: new FormControl(""),
      Phone: new FormControl(""),
      Note: new FormControl("")
      //"Gender": new FormControl(this.FilterGender[0].Key)
    }); // for header Filter

    this.EditContact = new FormGroup({
      Name: new FormControl("", Validators.required),
      Email: new FormControl("", [
        Validators.required,
        Validators.pattern(
          /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        )
      ]),
      Address: new FormControl(""),
      City: new FormControl(""),
      State: new FormControl(""),
      ZipCode: new FormControl(""),
      CountryKey: new FormControl(""),
      Phone: new FormControl(""),
      Note: new FormControl(""),
      Key: new FormControl("")
      //"Gender": new FormControl(this.FilterGender[0].Key)
    }); // for header Filter

    this.contactsService.takeListCountries().subscribe(
      data => {
        if (data.ResultCode === 0) {
          this.countryList = data.ReturnObject;
          console.log(this.countryList);
        } else {
        }
      },
      error => {
        console.log(error);
      }
    );

    this.getFirstDatas();
  }
}
