import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { map, catchError } from "rxjs/operators";
import { HttpParams } from "@angular/common/http";
import { Router } from "@angular/router";
import * as SecureLS from "secure-ls";
import { environment } from "./../../environments/environment";

const httpOptions = {
  headers: new HttpHeaders({
    "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
    "Accept-Language": "tr-TR",
  }),
};

const httpJsonOptions = {
  headers: new HttpHeaders({
    "Content-Type": "application/json",
    "Accept-Language": "tr-TR",
  }),
};
@Injectable()
export class AuthenticationService {
  //baseHostUrl: string = '';  // localhost
  basUrl: string = environment.apiUrl;
  // baseHostUrl: string = "https://cardplus-merchant-api.test.paypoint.md"; // test host
  constructor(private http: HttpClient, private router: Router) {}

  login(
    username: string,
    password: string,
    language: string,
    merchantCode: string,
    remember
  ) {
    console.log(remember);
    let body = new HttpParams();
    body = body.set("username", username);
    body = body.set("password", password);
    body = body.set("grant_type", "password");
    body = body.set("merchant_code", merchantCode);
    body = body.set("remember", remember);
    return this.http
      .post<any>(this.basUrl + `/auth`, body, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
          "Accept-Language": this.GetLang(),
        },
      })
      .pipe(
        map((user) => {
          console.log(user);
          if (user !== null) {
            user.resultCode = 0;
            let date = user.expires_in - 600; // takes from api time -600 sec(10 min)
            date = date * 1000;
            let currentTimeLogin = new Date().valueOf();
            let expiration_date = currentTimeLogin + date;
            localStorage.setItem(
              "currentUser",
              JSON.stringify({
                token: user.access_token,
                login: username,
                tokenTime: expiration_date,
              })
            );
            localStorage.setItem("currentLang", language);
            let ls = new SecureLS(); // for Secure Local storage
            ls.set("permissions", {
              pages: {
                dashboard: {
                  state: true,
                },
              },
            });
          } else {
            user = new User();
            user.resultCode = 64;
            user.msg = "Invalid login or password !";
          }
          return user;
        })
      );
  }
  passwordResetRequest(email, captcha) {
    console.log("Recovery Data::", email, captcha);
    let body = new HttpParams();
    body = body.set("Login", email);
    body = body.set("Captcha", captcha);
    // let body = {
    //   pRequest: {
    //     Login: email,
    //   },

    //   RecaptchaFromUI: captcha,
    // };
    return this.http
      .post<any>(this.basUrl + `/api/account/PasswordResetRequest`, body, {
        headers: {
          "Accept-Language": this.GetLang(),
          "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
        },
      })
      .pipe(
        map((response) => {
          // login successful if there's a jwt token in the response
          // store user details and jwt token in local storage to keep user logged in between page refreshes
          return response;
        })
      );
  }
  passwordResetCheck(token, key, id) {
    console.log(token);
    console.log(key);
    console.log(id);
    let body = new HttpParams();
    body = body.set("Code", key);
    body = body.set("Token", token);
    body = body.set("Id", id);
    return this.http
      .post<any>(this.basUrl + `/api/account/PasswordResetCheck`, body, {
        headers: {
          "Accept-Language": this.GetLang(),
          "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
        },
      })
      .pipe(
        map((response) => {
          // login successful if there's a jwt token in the response
          // store user details and jwt token in local storage to keep user logged in between page refreshes
          return response;
        })
      );
  }
  changePassword(password, passwordconfirm, token, key, id) {
    let body = new HttpParams();
    body = body.set("Code", key);
    body = body.set("Token", token);
    body = body.set("Id", id);
    body = body.set("Password", password);
    body = body.set("PasswordConfirm", passwordconfirm);
    return this.http
      .post<any>(this.basUrl + `/api/account/PasswordResetChange`, body, {
        headers: {
          "Accept-Language": this.GetLang(),
          "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
        },
      })
      .pipe(
        map((response) => {
          // login successful if there's a jwt token in the response
          // store user details and jwt token in local storage to keep user logged in between page refreshes
          return response;
        })
      );
  }
  isTokenExpired(): boolean {
    let token = JSON.parse(localStorage.getItem("currentUser"));
    if (!token) return true;

    const date = token.tokenTime;

    if (date === undefined) return false;

    return !(date.valueOf() > new Date().valueOf());
  }

  checkTokenResponse(): boolean {
    if (this.isTokenExpired()) {
      this.router.navigate(["/login"], { queryParams: { error: "401" } });
      return false;
    }
    return true;
  }

  logout() {
    // remove user from local storage to log user out
    localStorage.removeItem("currentUser");
    localStorage.removeItem("permissions");
    localStorage.removeItem("providerAll");
    localStorage.removeItem("providerTop");
    localStorage.removeItem("providerGroup");
    localStorage.removeItem("dealerAllList");
  }

  private GetLang() {
    switch (localStorage.getItem("currentLang")) {
      case "ru":
        return "ru-RU";
      case "tr":
        return "tr-TR";
    }
    return "en-US";
  }
}

export class User {
  token: string;
  login: string;
  tokenTime: string;
  resultCode: number;
  msg: string;
}
