import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, FormControl, Validators, AbstractControl, ValidatorFn } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith, first } from 'rxjs/operators';
import { AlertService, UserService, ProviderService } from '../../_services';
import { DateAdapter } from '@angular/material/core';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from "@angular/material/paginator";
import { MatDialog } from "@angular/material/dialog";
import { MatTableDataSource } from "@angular/material/table";
import { DataSource } from '@angular/cdk/table';
import { DatePipe } from '@angular/common';
import { ReportsDatasService } from '../../_services/reportsdata.service';
import { saveAs } from 'file-saver';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { TransactionService } from '../../_services/transaction.service';

/** Constants used to fill up our data base. */

export interface standartType {
	value: string;
	viewValue: string;
} // standart Interface

export interface ItemList {
	Value: string;
	Name: string;
}


@Component({
	templateUrl: 'transaction-report.component.html',
	styleUrls: ['reports.scss'],
	providers: [DatePipe]
})
export class TransactionReportComponent implements OnInit {

	public totalAmountBody = 0;
	public totalAmountFee = 0;
	public totalAmountTotal = 0;

	public paginationItems; // for display pagination from api
	public paginationItemPerPage; // for display pagination from api
	saleFilteredOptions: Observable<ItemList[]>; // salePoints

	public currentPage = 0;
	isLoadingDetailInfo: boolean = false;
	modalDatas = {
		'Request': {},
		'Response': {},
	};// for display datas in modal

	//-------------------------------------
	providersList;
	isDownloadingFile: boolean = false;
	Transactions_Datas;
	dealerListOptions: ItemList[]; // = [{ Value: '1', Name: 'Mase 1' }, { Value: '2', Name: 'Dealer 222' }, { Value: '3', Name: 'Feadea' }, { Value: '4', Name: 'Grisha' }];
	loading = false; // for disable fast response click
	pageEvent; // for fix error of pageEvent in Visual Studio
	panelOpenState = true;
	// For change pages

	services: ItemList[];

	account = ''; // Account input
	refStan = ''; // refStan input
	serviceSelected = ''; // Service selected
	userLogin = ''; // User Login input

	minDate = new Date(2000, 0, 1); // Date picker min date
	maxDate = new Date(new Date().getFullYear() + 5, 0, 1); // Date picker max date
	events: string[] = []; // Date picker
	dealerFControl = new FormControl(); // Dealer
	saleListOptions: ItemList[];


	paidTypes: standartType[] = [
		{ value: 'all', viewValue: 'All transactions' },
		{ value: 'cash', viewValue: 'Cash' },
		{ value: 'ewallet', viewValue: 'E-Wallet' }
	]; // Select Channel

	operations: standartType[] = [
		{ value: 'in', viewValue: 'Cash In' },
		{ value: 'out', viewValue: 'Cash Out' }
	]; // Select Channel


	dealerFilteredOptions: Observable<ItemList[]>; // dealer
	currentTime = new Date();
	//----------------- list of columns 
	displayedColumns: string[] = ['RefStan', 'DealerName', 'User', 'CompanyName', 'Account', 'TransfAmount', 'Commision', 'Amount', 'Currency', 'TrnDate'];

	dataSource = new MatTableDataSource(); // array of datas
	@ViewChild(MatPaginator) paginator: MatPaginator;

	constructor(public dialog: MatDialog,
		// Make service for post api datas
		private datePipe: DatePipe,
		private reportsDataService: ReportsDatasService,
		private providerService: ProviderService,
		private transactionService: TransactionService,
		private dateAdapter: DateAdapter<Date>,
		private reportsDatasService: ReportsDatasService
	) {
		// translate.onLangChange.pipe(untilDestroyed(this)).subscribe((event: LangChangeEvent) => {
		// 	dateAdapter.setLocale(translate.currentLang + '-' + translate.currentLang.toUpperCase()); // change this for change format date in select
		// 	console.log(translate.currentLang);
		// 	console.log(translate.currentLang.toUpperCase());
		// })



		dateAdapter.setLocale('en-EN'); // change this for change format date in select

		// Assign the data to the data source for the table to render
		// this.dataSource = new MatTableDataSource(users);
	}

	FilterForm: FormGroup; // Form initial
	//dealerDropValidator(controls): ValidatorFn {
	//    return (control: AbstractControl): { [key: string]: boolean } | null => {
	//        if ((control.value.length > 0) && (controls.indexOf(control.value) < 0)) {
	//            return { 'dealer': true };
	//        }
	//        return null;
	//    };
	//}

	@ViewChild(MatSort) sort: MatSort;

	ngOnInit() {
		this.paginationItemPerPage = 10;

		this.FilterForm = new FormGroup({
			'Dealer': new FormControl(), //, [this.dealerDropValidator(this.options)]),
			'dateFrom': new FormControl({ value: this.currentTime, disabled: true }, Validators.required),
			'dateTo': new FormControl({ value: this.currentTime, disabled: true }, Validators.required),
			'Account': new FormControl(''),
			'RefStan': new FormControl(''),
			'UserName': new FormControl(''),
			'ServiceDrop': new FormControl(''),
			'ChannelDrop': new FormControl(''),
			'PaidTypeDrop': new FormControl(''),
			'OperationDrop': new FormControl(''),
		});
		this.getFirstDatas();
	}
	//----------------------  
	public handlePage(e: any) {
		this.currentPage = e.pageIndex;
		this.paginationItemPerPage = e.pageSize;
		this.getFirstDatas();
	}
	displaySale(item?: ItemList): string | undefined {
		return item ? item.Name : undefined;
	}
	
	getFirstDatas() {
		this.loading = true;
		let start = this.datePipe.transform(this.FilterForm.controls.dateFrom.value, 'yyyy-MM-ddT00:00:00');
		let finish = this.datePipe.transform(this.FilterForm.controls.dateTo.value, 'yyyy-MM-ddT23:59:59');
        // Take datas from form
        console.log(this.dealerFControl);
		let dealerId = this.dealerFControl.value === null ? null : this.dealerFControl.value.Value;
		let filter = this.FilterForm.value;
		let currentPage = this.currentPage + 1;

		this.reportsDatasService.getTransactionsDatasCurrentPage(start, finish, filter, dealerId, currentPage, this.paginationItemPerPage)
			.pipe(first())
			.subscribe(
				data => {
					console.log(data);
					if (data.ResultCode === 0) {
						let obj = data.ReturnObject;
						console.log(obj);
						if (obj !== null) {
							this.Transactions_Datas = obj.Items;
							this.dataSource = new MatTableDataSource(this.Transactions_Datas); // array of datas	

							this.dataSource.sort = this.sort; // activate sort after insert datas
							// this.dataSource.paginator = this.paginator; // activate paginator after insert datas
							this.paginationItems = obj.Pagination.ItemTotal;

							this.totalAmountBody = obj.Total.AmountBody;
							this.totalAmountFee = obj.Total.AmountFee;
							this.totalAmountTotal = obj.Total.AmountTotal;
						}
						else {
							this.totalAmountBody = 0;
							this.totalAmountFee = 0;
							this.totalAmountTotal = 0;
							this.paginationItems = 0;
							this.Transactions_Datas = [];
							this.dataSource = new MatTableDataSource(this.Transactions_Datas); // array of datas	
						}
					}
					this.loading = false;
				},
				error => {
					this.loading = false;
					console.log(error);
				});
	}

	download(format) {
		this.isDownloadingFile = true;
		this.loading = true;
		let start = this.datePipe.transform(this.FilterForm.controls.dateFrom.value, 'yyyy-MM-ddT00:00:00');
		let finish = this.datePipe.transform(this.FilterForm.controls.dateTo.value, 'yyyy-MM-ddTHH:mm:ss');
		this.reportsDatasService.getFile(start, finish, this.FilterForm.value, this.currentPage + 1, this.paginationItemPerPage, format).subscribe(
			res => {
				this.isDownloadingFile = false;
				this.loading = false;
				saveAs(res, finish + '.' + format, { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
			},
			error => {
				console.log(error);
				this.loading = false;
				this.isDownloadingFile = false;
			});
	}

	onSubmit() {
		this.getFirstDatas();
	}

	displayDealer(item?: ItemList): string | undefined {
		return item ? item.Name : undefined;
	}

	//----------------------
	PaginationCurentPageReset(e: any) {
		this.currentPage = 0;
	}
	//------------------- private helpers 
	// convenience getter for easy access to form fields
	get f() { return this.FilterForm.controls; }

	CloseDialog(): void {
		this.dialog.closeAll();
	}

	selectRow(templateRef, row) {
		this.isLoadingDetailInfo = true;
		this.reportsDatasService.getAdvanceTransaction(row.RefStan)
			.pipe(first())
			.subscribe(
				data => {
					console.log(data);
					if (data.ResultCode === 0) {
						if (data.ReturnObject !== null) {
							this.modalDatas = data.ReturnObject;
							this.isLoadingDetailInfo = false;
							//console.log(this.modalDatas);
						}
						else {
							this.isLoadingDetailInfo = false;
						}
					}
					else {
						this.CloseDialog();
					}
				},
				error => {
					this.isLoadingDetailInfo = false;
					console.log(error);
				});
		const dialogRef = this.dialog.open(templateRef, {
			panelClass: 'modal_transaction',
		});
	}
}