import { Injectable } from "@angular/core";
import {
  HttpClient,
  HttpEventType,
  HttpHeaders,
  HttpRequest,
  HttpResponse,
  HttpParams,
} from "@angular/common/http";
import { map } from "rxjs/operators";
import { Cacheable } from "ngx-cacheable";
import { AuthenticationService } from "./authentication.service";
import { Observable, Subject } from "rxjs";

@Injectable()
export class ContactsService {
  constructor(
    private http: HttpClient,
    private authService: AuthenticationService
  ) {}

  getContactsList(FetchPage, FetchCount, model) {
    let Filter = {
      Name: model.Name,
      Email: model.Email,
      Phone: model.Phone,
      Gender: model.Gender,
    };
    return this.http
      .get<any>(
        this.authService.basUrl +
          `/api/contacts/gets?FetchPage=${FetchPage}&FetchCount=${FetchCount}&Name=${Filter.Name}&Email=${Filter.Email}&Phone=${Filter.Phone}&Sex=${Filter.Gender}`,
        {
          headers: { "Accept-Language": this.GetLang() },
        }
      )
      .pipe(
        map((response) => {
          if (this.authService.checkTokenResponse()) {
            return response;
          }
        })
      );
  }

  getContactInfo(id) {
    return this.http
      .get<any>(this.authService.basUrl + `/api/contacts/get/${id}`)
      .pipe(
        map((response) => {
          if (this.authService.checkTokenResponse()) {
            return response;
          }
        })
      );
  }

  createContact(model) {
    let Contact = {
      Name: model.Name,
      Email: model.Email,
      Address: model.Address,
      City: model.City,
      State: model.State,
      CountryKey: model.CountryKey,
      ZipCode: model.ZipCode,
      Phone: model.Phone,
      Note: model.Note,
    };

    return this.http
      .post<any>(
        this.authService.basUrl + `/api/contacts/save`,
        { Contact },
        {
          headers: {
            "Content-Type": "application/json",
            "Accept-Language": this.GetLang(),
          },
        }
      )
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  saveContact(model) {
    let Contact = {
      Name: model.Name,
      Email: model.Email,
      Address: model.Address,
      City: model.City,
      State: model.State,
      CountryKey: model.CountryKey,
      ZipCode: model.ZipCode,
      Phone: model.Phone,
      Note: model.Note,
      Key: model.Key,
    };

    return this.http
      .post<any>(
        this.authService.basUrl + `/api/contacts/save`,
        { Contact: Contact },
        {
          headers: {
            "Content-Type": "application/json",
            "Accept-Language": this.GetLang(),
          },
        }
      )
      .pipe(
        map((response) => {
          return response;
        })
      );
  }
  // createSale(product, amount, phone) {
  //   let body = {
  //     Description: product,
  //     Amount: amount,
  //     MobTelephone: phone,
  //   };
  //   return this.http
  //     .post<any>(this.authService.basUrl + `/api/sale/create`, body, {
  //       headers: {
  //         "Content-Type": "application/json",
  //         "Accept-Language": this.GetLang(),
  //       },
  //     })
  //     .pipe(
  //       map((response) => {
  //         if (this.authService.checkTokenResponse()) {
  //           return response;
  //         }
  //       })
  //     );
  // }
  deleteContact(key) {
    let body = {
      Key: key,
    };
    return this.http
      .post<any>(this.authService.basUrl + `/api/contacts/Remove`, body, {
        headers: {
          "Content-Type": "application/json",
          "Accept-Language": this.GetLang(),
        },
      })
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  takeListCountries() {
    return this.http
      .get<any>(this.authService.basUrl + `/api/contacts/countries`, {
        headers: {
          "Content-Type": "application/json",
          "Accept-Language": this.GetLang(),
        },
      })

      .pipe(
        map((response) => {
          if (this.authService.checkTokenResponse()) {
            return response;
          }
        })
      );
  }

  private GetLang() {
    switch (localStorage.getItem("currentLang")) {
      case "ru":
        return "ru-RU";
      case "tr":
        return "tr-TR";
    }
    return "en-US";
  }
}
