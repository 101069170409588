import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map, first } from 'rxjs/operators';
//import { Observable } from 'rxjs';
import { AuthenticationService } from "./authentication.service";

export interface ItemList {
    Value: string;
    Name: string;
} 

@Injectable()
export class ProviderService {
  constructor(
    private http: HttpClient,
    private authService: AuthenticationService
  ) {}
  //--------------- use only into your component
  GetAllProvidersFromStorage() {
    //: Observable<ItemList[]> {
    let prvTop = localStorage.getItem("providerAll");
    if (prvTop === null) {
      this.AllList()
        .pipe(first())
        .subscribe(
          (data) => {
            localStorage.setItem("providerAll", JSON.stringify(data));
            return data;
          },
          (error) => {
            console.log(error);
          }
        );
    } else return JSON.parse(prvTop);
  }
  //----------------- TopList of provider
  TopList() {
    console.log("top list get...");
    return this.http
      .get<any>(this.authService.basUrl + `/api/provider/GetTop?count=6`, {
        headers: { "Accept-Language": this.GetLang() },
      })
      .pipe(
        map((response) => {
          return response;
        })
      );
  }
  //----------------- get groups of provider
  GroupList() {
    console.log("-------------->> GroupList --------------");
    return this.http
      .get<any>(this.authService.basUrl + `/api/provider/getGroup/all`, {
        headers: { "Accept-Language": this.GetLang() },
      })
      .pipe(
        map((response) => {
          return response;
        })
      );
  }
  //---------------- get all providers
  AllList() {
    console.log("-------------->> AllList --------------");
    return this.http
      .get<any>(this.authService.basUrl + `/api/provider/get`, {
        headers: { "Accept-Language": this.GetLang() },
      })
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  private GetLang() {
    switch (localStorage.getItem("currentLang")) {
      case "en":
        return "en-US";
    }
    return "tr-TR";
  }
}


