import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { map, first } from "rxjs/operators";
import { AuthenticationService } from "./authentication.service";
import { Cacheable, CacheBuster } from "ngx-cacheable";
import { Subject } from "rxjs";
import { not } from "@angular/compiler/src/output/output_ast";

// const httpOptions = {
//   headers: new HttpHeaders({
//     'Content-Type':  'application/x-www-form-urlencoded;charset=UTF-8',
//     'Accept-Language': 'en-EN',
// })
// };
const cacheBuster$ = new Subject<void>();
@Injectable()
export class SettingsService {
  constructor(
    private authService: AuthenticationService,
    private http: HttpClient
  ) {}

  // _______________   Dealer page

  // @Cacheable({
  // 	cacheBusterObserver: cacheBuster$
  // })

  // @CacheBuster({
  // 	cacheBusterNotifier: cacheBuster$
  // })

  // Rating

  getAppRating() {
    let versioFromYear = "pp_version_1_0_web";
    return this.http
      .get<any>(
        this.authService.basUrl +
          `/api/Rating/Get?id=web&version=${versioFromYear}`,
        {
          headers: { "Accept-Language": this.GetLang() },
        }
      )
      .pipe(
        map((response) => {
          if (this.authService.checkTokenResponse()) {
            return response;
          }
        })
      );
  }
  saveAppRating(version, rate, msg) {
    let versioFromYear = "1.2." + new Date().getFullYear();
    let body = {
      Version: "pp_version_1_0_web",
      Type: "web",
      Value: rate,
      FeedbackMsg: msg,
      Info: "Web Version: " + versioFromYear,
    };
    return this.http
      .post<any>(this.authService.basUrl + `/api/Rating/Save`, body, {
        headers: { "Accept-Language": this.GetLang() },
      })
      .pipe(
        map((response) => {
          if (this.authService.checkTokenResponse()) {
            return response;
          }
        })
      );
  }
  // Profile
  saveImage(type, image) {
    let body = {
      MimeType: type,
      ImageData: image,
      TypeStr: "USER_AVATAR",
    };
    return this.http
      .post<any>(this.authService.basUrl + `/api/profile/Avatar`, body, {
        headers: { "Content-Type": "application/json" },
      })
      .pipe(
        map((response) => {
          if (this.authService.checkTokenResponse()) {
            return response;
          }
        })
      );
  }

  saveImageMerchant(key, type, image) {
    let body = {
      MimeType: type,
      ImageData: image,
      TypeStr: "USER_AVATAR",
      Key: key,
    };
    return this.http
      .post<any>(this.authService.basUrl + `/api/Merchant/Logo`, body, {
        headers: { "Content-Type": "application/json" },
      })
      .pipe(
        map((response) => {
          if (this.authService.checkTokenResponse()) {
            return response;
          }
        })
      );
  }

  getNotificationSettings() {
    return this.http
      .get<any>(this.authService.basUrl + `/api/Notification/Config`, {
        headers: { "Accept-Language": this.GetLang() },
      })
      .pipe(
        map((response) => {
          if (this.authService.checkTokenResponse()) {
            return response;
          }
        })
      );
  }

  getAccountSettings() {
    return this.http
      .get<any>(this.authService.basUrl + `/api/Merchant/Get`, {
        headers: { "Accept-Language": this.GetLang() },
      })
      .pipe(
        map((response) => {
          if (this.authService.checkTokenResponse()) {
            return response;
          }
        })
      );
  }

  getBankSettings() {
    return this.http
      .get<any>(this.authService.basUrl + `/api/Merchant/Get`, {
        headers: { "Accept-Language": this.GetLang() },
      })
      .pipe(
        map((response) => {
          if (this.authService.checkTokenResponse()) {
            return response;
          }
        })
      );
  }

  getCommission() {
    return this.http
      .get<any>(this.authService.basUrl + `/api/WithDraw/Config`, {
        headers: { "Accept-Language": this.GetLang() },
      })
      .pipe(
        map((response) => {
          if (this.authService.checkTokenResponse()) {
            return response;
          }
        })
      );
  }

  getProfileSettings() {
    return this.http
      .get<any>(this.authService.basUrl + `/api/Profile/Get`, {
        headers: { "Accept-Language": this.GetLang() },
      })
      .pipe(
        map((response) => {
          if (this.authService.checkTokenResponse()) {
            return response;
          }
        })
      );
  }

  @Cacheable({
    cacheBusterObserver: cacheBuster$,
  })
  getLanguages() {
    return this.http
      .get<any>(this.authService.basUrl + `/api/Profile/Languages`, {
        headers: { "Accept-Language": this.GetLang() },
      })
      .pipe(
        map((response) => {
          if (this.authService.checkTokenResponse()) {
            return response;
          }
        })
      );
  }

  changePersonalDatas(data) {
    return this.http
      .post<any>(
        this.authService.basUrl + `/api/Profile/Save`,
        {
          Email: data.Email,
          UserLanguage: data.Language,
          Name: data.Name,
          UserMobile: data.UserMobile,
          UsersJob: data.UsersJob,
        },
        { headers: { "Content-Type": "application/json" } }
      )
      .pipe(
        map((response) => {
          if (this.authService.checkTokenResponse()) {
            return response;
          }
        })
      );
  }

  saveNotificationSettings(data) {
    return this.http
      .post<any>(
        this.authService.basUrl + `/api/Notification/Config`,
        { NotificationsProfile: data },
        {
          headers: {
            "Content-Type": "application/json",
            "Accept-Language": this.GetLang(),
          },
        }
      )
      .pipe(
        map((response) => {
          if (this.authService.checkTokenResponse()) {
            return response;
          }
        })
      );
  }

  sendOTP(amount) {
    return this.http
      .post<any>(
        this.authService.basUrl + `/api/Withdraw/RequestInit?Amount=` + amount,
        { Amount: amount },
        {
          headers: {
            "Content-Type": "application/json",
            "Accept-Language": this.GetLang(),
          },
        }
      )
      .pipe(
        map((response) => {
          if (this.authService.checkTokenResponse()) {
            return response;
          }
        })
      );
  }
  confirmRequest(requestId, OTP_code) {
    return this.http
      .post<any>(
        this.authService.basUrl +
          `/api/Withdraw/RequestConfirm?RequestId=` +
          requestId +
          `&OTP=` +
          OTP_code,
        { RequestId: requestId, OTP: OTP_code },
        {
          headers: {
            "Content-Type": "application/json",
            "Accept-Language": this.GetLang(),
          },
        }
      )
      .pipe(
        map((response) => {
          if (this.authService.checkTokenResponse()) {
            return response;
          }
        })
      );
  }

  getScheduleSettings() {
    return this.http
      .get<any>(this.authService.basUrl + `/api/Withdraw/GetScheduledRequest`, {
        headers: { "Accept-Language": this.GetLang() },
      })
      .pipe(
        map((response) => {
          if (this.authService.checkTokenResponse()) {
            return response;
          }
        })
      );
  }

  saveSchedule(type, time, amount) {
    type === "true" ? (type = true) : (type = false);
    return this.http
      .post<any>(
        this.authService.basUrl + `/api/Withdraw/UpdateScheduledRequest`,
        {
          AmountLimit: Number(amount) * 100,
          ScheduledType: time,
          isEnable: type,
        },
        {
          headers: {
            "Content-Type": "application/json",
            "Accept-Language": this.GetLang(),
          },
        }
      )
      .pipe(
        map((response) => {
          if (this.authService.checkTokenResponse()) {
            return response;
          }
        })
      );
  }

  private GetLang() {
    switch (localStorage.getItem("currentLang")) {
      case "ru":
        return "ru-RU";
      case "tr":
        return "tr-TR";
    }
    return "en-US";
  }
}
