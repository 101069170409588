import { Component, OnInit, ViewChild, Input, ElementRef } from "@angular/core";
import { Router } from "@angular/router";
import {
  FormBuilder,
  FormGroup,
  FormControl,
  Validators,
  AbstractControl,
  ValidatorFn,
  FormGroupDirective,
  NgForm,
} from "@angular/forms";
import { Observable } from "rxjs";
import { map, startWith, first } from "rxjs/operators";
import { AlertService, UserService, ProviderService } from "../../../_services";
import { MatPaginator } from "@angular/material/paginator";
import { MatTableDataSource } from "@angular/material/table";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { DataSource } from "@angular/cdk/table";
import { ReportsDatasService } from "../../../_services/reportsdata.service";
import { DatePipe } from "@angular/common";
import * as SecureLS from "secure-ls";
import { TransactionService } from "../../../_services/transaction.service";
import { TranslateService } from "@ngx-translate/core";
import { saveAs } from "file-saver";
import { ToastrService } from "ngx-toastr";
import { Globals } from "../../../globals/globals";

import { ActivatedRoute } from "@angular/router";

@Component({
  templateUrl: "./order-history.component.html",
  styleUrls: ["../transactions.scss"],
  providers: [DatePipe, Globals],
})
export class OrderHistoryComponent implements OnInit {
  constructor(
    public dialog: MatDialog,
    // Make service for post api datas
    public translate: TranslateService,
    private datePipe: DatePipe,
    private transactionService: TransactionService,
    public toastr: ToastrService,
    private globals: Globals,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    public element: ElementRef
  ) {}
  public paginationItems; // for display pagination from api
  public paginationItemPerPage; // for display pagination from api
  allPageLoad: boolean = false;
  isDownloadingFile: boolean = false;
  permissions;
  modalDatas = {};
  modalTransactionsList = [];

  moneyProviderList = [{ ProviderId: 0, ProviderName: "ALL" }];

  radioModel = 1;
  public currentPage = 0;
  isLoadingDetailInfo: boolean = true; // spinner
  Info;
  loading = false; // for disable fast response click
  pageEvent; // for fix error of pageEvent in Visual Studio
  panelOpenState = true;
  // For change pages
  minDate = new Date(2000, 0, 1); // Date picker min date
  maxDate = new Date(new Date().getFullYear() + 5, 0, 1); // Date picker max date
  events: string[] = []; // Date picker
  currentTime = new Date();
  AccountCodes;
  refundError = "";
  displayedColumns: string[] = [
    "Date",
    "OrderNumber",
    "MobileNumber",
    "Description",
    "InstallmentNumber",
    "Amount",
    "Fee",
    "Status",
    "ProductType",
    "PaidDate",
    "SalePaymentType",
    "Action",
  ];
  dataSource = new MatTableDataSource(); // array of datas
  @ViewChild(MatPaginator) paginator: MatPaginator;

  FilterForm: FormGroup; // Form initial
  RefundForm: FormGroup;

  CloseDialog(_dialog = null): void {
    this.dialog.closeAll();
  }
  public totalAmounts: number = 0;
  public totalFee: number = 0;

  getFirstDatas(period) {
    this.loading = true;
    this.radioModel = period;
    let start = this.datePipe.transform(
      this.FilterForm.controls.dateFrom.value,
      "yyyy-MM-ddT00:00:00"
    );
    let finish = this.datePipe.transform(
      this.FilterForm.controls.dateTo.value,
      "yyyy-MM-ddT23:59:59"
    );
    let filter_status = null;
    if (this.FilterForm.value.Status !== "null") {
      filter_status = this.FilterForm.value.Status;
    }

    this.transactionService
      .getsOrderHistory(
        start,
        finish,
        period,
        this.FilterForm.value.Code,
        this.FilterForm.value.Phone,
        this.FilterForm.value.Status,
        this.FilterForm.value.ProductType,
        this.FilterForm.value.SalePayTypeList,

        this.paginationItemPerPage,
        this.currentPage + 1
      )
      .subscribe(
        (data) => {
          let datas = [];
          this.paginationItems = 0;
          this.totalAmounts = 0;
          this.totalFee = 0;
          if (data.ResultCode === 0) {
            if (data.ReturnObject !== null) {
              datas = data.ReturnObject.Orders;
              this.paginationItems = data.ReturnObject.Fetch.Cnt;
              this.totalAmounts = data.ReturnObject.TotalAmounts;
              this.totalFee = data.ReturnObject.TotalFee;
            }
          }
          this.dataSource = new MatTableDataSource(datas); // array of datas
          this.loading = false;
        },
        (error) => {
          this.loading = false;
          console.log(error);
        },
        () => {
          this.allPageLoad = true;
        }
      );
  }

  getMoneyProviders() {
    this.transactionService.getMoneyProviders().subscribe(
      (data) => {
        if (data.ResultCode === 0) {
          if (data.ReturnObject !== null) {
            this.moneyProviderList = data.ReturnObject;
          }
        }
        this.loading = false;
      },
      (error) => {
        this.loading = false;
        console.log(error);
      },
      () => {
        this.FilterForm.patchValue({
          ["PaymentType"]: this.moneyProviderList[0].ProviderId,
        });
        this.getFirstDatas(this.radioModel);
      }
    );
  }

  convertOnBlur(searchValue, form_group) {
    let _styledAmount = parseFloat(searchValue ? searchValue : "0").toFixed(2);

    form_group.patchValue({
      ["Amount"]: _styledAmount,
    });
    searchValue = _styledAmount;
  }
  public currentDialog;
  openConfirmRefundDialog(_dialog) {
    if (this.RefundForm.valid === true) {
      const dialogRef = this.dialog.open(_dialog);
      this.currentDialog = dialogRef;
    }
  }
  currentFactID = null;
  makeRefund() {
    let _rf = this.RefundForm.value,
      _rd = this.refundData,
      _amount = _rf.Amount ? _rf.Amount.replace(/\D/g, "") : _rd.Amount,
      _factID = this.currentFactID;
    this.transactionService
      .makeRefund(
        _rd.OrderNumber,
        _rf.RefundType,
        _factID,
        _amount,
        _rf.Comment
      )
      .subscribe(
        (data) => {
          if (data.ResultCode === 0) {
            this.CloseDialog();

            let theme = "";
            let body = "";
            this.translate
              .get("REPORT.THEME_REFUND_SUCCESS")
              .subscribe((text) => {
                theme = text;
              });
            this.translate
              .get("REPORT.BODY_REFUND_SUCCESS")
              .subscribe((text) => {
                body = text;
              });
            this.toastr.success(body, theme);
            this.RefundForm.reset();
          } else {
            let body = "";
            data.ResultMessage.length === 0
              ? (body = "")
              : (body = data.ResultMessage);
            let theme = "";
            this.translate
              .get("REPORT.THEME_REFUND_ERROR")
              .subscribe((text) => {
                theme = text;
              });
            this.toastr.error(body, theme);
          }
          this.isLoadingDetailInfo = false;
        },
        (error) => {
          this.isLoadingDetailInfo = false;
          console.log(error);
        },
        () => {
          this.isMakingRefund = false;
          this.getFirstDatas(this.radioModel);
        }
      );
  }
  closeRefundModal(_flag) {
    if (_flag) {
      this.currentDialog.close();
      this.isMakingRefund = true;
      this.makeRefund();
    } else {
      this.currentDialog.close();
    }
  }
  onInputAmount(event, maxLength) {
    if (event.target.value.length > maxLength) {
      event.target.value = event.target.value.slice(0, maxLength);
    }
  }
  onFocusAmount(event) {
    event.target.select();
  }
  refundTypeOnChange(event) {
    if (event.value === 0) {
      this.RefundForm.controls.Amount.setValidators([]);
      this.RefundForm.controls.Amount.setValue(this.refundAmountMax);
      this.RefundForm.controls.Amount.disable();
      this.RefundForm.controls.Amount.updateValueAndValidity();
    } else {
      this.RefundForm.controls.Amount.setValidators([
        Validators.required,
        Validators.min(0.001),
        Validators.max(this.refundData.Amount / 100),
      ]);
      this.RefundForm.controls.Amount.enable();
      this.RefundForm.controls.Amount.setValue((0).toFixed(2));
      this.RefundForm.controls.Amount.updateValueAndValidity();
    }
  }
  refundData;
  isMakingRefund: boolean = false;
  refundAmountMax: string = "";
  openRefundModal(templateRef, ROW) {
    this.currentFactID = ROW.FactID;
    this.isLoadingDetailInfo = false;
    this.RefundForm.controls.RefundType.setValue(this.refundTypeList[0].Value);
    this.refundAmountMax = (ROW.Amount / 100).toFixed(2);
    if (this.RefundForm.value.RefundType === 0) {
      this.RefundForm.controls.Amount.setValidators([]);
      this.RefundForm.controls.Amount.setValue(this.refundAmountMax);
      this.RefundForm.controls.Amount.disable();
      this.RefundForm.controls.Amount.updateValueAndValidity();
    } else {
      this.RefundForm.controls.Amount.setValidators([
        Validators.required,
        Validators.min(0.001),
        Validators.max(this.refundData.Amount / 100),
      ]);
      this.RefundForm.controls.Amount.setValue((0).toFixed(2));
      this.RefundForm.controls.Amount.enable();
      this.RefundForm.controls.Amount.updateValueAndValidity();
    }
    this.RefundForm.controls.Amount.setValidators([
      Validators.required,
      Validators.min(0.001),
      Validators.max(ROW.Amount / 100),
    ]);

    this.RefundForm.controls.Amount.updateValueAndValidity();
    this.dialog.open(templateRef, {
      panelClass: "payments_table_modal",
    });

    this.refundData = ROW;
  }
  openDetailsModal(templateRef, number) {
    this.refundError = "";
    this.isLoadingDetailInfo = true;
    this.modalDatas = new Object();

    this.dialog.open(templateRef, {
      panelClass: "payments_table_modal",
    });
    this.transactionService.getOrderDetails(number).subscribe(
      (data) => {
        console.log(data);
        if (data.ResultCode === 0) {
          if (data.ReturnObject !== null) {
            this.modalDatas = data.ReturnObject;
            this.isLoadingDetailInfo = false;
            //console.log(this.modalDatas);
          } else {
            this.isLoadingDetailInfo = false;
          }
        } else {
          // If pKey is wrong or not datas for this array, make Alert and close Modal
          alert(data.ResultMessage);
          this.CloseDialog();
        }
      },
      (error) => {
        this.isLoadingDetailInfo = false;
        console.log(error);
      }
    );
  }

  download(format) {
    if (this.loading === false && this.isDownloadingFile === false) {
      this.isDownloadingFile = true;
      let start = this.datePipe.transform(
        this.FilterForm.controls.dateFrom.value,
        "yyyy-MM-ddT00:00:00"
      );
      let finish = this.datePipe.transform(
        this.FilterForm.controls.dateTo.value,
        "yyyy-MM-ddT23:59:59"
      );

      this.isDownloadingFile = true;
      this.transactionService
        .getsOrderHistoryFile(
          start,
          finish,
          this.radioModel,
          this.FilterForm.value.Code,
          this.FilterForm.value.Phone,
          this.FilterForm.value.Status,
          this.FilterForm.value.ProductType,
          this.FilterForm.value.SalePayTypeList,
          format
        )
        .subscribe(
          (res) => {
            this.isDownloadingFile = false;
            saveAs(
              res,
              "OrderHistory_" +
                this.datePipe.transform(
                  this.FilterForm.controls.dateTo.value,
                  "yyyy-MM-dd"
                ) +
                "." +
                format,
              {
                type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
              }
            );
          },
          (error) => {
            console.log(error);
            this.isDownloadingFile = false;
          }
        );
    }
  }

  statusLists = [
    {
      text: "All",
      value: "null",
      color: "",
    },
    // {
    //   text: "New",
    //   value: 0,
    //   color: "badge-success",
    // },
    {
      text: "Paid",
      value: 1,
      color: "badge-success",
    },
    {
      text: "Send",
      value: 2,
      color: "badge-warning",
    },
    {
      text: "Refund",
      value: 3,
      color: "badge-primary",
    },
    {
      text: "Cancel",
      value: 4,
      color: "badge-info",
    },
    {
      text: "Refund",
      value: 5,
      color: "badge-info",
    },
    {
      text: "PartialRefund",
      value: 6,
      color: "badge-info",
    },
  ];

  whatBadge(id) {
    for (let i = 0; i < this.statusLists.length; i++) {
      if (this.statusLists[i].value == id) {
        return this.statusLists[i].color;
      }
    }
  }
  whatProduct(id) {
    for (let i = 0; i < this.productTypeList.length; i++) {
      if (this.productTypeList[i].value == id) {
        return this.productTypeList[i].color;
      }
    }
  }
  productTypeList = [
    {
      text: "All",
      value: "null",
      color: "",
    },
    {
      text: "Sale",
      value: 0,
      color: "badge-primary",
    },
    {
      text: "Invoice",
      value: 1,
      color: "badge-success",
    },
  ];
  refundList = [
    {
      text: "Full",
      value: 0,
      color: "badge-info",
    },
    //{
    //    text: 'Partial',
    //    value: 1,
    //    color: 'badge-warning'
    //},
  ];
  salePayTypeList = [
       {
          text: "All",
           value: "null", 
         },
         {
          text: "Sale",
           value: 1, 
         },
         {
           text: "Installment",
           value: 2, 
         },
         {
           text: "Account",
           value: 3, 
         }];
  //----------------------
  PaginationCurentPageReset(e: any) {
    this.currentPage = 0;
  }

  //------------------- private helpers
  // convenience getter for easy access to form fields
  get f() {
    return this.FilterForm.controls;
  }

  public handlePage(e: any) {
    this.currentPage = e.pageIndex;
    this.paginationItemPerPage = e.pageSize;
    this.getFirstDatas(this.radioModel);
  }

  ngOnInit() {
    this.paginationItemPerPage = 10;

    this.translate.get("REPORT.ORDER_STS_ALL").subscribe((text) => {
      this.statusLists[0].text = text;
    });
    // this.translate.get("REPORT.STATUS_STS_NEW").subscribe((text) => {
    //   this.statusLists[0].text = text;
    // });
    this.translate.get("REPORT.STATUS_STS_PAID").subscribe((text) => {
      this.statusLists[1].text = text;
    });
    this.translate.get("REPORT.STATUS_STS_SEND").subscribe((text) => {
      this.statusLists[2].text = text;
    });
    this.translate.get("REPORT.STATUS_STS_EXPIRED").subscribe((text) => {
      this.statusLists[3].text = text;
    });
    this.translate.get("REPORT.STATUS_STS_CANCEL").subscribe((text) => {
      this.statusLists[4].text = text;
    });
    this.translate.get("REPORT.STATUS_STS_FULLREFUND").subscribe((text) => {
      this.statusLists[5].text = text;
    });
    this.translate.get("REPORT.STATUS_STS_PARTREFUND").subscribe((text) => {
      this.statusLists[6].text = text;
    });
    this.translate.get("REPORT.REFUND_FULL_TEXT").subscribe((text) => {
      this.refundList[0].text = text;
    });
    this.translate.get("REPORT.ORDER_TYPE_ALL").subscribe((text) => {
      this.productTypeList[0].text = text;
    });
    this.translate.get("REPORT.ORDER_TYPE_0").subscribe((text) => {
      this.productTypeList[1].text = text;
    });
    this.translate.get("REPORT.ORDER_TYPE_1").subscribe((text) => {
      this.productTypeList[2].text = text;
    });
    this.translate.get("REPORT.ORDER_REFUND_FULL").subscribe((text) => {
      this.refundTypeList[0].Name = text;
    });
    this.translate.get("REPORT.ORDER_REFUND_PART").subscribe((text) => {
      this.refundTypeList[1].Name = text;
    });
    this.translate.get("REPORT.SALE_PAY_TYPE_0").subscribe((text) => {
           this.salePayTypeList[0].text = text;
          });
          this.translate.get("REPORT.SALE_PAY_TYPE_1").subscribe((text) => {
            this.salePayTypeList[1].text = text;
          });
          this.translate.get("REPORT.SALE_PAY_TYPE_2").subscribe((text) => {
             this.salePayTypeList[2].text = text;
           });
           this.translate.get("REPORT.SALE_PAY_TYPE_3").subscribe((text) => {
             this.salePayTypeList[3].text = text;
           });
          
    this.FilterForm = new FormGroup({
      dateFrom: new FormControl(
        { value: this.currentTime, disabled: true },
        Validators.required
      ),
      dateTo: new FormControl(
        { value: this.currentTime, disabled: true },
        Validators.required
      ),
      Status: new FormControl(this.statusLists[0].value, Validators.required),
      Code: new FormControl(""),
      Phone: new FormControl(""),
      SalePayTypeList: new FormControl(this.salePayTypeList[0].value, Validators.required),
      PaymentType: new FormControl("", Validators.required),
      ProductType: new FormControl(this.productTypeList[0].value),
    }); // for header Filter

    this.RefundForm = new FormGroup({
      // public RefundType RefundType { get; set; }
      //         public long OperationId { get; set; }
      //         public long Amount { get; set; }
      //         public string Comment { get; set; }
      // Full = 0,
      // Partial = 1
      RefundType: new FormControl(
        this.refundTypeList[0].Value,
        Validators.required
      ),
      Amount: new FormControl("", Validators.required),
      Comment: new FormControl("", Validators.required),
    });
    this.getFirstDatas(this.radioModel);
  }
  refundTypeList = [
    { Name: "Full", Value: 0 },
    { Name: "Partial", Value: 1 },
  ];
}
