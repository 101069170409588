import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, FormControl, Validators, AbstractControl, ValidatorFn, FormGroupDirective, NgForm } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith, first } from 'rxjs/operators';
import { AlertService, UserService, ProviderService } from '../../_services';
//import { MatPaginator, MatTableDataSource, MatDatepickerInputEvent, MatDialog, DateAdapter, ErrorStateMatcher } from '@angular/material';
import { DataSource } from '@angular/cdk/table';
import { DatePipe } from '@angular/common';
/** Constants used to fill up our data base. */
import * as SecureLS from 'secure-ls';
import { SettingsService } from '../../_services/settings.service';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';

@Component({
    templateUrl: 'withdraw-settings.component.html',
    styleUrls: ['withdraw.scss'],
    providers: [DatePipe]
})



export class WithdrawSettingsComponent implements OnInit {
    private markFormGroupTouched(formGroup: FormGroup) {
        (<any>Object).values(formGroup.controls).forEach(control => {
            control.markAsTouched();

            if (control.controls) {
                this.markFormGroupTouched(control);
            }
        });
    }
    constructor(
        public translate: TranslateService,
        private formBuilder: FormBuilder,
        private settingsService: SettingsService,
        public toastr: ToastrService
    ) {

    }

    requestId = 0;
    loading: boolean = false;
    Info;
    schedule_error = '';
    loading_page: boolean = false;
    Schedule: FormGroup;

    convertOnBlur(searchValue) {
        if (searchValue > 0) {
            this.Schedule.patchValue({ ['Amount']: parseFloat(searchValue).toFixed(2) });
            searchValue = parseFloat(searchValue).toFixed(2);
        }
    }
    onTypeChange(searchValue) {
        if ((searchValue * 100) % (0.1 * 100)) {
            this.Schedule.patchValue({ ['Amount']: Math.round(searchValue) });
            searchValue = Math.round(searchValue);
        }
    }

    changeTime() {
        if (this.Schedule.value.Type === "true") {
            this.Schedule.removeControl("Amount");
            if (this.Schedule.value.Time === 3) {
                this.Schedule.addControl("Amount", new FormControl('', [Validators.required, Validators.min(1)]));
            }
            else {
                this.Schedule.addControl("Amount", new FormControl(''));
            }
        }
    }

    getProfileSettings() {
        this.settingsService.getScheduleSettings().pipe(first())
            .subscribe(
                data => {
                    console.log(data);
                    if (data.ResultCode === 0) {
                        this.Schedule.removeControl("Amount");
                        this.Schedule.removeControl("Time");

                        this.Schedule.patchValue({ ['Type']: String(data.ReturnObject.IsEnable) });
                        if (data.ReturnObject.IsEnable === true) {
                            this.Schedule.addControl("Time", new FormControl(this.scheduleList[data.ReturnObject.ScheduledType].value, Validators.required));
                        }
                        else {
                            this.Schedule.addControl("Time", new FormControl(this.scheduleList[0].value));
                        }
                        if (data.ReturnObject.ScheduledType === 3) {
                            this.Schedule.addControl("Amount", new FormControl((Number(data.ReturnObject.AmountLimit) / 100).toFixed(2), [Validators.required, Validators.min(1)]));
                        }
                        else {
                            this.Schedule.addControl("Amount", new FormControl(''));
                        }
                    }
                    else {
                        this.Schedule.patchValue({ ['Type']: String(false) });
                        this.Schedule.patchValue({ ['Time']: this.scheduleList[0].value });
                    }
                    this.loading_page = true;
                },
                error => {
                    console.log(error);
                    this.loading_page = true;
                });
    }

    sendSchedule() {
        this.schedule_error = '';
        if (this.Schedule.valid === true || this.Schedule.value.Type === "false") {
            console.log(this.Schedule.value);
            this.loading = true;
            this.settingsService.saveSchedule(this.Schedule.value.Type, this.Schedule.value.Time,  Math.round(Number(this.Schedule.value.Amount)*100))
                .pipe(first())
                .subscribe(
                    data => {
                        console.log(data);
                        if (data.ResultCode === 0) {
                            let theme = '';
                            let body = '';
                            this.translate.get('REPORT.THEME_SUCCESS_SCHEDULE').subscribe((text) => { theme = text; });
                            this.translate.get('REPORT.BODY_SUCCESS_SCHEDULE').subscribe((text) => { body = text; });
                            this.toastr.success(body, theme);
                        }
                        else {
                            let theme = '';
                            data.ResultMessage === null ? this.schedule_error = '' : this.schedule_error = data.ResultMessage;
                            this.translate.get('REPORT.THEME_ERROR_SCHEDULE').subscribe((text) => { theme = text; });
                            this.toastr.error(this.schedule_error, theme);
                        }
                        this.loading = false;
                    },
                    error => {
                        this.loading = false;
                        console.log(error);
                    });
        }

    }

    scheduleList = [
        {
            text: 'EveryWeekEnd',
            value: 0
        },
        {
            text: 'EveryMonthEnd',
            value: 1
        },
        {
            text: 'EveryYearEnd',
            value: 2
        },
        {
            text: 'Every Amount Limit Higher',
            value: 3
        }
    ];

    ngOnInit() {

        this.Schedule = this.formBuilder.group({
            'Type': ['', Validators.required],
            'Time': [''],
            'Amount': [''],
        });

        this.translate.get('REPORT.SCHEDULE_TYPE_WEEK').subscribe((text) => { this.scheduleList[0].text = text; });
        this.translate.get('REPORT.SCHEDULE_TYPE_MONTH').subscribe((text) => { this.scheduleList[1].text = text; });
        this.translate.get('REPORT.SCHEDULE_TYPE_YEAR').subscribe((text) => { this.scheduleList[2].text = text; });
        this.translate.get('REPORT.SCHEDULE_TYPE_AMOUNTLIMIT').subscribe((text) => { this.scheduleList[3].text = text; });


        let ls = new SecureLS(); // for Secure Local storage
        if (ls.get('info') !== null) {
            this.Info = ls.get('info');
        }
        this.getProfileSettings();
    }
}
