import { DatePipe } from "@angular/common";
import { Pipe, PipeTransform } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { Globals } from "../globals/globals";

@Pipe({
  name: "localizedDate",
  pure: false,
})
export class LocalizedDatePipe implements PipeTransform {
  constructor(
    private translateService: TranslateService,
    private globals: Globals
  ) {}

  transform(value: any, pattern: string = this.globals.dateType): any {
    const datePipe: DatePipe = new DatePipe(this.translateService.currentLang);
    return datePipe.transform(value, pattern);
  }
}
