import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-invoice-refunds',
  templateUrl: './invoice-refunds.component.html',
  styleUrls: ['./invoice-refunds.component.scss']
})
export class InvoiceRefunds implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
