import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { first } from "rxjs/operators";
import { saveAs } from "file-saver";
import { DatePipe } from "@angular/common";
import { TranslateService, LangChangeEvent } from "@ngx-translate/core";
import { InvoicingService } from "../../_services/invoicing.service";
import { untilDestroyed } from "ngx-take-until-destroy";
import { MatTableDataSource } from "@angular/material/table";
import { MatPaginator } from "@angular/material/paginator";
import { MatDialog } from "@angular/material/dialog";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { Globals } from "../../globals/globals";
import { Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";

@Component({
  selector: "app-invoice-history",
  templateUrl: "./invoice-history.component.html",
  styleUrls: ["../base/base.scss", "invoice.scss"],
  providers: [DatePipe, Globals],
})
export class InvoiceHistory implements OnInit, OnDestroy {
  mainChartLoad: boolean = false;
  isDownloadingFile: boolean = false;
  loader: boolean = false; // Display dashboard when loading datas
  Status: 0;
  FilterForm: FormGroup; // Form initial
  FilterFormTable: FormGroup;
  pageEvent; // for fix error of pageEvent in Visual Studio

  displayedColumns: string[] = [
    "TargetInvoice",
    "Created",
    "Expires",
    "FullName",
    "Amount",
    "Fee",
    "PayDate",
    "Status",
    "Actions",
  ];

  dataSource = new MatTableDataSource(); // array of datas
  @ViewChild(MatPaginator) paginator: MatPaginator;

  currentTime = new Date();

  minDate = new Date(2000, 0, 1); // Date picker min date
  maxDate = new Date(new Date().getFullYear() + 5, 0, 1);
  totalItems;
  loading: boolean = false;

  // Date picker max date
  constructor(
    private invoicingService: InvoicingService,
    public dialog: MatDialog,
    public translate: TranslateService,
    private datePipe: DatePipe,
    private globals: Globals,
    private router: Router,
    public toastr: ToastrService
  ) {
    translate.onLangChange
      .pipe(untilDestroyed(this))
      .subscribe((event: LangChangeEvent) => {});
  }

  radioModel = 1; // standart radio value for month
  // mainChart
  public itemsPerPage = 10; // for display pagination from api
  public currentPage = 0;
  public currentItem;
  public mainChartElements: Number;
  public linkToCopy: string = "";
  public handlePage(e: any) {
    this.currentPage = e.pageIndex;
    this.itemsPerPage = e.pageSize;
    this.getDatasTable(this.radioModel);
  }
  openConfirmationDialog(dialogName, row) {
    this.currentItem = row;
    this.openModal(dialogName);
  }
  openModal(templateRef) {
    this.dialog.open(templateRef);
  }

  rejectInvoice() {
    this.modalLoading = true;

    this.invoicingService
      .rejectInvoice(this.currentItem.Key)
      .subscribe((data) => {
        if (data.ResultCode === 0) {
          this.loading = false;
          this.CloseDialog();
          let theme = "";
          let body = "";
          this.translate
            .get("INVOICE.THEME_REJECT_SUCCESS")
            .subscribe((text) => {
              theme = text;
            });
          this.translate
            .get("INVOICE.BODY_REJECT_SUCCESS")
            .subscribe((text) => {
              body = text;
            });
          this.toastr.success(body, theme);
          this.getDatasTable(this.radioModel);
        } else {
          let body = "";
          data.ResultMessage.length === 0
            ? (body = "")
            : (body = data.ResultMessage);
          let theme = "";
          this.translate
            .get("INVOICE.THEME_INVOICE_REJECT_ERROR")
            .subscribe((text) => {
              theme = text;
            });
          this.toastr.error(body, theme);
        }
        this.modalLoading = false;
      });
  }
  CloseDialog(): void {
    this.dialog.closeAll();
  }

  viewInvoice(invoiceKey) {
    let path;
    this.invoicingService.getCreateInvoice(invoiceKey).subscribe(
      (res) => {
        path = `/invoice-create/${invoiceKey}`;
        this.router.navigate([path]);
      },
      (error) => {
        console.log(error);
      }
    );
  }

  copyInvoicePath(modal, invoiceKey) {
    this.invoicingService.getCopyPathInvoicing(invoiceKey).subscribe(
      (res) => {
        this.linkToCopy = res.ReturnObject;
        this.openModal(modal);
      },
      (error) => {
        console.log(error);
      }
    );
  }
  openLink(link) {
    window.open(link);
  }
  copyToClipboard(text) {
    let dummy = document.createElement("input");
    dummy.style.position = "fixed";
    dummy.style.left = "0";
    dummy.style.top = "0";
    dummy.style.opacity = "0";
    dummy.value = text;

    document.body.appendChild(dummy);
    dummy.focus();
    dummy.select();
    // document.execCommand("copy");
    try {
      let successful = document.execCommand("copy");
      let msg = successful ? "successful" : "unsuccessful";
      let theme, body;
      if (successful) {
        this.translate.get("INVOICE.THEME_COPY_SUCCESS").subscribe((text) => {
          theme = text;
        });
        this.translate.get("INVOICE.BODY_COPY_SUCCESS").subscribe((text) => {
          body = text;
        });
        this.toastr.success(body, theme);
      } else {
        this.translate.get("INVOICE.THEME_COPY_FAIL").subscribe((text) => {
          theme = text;
        });
        this.translate.get("INVOICE.BODY_COPY_FAIL").subscribe((text) => {
          body = text;
        });
        this.toastr.error(body, theme);
      }
    } catch (err) {}
    document.body.removeChild(dummy);
  }
  openCopyModal(text) {
    this.openModal("copyModal");
  }

  duplicateInvoice(invoiceKey) {
    let path,
      copyParam = "";
    this.invoicingService.getCreateInvoice(invoiceKey).subscribe(
      (res) => {
        localStorage.setItem("dublicateFlag", "true");
        path = `/invoice-create/${invoiceKey}/${copyParam}`;
        this.router.navigate([path]);
        console.log("Duplicate Res:", res);
      },
      (error) => {
        console.log(error);
      }
    );
  }
  modalLoading: boolean = false;
  sendInvoice() {
    this.modalLoading = true;
    this.invoicingService
      .sendInvoice(this.currentItem.Key)
      .subscribe((data) => {
        if (data.ResultCode === 0) {
          this.loading = false;
          this.CloseDialog();
          let theme = "";
          let body = "";
          this.translate.get("INVOICE.THEME_SEND_SUCCESS").subscribe((text) => {
            theme = text;
          });
          this.translate.get("INVOICE.BODY_SEND_SUCCESS").subscribe((text) => {
            body = text;
          });
          this.toastr.success(body, theme);
        } else {
          let body = "";
          data.ResultMessage.length === 0
            ? (body = "")
            : (body = data.ResultMessage);
          let theme = "";
          this.translate.get("INVOICE.THEME_SEND_ERROR").subscribe((text) => {
            theme = text;
          });
          this.toastr.error(body, theme);
        }
        this.modalLoading = false;
      });
  }
  download(format) {
    this.isDownloadingFile = true;

    let start = this.datePipe.transform(
      this.FilterForm.controls.dateFrom.value,
      "yyyy-MM-ddT00:00:00"
    );
    let finish = this.datePipe.transform(
      this.FilterForm.controls.dateTo.value,
      "yyyy-MM-ddTHH:mm:ss"
    );
    let file_name = "InvoiceHistory_" + finish;;
    this.invoicingService
      .getFile(start, finish, this.radioModel, format)
      .subscribe(
        (res) => {
          this.isDownloadingFile = false;
          saveAs(res, file_name + "." + format, {
            type:
              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });
        },
        (error) => {
          console.log(error);
          this.isDownloadingFile = false;
        }
      );
  }

  getDatasTable(interval) {
    this.loading = true;
    this.radioModel = interval;
    let start = this.datePipe.transform(
      this.FilterForm.controls.dateFrom.value,
      "yyyy-MM-ddT00:00:00"
    );
    let finish = this.datePipe.transform(
      this.FilterForm.controls.dateTo.value,
      "yyyy-MM-ddT23:59:59"
    );
    let filter_status = null;
    if (this.FilterForm.value.Status !== "null") {
      filter_status = this.FilterForm.value.Status;
    }

    this.invoicingService
      .getInvoiceHistory(
        interval,
        start,
        finish,
        filter_status,
        this.FilterForm.value.InvoiceEmail,
        this.FilterForm.value.InvoiceNumber,
        this.itemsPerPage,
        this.currentPage + 1
      )
      .pipe(first())
      .subscribe(
        (data) => {
          this.totalItems = 0;
          if (data.ResultCode === 0) {
            if (data.ReturnObject !== null) {
              let items = data.ReturnObject.Invoices;
              this.dataSource = new MatTableDataSource(items); // array of datas
              this.totalItems = data.ReturnObject.Fetch.Cnt;
            }
          } else {
            this.totalItems = 0;
            this.dataSource = new MatTableDataSource([]); // array of datas
          }
          this.loading = false;
        },
        (error) => {
          this.loading = false;
          console.log(error);
        },
        () => {
          this.loader = true;
        }
      );
  }

  ngOnInit(): void {
    this.FilterForm = new FormGroup({
      dateFrom: new FormControl(
        { value: this.currentTime, disabled: true },
        Validators.required
      ),
      dateTo: new FormControl(
        { value: this.currentTime, disabled: true },
        Validators.required
      ),
      InvoiceNumber: new FormControl(""),
      InvoiceEmail: new FormControl(""),
      Status: new FormControl(this.statusLists[0].value, Validators.required),
    });

    this.FilterFormTable = new FormGroup({
      dateFrom: new FormControl(
        { value: this.currentTime, disabled: true },
        Validators.required
      ),
      dateTo: new FormControl(
        { value: this.currentTime, disabled: true },
        Validators.required
      ),
    });
    this.getDatasTable(this.radioModel);

    this.translate.get("INVOICE.INV_STATUS_ALL").subscribe((text) => {
      this.statusLists[0].text = text;
    });
    this.translate.get("INVOICE.STATUS_PENDING").subscribe((text) => {
      this.statusLists[1].text = text;
    });
    this.translate.get("INVOICE.STATUS_PAID").subscribe((text) => {
      this.statusLists[2].text = text;
    });
    this.translate.get("INVOICE.STATUS_CANCEL").subscribe((text) => {
      this.statusLists[3].text = text;
    });
    this.translate.get("INVOICE.STATUS_EXPIRED").subscribe((text) => {
      this.statusLists[4].text = text;
    });
  }
  ngOnDestroy(): void {}
  statusLists = [
    {
      text: "All",
      value: "",
    },

    {
      text: "Pending",
      value: 1,
    },
    {
      text: "Paid",
      value: 2,
    },
    {
      text: "Cancel",
      value: 3,
    },
    {
      text: "Expired",
      value: 4,
    },
  ];
}
