import { Component, OnInit, ViewChild } from "@angular/core";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { Observable } from "rxjs";
import { map, startWith, first } from "rxjs/operators";
import { MatPaginator } from "@angular/material/paginator";
import { MatDialog } from "@angular/material/dialog";
import { MatTableDataSource } from "@angular/material/table";
import { ReportsDatasService } from "../../../_services/reportsdata.service";
import { DatePipe } from "@angular/common";
import { TransactionService } from "../../../_services/transaction.service";
import { TranslateService } from "@ngx-translate/core";
/** Constants used to fill up our data base. */
import { saveAs } from "file-saver";
import { Globals } from "../../../globals/globals";
import * as SecureLS from "secure-ls";

import { ActivatedRoute } from "@angular/router";

export interface ItemList {
  Value: string;
  Name: string;
}

@Component({
  templateUrl: "./sales-history.component.html",
  styleUrls: ["./sales-history.component.scss", "../../withdraw/withdraw.scss"],
  providers: [DatePipe, Globals],
})
export class SalesHistoryComponent implements OnInit {
  public totalItems; // for display pagination from api
  public itemsPerPage = 10; // for display pagination from api
  allPageLoad: boolean = false;
  public currentPage = 0;
  isLoadingDetailInfo: boolean = true; // spinner
  isDownloadingFile: boolean = false; // spinner
  permissions;
  all_permissions;

  radioModel = 1;
  //-------------------------------------
  dealerListOptions: ItemList[]; // dealer List
  loading = false; // for disable fast response click
  pageEvent; // for fix error of pageEvent in Visual Studio
  // For change pages
  modalDatas = {
    Confirmed: "",
    Processes: "",
    BankDetails: {
      IBAN: "",
      TVA: "",
      AccountNumber: "",
      AccountName: "",
      BankCode: "",
      BankBranch: "",
      CountryName: null,
      Address: null,
    },
    Amount: 0,
    Fee: 0,
    CurrencyCode: "",
    Registered: "",
    Status: 0,
    MerchantId: 0,
  }; // for display datas in modal

  minDate = new Date(2000, 0, 1); // Date picker min date
  maxDate = new Date(new Date().getFullYear() + 5, 0, 1); // Date picker max date
  events: string[] = []; // Date picker
  dealerFControl = new FormControl(); // Dealer

  dealerFilteredOptions: Observable<ItemList[]>; // dealer

  currentTime = new Date();
  //----------------- list of columns
  displayedColumns: string[] = [
    "DateReg",
    "Code",
    "UserName",
    "Amount",
    "Description",
    "MobTelephone",
    "Status",
    "DatePaid",
    "DateExpired",
  ];

  dataSource = new MatTableDataSource(); // array of datas
  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(
    public dialog: MatDialog,
    // Make service for post api datas
    private datePipe: DatePipe,
    private reportsDataService: ReportsDatasService,
    public translate: TranslateService,
    private transactionService: TransactionService,
    private globals: Globals,
    private route: ActivatedRoute
  ) {}

  FilterForm: FormGroup; // Form initial

  makePDF() {
    if (this.loading === false && this.isDownloadingFile === false) {
      let start = this.datePipe.transform(
        this.FilterForm.controls.dateFrom.value,
        "yyyy-MM-ddT00:00:00"
      );
      let finish = this.datePipe.transform(
        this.FilterForm.controls.dateTo.value,
        "yyyy-MM-ddT23:59:59"
      );
      this.isDownloadingFile = true;
      this.reportsDataService
        .getSalesPdf(
          start,
          finish,
          this.radioModel,
          this.FilterForm.value.Code,
          this.FilterForm.value.Status,
          this.FilterForm.value.OperationType
        )
        .subscribe(
          (res) => {
            this.isDownloadingFile = false;
            saveAs(res, "Sales_History_" + this.datePipe.transform(
        this.FilterForm.controls.dateTo.value,
        "yyyy-MM-dd"
      ) + "." + "pdf", {
              type: "application/pdf",
            });
          },
          (error) => {
            console.log(error);
            this.isDownloadingFile = false;
          }
        );
    }
  }

  CloseDialog(): void {
    this.dialog.closeAll();
  }

  detailWithdraw(templateRef, Id) {
    this.isLoadingDetailInfo = true;
    this.dialog.open(templateRef, {
      panelClass: "modal_transaction",
    });
    this.transactionService
      .getAdvanceWithdraw(Id)
      .pipe(first())
      .subscribe(
        (data) => {
          console.log(data);
          if (data.ResultCode === 0) {
            this.modalDatas = data.ReturnObject;
          } else {
            this.CloseDialog();
          }
          this.isLoadingDetailInfo = false;
        },
        (error) => {
          this.isLoadingDetailInfo = false;
          console.log(error);
        }
      );
  }

  getFirstDatas(period) {
    this.loading = true;
    this.radioModel = period;
    let start = this.datePipe.transform(
      this.FilterForm.controls.dateFrom.value,
      "yyyy-MM-ddT00:00:00"
    );
    let finish = this.datePipe.transform(
      this.FilterForm.controls.dateTo.value,
      "yyyy-MM-ddT23:59:59"
    );
    // Take data from form

    this.reportsDataService
      .salesGets(
        start,
        finish,
        period,
        this.FilterForm.value.Code,
        this.FilterForm.value.Status,
        this.FilterForm.value.OperationType,
        this.currentPage + 1,
        this.itemsPerPage
      )
      .subscribe(
        (data) => {
          console.log(data);
          this.totalItems = 0;
          let datas = [];
          if (data.ResultCode === 0) {
            if (data.ReturnObject !== null) {
              datas = data.ReturnObject.Sales;
              this.totalItems = data.ReturnObject.Fetch.Cnt;
            }
          }
          this.dataSource = new MatTableDataSource(datas); // array of data
          this.loading = false;
        },
        (error) => {
          this.loading = false;
          console.log(error);
        },
        () => {
          this.allPageLoad = true;
        }
      );
  }
  whatBadge(id) {
    for (let i = 0; i < this.statusLists.length; i++) {
      if (this.statusLists[i].value == id) {
        return this.statusLists[i].color;
      }
    }
  }
  statusLists = [
    {
      text: "New",
      color: "badge-warning",
      value: 1,
    },
    {
      text: "Error",
      color: "badge-danger",
      value: 2,
    },
    {
      text: "Success",
      color: "badge-success",
      value: 3,
    },
    {
      text: "Cancel",
      color: "badge-secondary",
      value: 4,
    },
  ];
  typeList = [
    {
      text: "Simple",
      value: 0,
    },
    {
      text: "Statis",
      value: 1,
    },
    {
      text: "Dynamic",
      value: 2,
    },
  ];
  ngOnInit() {
    let ls = new SecureLS();

    this.permissions = ls.get("permissions")[
      this.route.data["value"].pathCheck
    ];
    this.all_permissions = ls.get("permissions");

    this.FilterForm = new FormGroup({
      dateFrom: new FormControl(
        { value: this.currentTime, disabled: true },
        Validators.required
      ),
      dateTo: new FormControl(
        { value: this.currentTime, disabled: true },
        Validators.required
      ),
      Code: new FormControl(""),
      Status: new FormControl(""),
      OperationType: new FormControl(""),
    });
    this.translate.get("SALES.ST_SIMPLE_NEW").subscribe((text) => {
      this.statusLists[0].text = text;
    });
    this.translate.get("SALES.ST_SIMPLE_ERROR").subscribe((text) => {
      this.statusLists[1].text = text;
    });
    this.translate.get("SALES.ST_SIMPLE_SUCCESS").subscribe((text) => {
      this.statusLists[2].text = text;
    });
    this.translate.get("SALES.ST_SIMPLE_CANCEL").subscribe((text) => {
      this.statusLists[3].text = text;
    });
    this.translate.get("SALES.ST_ALL_TEXT").subscribe((text) => {
      this.statusLists.unshift({
        text: text,
        color: "badge-secondary",
        value: null,
      });
    });
    this.translate.get("SALES.TT_SIMPLE_TEXT").subscribe((text) => {
      this.typeList[0].text = text;
    });
    this.translate.get("SALES.TT_STATIC_TEXT").subscribe((text) => {
      this.typeList[1].text = text;
    });
    this.translate.get("SALES.TT_DYNAMIC_TEXT").subscribe((text) => {
      this.typeList[2].text = text;
    });

    this.translate.get("SALES.TT_ALL_TEXT").subscribe((text) => {
      this.typeList.unshift({
        text: text,
        value: null,
      });
    });
    this.getFirstDatas(this.radioModel);
  }

  //----------------------
  public handlePage(e: any) {
    this.currentPage = e.pageIndex;
    this.itemsPerPage = e.pageSize;
    this.getFirstDatas(this.radioModel);
  }

  // function for change tables page
}
