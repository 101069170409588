import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import {
  FormBuilder,
  FormGroup,
  FormControl,
  Validators,
} from "@angular/forms";
import { first } from "rxjs/operators";

import { AlertService, AuthenticationService } from "../../_services";
import { $ } from "protractor";
import { TranslateService, LangChangeEvent } from "@ngx-translate/core";
import { environment } from "../../../environments/environment";

@Component({
  selector: "app-dashboard",
  templateUrl: "recovery.component.html",
  styleUrls: ["login.scss"],
})
export class RecoveryComponent implements OnInit {
  public recaptchaSiteKey: string = environment.recaptchaSiteKey;
  recoveryForm: FormGroup;
  loading = false;
  submitted = false;
  step1 = true;
  step2 = false;
  txt_title: string;
  returnUrl: string;
  error_current = "";
  current_language;
  error_redirect = "";
  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticationService,
    private alertService: AlertService,
    public translate: TranslateService
  ) {
    console.log("Log init ...");
    console.log(
      "browser lang is " +
        translate.getBrowserLang() +
        " --> " +
        translate.getBrowserCultureLang()
    );

    translate.addLangs(["en", "tr", "ru"]);
    // translate.setDefaultLang('en');
    console.log("l store", localStorage.getItem("currentLang"));
    if (localStorage.getItem("currentLang")) {
      console.log("Cookie");
      this.current_language = localStorage.getItem("currentLang");
    } else {
      this.current_language = translate.getBrowserLang();
    }
    const browserLang = this.current_language; // This variable change lang
    translate.use(browserLang.match(/en|tr|ru/) ? browserLang : "tr");

    translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.current_language = event.lang;
      localStorage.setItem("currentLang", this.current_language);
    });
    this.route.queryParams.subscribe((params) => {
      if (typeof params["error"] !== "undefined") {
        this.error_redirect = params["error"];
      }
    });
  }
  currentCResponse: string = "";
  isCaptchaChecked: boolean = false;
  captchaResolved(event): void {
    this.currentCResponse = event;
    event ? (this.isCaptchaChecked = true) : (this.isCaptchaChecked = false);
  }
  // onScriptLoad() {
  //     console.log('Google reCAPTCHA loaded and is ready for use!')
  // }

  // onScriptError() {
  //     console.log('Something went long when loading the Google reCAPTCHA')
  // }

  ngOnInit() {
    // Reset login status
    this.authenticationService.logout(); // Reset Logout

    if (this.error_redirect.length && this.error_redirect === "401") {
      this.translate.get("HOME").subscribe((res) => {
        setTimeout(function () {
          alert(res.Session);
        }, 1);
      });
    }
    // Initial form
    this.recoveryForm = this.formBuilder.group({
      // email: ['', [Validators.required, Validators.pattern(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)]],
      email: ["", [Validators.required]],
    });

    // get return url from route parameters or default to '/'
    this.returnUrl = this.route.snapshot.queryParams["returnUrl"] || "/";
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.recoveryForm.controls;
  }

  onSubmit() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.recoveryForm.invalid) {
      return;
    }
    this.loading = true; // uncomment

    this.error_current = "";
    this.authenticationService
      .passwordResetRequest(
        this.recoveryForm.value.email,
        this.currentCResponse
      )
      .pipe(first())
      .subscribe(
        (data) => {
          console.log(data);
          if (data.ResultCode == 0) {
            this.step1 = false;
            this.step2 = true;
          } else {
            this.error_current = data.ResultMessage;
          }
          this.loading = false;
        },
        (error) => {
          this.error_current = error; // Or create custom text on exist user
          console.log(error);
          // this.alertService.error(error);
          this.loading = false;
        }
      );
  }
  //----------------- helpers
  onClickErrorClear() {
    this.txt_title = null;
  }
}
