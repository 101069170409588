import {
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
  ElementRef,
  ɵConsole,
} from "@angular/core"
import { DatePipe } from "@angular/common"
import { LangChangeEvent, TranslateService } from "@ngx-translate/core"
import { untilDestroyed } from "ngx-take-until-destroy"
import { NgForm, FormControl, FormGroup, Validators } from "@angular/forms"
import { Globals } from "../../globals/globals"
import { FormCanDeactivate } from "../../_guards/form-can-deactivate"
import * as SecureLS from "secure-ls"
import { MatDialogRef } from "@angular/material/dialog"
import { MatDialog } from "@angular/material/dialog"
import { MatTableDataSource } from "@angular/material/table"
import { ReportsDatasService } from "../../_services/reportsdata.service"
import { first } from "rxjs/operators"
import { ActivatedRoute, Router } from "@angular/router"
import { ToastrService } from "ngx-toastr"
import { InvoicingService } from "../../_services/invoicing.service"
import { saveAs } from "file-saver"

import { trigger, state, animate, transition, style } from "@angular/animations"
import { environment } from "../../../environments/environment"

interface Emails {
  Name: string
  Email: string
}
interface Contacts {
  Name: string
  Email: string
  Phone: string
  Address: string
  Note: string
}

@Component({
  templateUrl: "invoice-create.component.html",
  animations: [
    trigger("inOutAnimation", [
      transition(":enter", [
        style({ height: 10, opacity: 0 }),
        animate(".5s ease-out", style({ height: 150, opacity: 1 })),
      ]),
      transition(":leave", [
        style({ height: 150, opacity: 1 }),
        animate(".5s ease-in", style({ height: 10, opacity: 0 })),
      ]),
    ]),
  ],
  styleUrls: ["invoice.scss"],
  providers: [DatePipe, Globals],
})
export class InvoiceCreateComponent
  extends FormCanDeactivate
  implements OnInit, OnDestroy
{
  basUrl: string = environment.apiUrl

  loader: boolean = false // Display dashboard when loading datas
  currentTime = new Date()
  minDate = new Date(2000, 0, 1) // Date picker min date
  maxDate = new Date(new Date().getFullYear() + 5, 0, 1)
  loading: boolean = false

  constructor(
    private invoicingService: InvoicingService,
    public translate: TranslateService,
    private datePipe: DatePipe,
    public dialogRef: MatDialogRef<InvoiceCreateComponent>,
    public dialog: MatDialog,
    private reportService: ReportsDatasService,
    private router: Router,
    public toastr: ToastrService,
    private route: ActivatedRoute
  ) {
    super()

    translate.onLangChange
      .pipe(untilDestroyed(this))
      .subscribe((event: LangChangeEvent) => {}) // Detect changes on the page | MAY BE DELETE
  }

  @ViewChild("form")
  form: NgForm
  salePaymentTypeLists = [];
  installmentLists = [];
  dateFromInput = new FormControl()
  dateToInput = new FormControl()
  validateDateDue(): boolean {
    return (
      this.datePipe.transform(this.dateFromInput.value, "yyyy-MM-dd") >
      this.datePipe.transform(this.dateToInput.value, "yyyy-MM-dd")
    )
  }
  CheckDates() {
    console.log(
      this.datePipe.transform(this.dateFromInput.value, "yyyy-MM-dd"),
      this.datePipe.transform(this.dateToInput.value, "yyyy-MM-dd")
    )
  }
  // mainInfo = {
  // 	MerchantInfo:{
  // 		Name: 'Default',
  // 		Address: 'Default',
  // 		Email: 'Default',
  // 		Phone: 'Default'
  // 	}
  // };
  expandContacts: boolean = false
  existingEmail: boolean = true
  mainInfo
  dublicateStorage =
    localStorage.getItem("dublicateFlag") == "true" ? true : false
  onlyReadInfo: boolean = false
  checkComments() {
    if (this.onlyReadInfo && !this.Comments) {
      return false
    } else return true
  }

  @ViewChild("iNumber") iNumber: ElementRef
  @ViewChild("fakeFocus") fakeFocus: ElementRef
  deFocus(event) {
    if (event.target.value.length > 0) {
      this.fakeFocus.nativeElement.focus()
      this.iNumber.nativeElement.focus()
    }
  }
  showInvoiceNumberError: boolean = false
  numberFocus(event) {
    let newValue = event.target.value.replace(/[^a-zA-Z0-9]/g, "")
    this.showInvoiceNumberError = event.target.value.length !== newValue.length
    event.target.value = newValue
    this.InvoiceNumber = newValue
  }
  //--------- for creating payment type
  salePaymentType = 1
  installmentId = 0
  //-----------------------------
  canDeleteRow = true
  InvoiceNumber: string
  ClientEmail: string
  emailList = [1, 2, 3, 4]
  Email = new FormControl({
    value: "",
    disabled: this.onlyReadInfo,
  }) // Email
  Name = new FormControl({
    value: "",
    disabled: this.onlyReadInfo,
  }) // Email
  PhoneNumber = new FormControl({
    value: "",
    disabled: this.onlyReadInfo,
  }) // Email
  Address = new FormControl({
    value: "",
    disabled: this.onlyReadInfo,
  }) // Email
  NewEmail = new FormControl("") // Email

  installmentIdCntrl = new FormControl({
    value: 0,
  })  
  
  contactFromExist: boolean = true
  btnTT = "some text"
  pasteFalse(event) {
    event.preventDefault()
  }
  showAllEmails(modal) {
    const dialogRef = this.dialog.open(modal, {
      panelClass: "modal_transaction",
    })
  }
  Comments: string = ""
  Taxes: Number = 0
  Discount: Number = 0
  Info
  Key_edit = this.route.snapshot.paramMap.get("id")
  submitted_btn = false
  save_btn = false
  getBool(str) {
    return str ? true : false
  }

  focusFunction(event) {
    event.target.value == 0 ? (event.target.value = "") : event.target.value
  }
  rowList = [
    {
      Description: "",
      Quantity: 0,
      Amount: "0",
    },
  ]
  totalSum: number = 0

  total(): number {
    if (this.rowList.length) {
      this.totalSum = 0
      this.rowList.forEach((element) => {
        this.totalSum =
          Number(this.totalSum) +
          Number(element.Quantity) * Number(element.Amount)
      })
      return Number(this.totalSum) * 100
    }
    return 0
  }

  toNumber(val): any {
    return val
  }
  addRow() {
    this.rowList.push({
      Description: "",
      Quantity: 0,
      Amount: "0",
    })
  }

  convertOnBlur(value, state, fixed?, target?, index?, maxLength?) {
    if (state) {
      if (Number(value) > 0) {
        let newValue = value,
          maxVal = 9999999.99
        if (target === "Amount") {
          newValue = parseFloat(value) > maxVal ? maxVal : value
          this.amountLength = 10
        }
        this.rowList[index][target] = parseFloat(newValue).toFixed(fixed)
      } else {
        this.rowList[index][target] = 0
      }
    } else {
      if (Number(value) > 0 && Number(value) <= 100) {
        this[target] = parseFloat(value).toFixed(fixed)
      } else if (Number(value) <= 0 || value === null) {
        this[target] = 0
      } else {
        this[target] = parseFloat("100").toFixed(fixed)
      }
    }
  }
  onBlurInput(event, type: string): void {
    let pattern: RegExp = type == "alphabs" ? /[^a-zа-я]/gi : /[^0-9]/gi
    event.target.value = event.target.value.replace(pattern, "")
  }

  onTypeChange(value, input, state, target?, index?) {
    if (state) {
      if (Number(value) < 0) {
        this.rowList[index][target] = 0
      }
    } else {
      if (Number(value) < 0) {
        this[target] = 0
      } else if (Number(value) > 100) {
        this[target] = 100
      } else {
        this[target] = value
      }
    }
  }
  amountLength: number = 7
  onKeyDown(event, input = "quantity") {
    let allowCodes = [
      8, 17, 37, 38, 39, 40, 46, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 96, 97,
      98, 99, 100, 101, 102, 103, 104, 105, 110,
    ]
    if (input === "amount") {
      allowCodes.push(190)
      if (event.target.value.search(/\./g) === -1) {
        this.amountLength = 7
      } else {
        this.amountLength = 10
      }
    }
    if (allowCodes.indexOf(event.keyCode) == -1) {
      event.preventDefault()
    }
  }
  allNumbers = [
    48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 96, 97, 98, 99, 100, 101, 102, 103,
    104, 105, 106, 107, 108, 109, 110, 111, 187, 189, 192,
  ]

  onlyAlphabs(evt) {
    evt = evt ? evt : event
    let charCode = evt.charCode
      ? evt.charCode
      : evt.keyCode
      ? evt.keyCode
      : evt.which
      ? evt.which
      : 0
    if (this.allNumbers.indexOf(charCode) !== -1) {
      return false
    }
  }
  onlyNumbers(e) {
    if (e.key.match(/[0-9cсvм]/gi || e.key.length > 0)) {
      return true
    } else {
      return false
    }
  }
  addressRows: number = 1
  checkAddressLength() {
    let lng = this.Address.value.length
    this.addressRows = Math.floor(lng / 34) + 1
  }
  onInputNotNumber(event) {
    event.target.value = event.target.value.replace(/[^0-9.]/g, "")
  }
  onInputPhone(event) {
    event.target.value = event.target.value.replace(/[^0-9.+]/g, "")
  }
  private markFormGroupTouched(formGroup: NgForm) {
    ;(<any>Object).values(formGroup.controls).forEach((control) => {
      control.markAsTouched()

      if (control.controls) {
        this.markFormGroupTouched(control)
      }
    })
  }

  preventE(event) {
    let allowCodes = [69, 187, 189]
    if (allowCodes.indexOf(event.keyCode) !== -1) {
      event.preventDefault()
    }
  }
  createInvoice(state) {
    // this.markFormGroupTouched(this.form);

    if (this.form.valid) {
      this.save_btn = true
      this.submitted_btn = true
      let finish =
          this.dateToInput.value === this.mainInfo.DateDue
            ? this.datePipe.transform(
                this.dateToInput.value,
                "yyyy-MM-ddThh:mm:ss"
              )
            : this.datePipe.transform(
                this.dateToInput.value,
                "yyyy-MM-ddT23:59:59"
              ),
        email = this.existingEmail ? this.Email.value : this.NewEmail.value

      this.dublicateStorage ? (this.Key_edit = "") : this.Key_edit

      this.loader = true

      this.reportService
        .saveInvoice(
          this.form.value,
          email,
          finish,
          this.total(),
          this.rowList,
          this.filesUpload,
          state,
          this.Key_edit,
          this.PhoneNumber.value,
          this.Address.value,
          this.Name.value,
          this.salePaymentType,
          this.installmentId
        )
        .subscribe(
          (data) => {
            console.log("Send Data:", data)
            if (data.ResultCode == 0) {
              if (state) {
                let theme = ""
                let body = ""
                this.translate
                  .get("INVOICE.THEME_RESEND_SUCCESS")
                  .subscribe((text) => {
                    theme = text
                  })
                this.translate
                  .get("INVOICE.BODY_RESEND_SUCCESS")
                  .subscribe((text) => {
                    body = text
                  })
                this.toastr.success(body, theme)

                setTimeout(() => {
                  this.router.navigate(["/invoice-history"])
                }, 3000)
              } else {
                let theme = ""
                let body = ""
                this.translate
                  .get("INVOICE.THEME_SAVE_SUCCESS")
                  .subscribe((text) => {
                    theme = text
                  })
                this.translate
                  .get("INVOICE.BODY_SAVE_SUCCESS")
                  .subscribe((text) => {
                    body = text
                  })
                this.toastr.success(body, theme)
                this.submitted_btn = false
                this.save_btn = false
                this.Key_edit = data.ReturnObject
                this.loader = false
              }
            } else {
              let body = ""
              data.ResultMessage.length === 0
                ? (body = "")
                : (body = data.ResultMessage)
              let theme = ""
              this.translate
                .get("INVOICE.THEME_INVOICE_ERROR")
                .subscribe((text) => {
                  theme = text
                })
              this.toastr.error(body, theme)
              this.save_btn = false
              this.submitted_btn = false
            }
          },
          (error) => {
            console.log(error)
            this.save_btn = false
            this.submitted_btn = false
          }
        )
    }
  }

  ObjectSize(obj) {
    var size = 0,
      key
    for (key in obj) {
      if (obj.hasOwnProperty(key)) {
        size++
      }
    }
    return size
  }

  deleteFile(index: number) {
    this.filesUpload.splice(index, 1)
  }

  deleteRow(index: number) {
    let test = [...this.rowList]
    test.splice(index, 1)
    this.rowList = []

    setTimeout(() => {
      // this.rowList = test;
      // for (let control in this.form.controls) {
      // }
      this.rowList = test
    }, 0)
  }

  test = true
  date = {
    From: new Date(),
    To: new Date(),
  }

  isCollapsed: boolean = true

  collapsed(event: any): void {
    // console.log(event);
  }

  expanded(event: any): void {
    // console.log(event);
  }
  printInvoice() {
    window.print()
  }
  isFileDownloading: boolean = false
  downloadInvoice() {
    // localhost:44305/api/invoicing/InvoicingDownloadPdf?pInvoiceKey=42E29973-6F4E-EA11-942F-005056B219F6
    this.isFileDownloading = true
    this.invoicingService.downloadPDF(this.Key_edit).subscribe(
      (data) => {
        // let blob: any = new Blob([data], {
        //   type: "text/pdf"
        // });
        // const url = window.URL.createObjectURL(blob);
        // window.open(url);
        saveAs(data, this.InvoiceNumber + "." + "pdf", {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        })
      },
      (error) => {
        console.log(error)
      },
      () => {
        this.isFileDownloading = false
      }
    )
  }
  filesUpload = []
  getInvoiceInfo() {
    this.loader = true

    if (this.dublicateStorage) {
      this.expandContacts = true
      this.invoicingService.getDuplicatedInvoice(this.Key_edit).subscribe(
        (data) => {
          if (data.ResultCode === 0) {
            this.mainInfo = data.ReturnObject
            this.InvoiceNumber = this.mainInfo["Number"]
            this.dateToInput.patchValue(this.mainInfo["DateDue"])
            this.filesUpload = this.mainInfo["Attachments"]
            this.Comments = this.mainInfo.Note
            this.canDeleteRow = false
            this.Email.setValue(this.mainInfo["Buyers"][0].Email)
            this.PhoneNumber.setValue(this.mainInfo["Buyers"][0].PhoneNumber)
            this.Address.setValue(this.mainInfo["Buyers"][0].Address)
            this.Name.setValue(this.mainInfo["Buyers"][0].Name)

            this.rowList = []
            this.mainInfo["Products"].forEach((product) => {
              this.rowList.push({
                Amount: (product.UnitPrice / 100).toFixed(2),
                Quantity: product.Quantity,
                Description: product.Description,
              })
            })
            this.Taxes = Number((this.mainInfo.TaxesValue / 100).toFixed(2))
            this.Discount = Number(
              (this.mainInfo.DiscountValue / 100).toFixed(2)
            )
            this.reportService.getEditInvoiceFiles(this.Key_edit).subscribe(
              (data) => {
                this.loader = true
                console.log(data)
                if (data.ResultCode === 0) {
                  this.filesUpload = data.ReturnObject
                } else {
                  this.filesUpload = []
                }
              },
              (error) => {
                console.log(error)
              },
              () => {
                this.canDeleteRow = true
                this.loader = false
              }
            )
          }
        },
        (error) => {
          console.log(error)
        },
        () => {
          this.loader = false
        }
      )
    } else {
      this.reportService.getCreateInvoice(this.Key_edit).subscribe(
        (data) => {
          this.loader = true
          if (data.ResultCode === 0) {
            this.mainInfo = data.ReturnObject
            this.InvoiceNumber = this.mainInfo.Number
            this.dateToInput.patchValue(this.mainInfo.DateDue)

            if (this.Key_edit !== null) {
              this.filesUpload = this.mainInfo.Attachments
              this.canDeleteRow = false
              this.Email.setValue(this.mainInfo["Buyers"][0].Email)
              this.PhoneNumber.setValue(this.mainInfo["Buyers"][0].PhoneNumber)
              this.Address.setValue(this.mainInfo["Buyers"][0].Address)
              this.Name.setValue(this.mainInfo["Buyers"][0].Name)
              this.Comments = this.mainInfo.Note
              this.expandContacts = true
              this.hideContactButton = true
              this.installmentIdCntrl.setValue(this.mainInfo["InstallmentId"] === null? 0 : this.mainInfo["InstallmentId"] );
              this.salePaymentType = this.mainInfo["SalePaymentType"] ;

              // this.ClientEmail = this.mainInfo.Buyers[0].Email;
              this.rowList = []
              this.mainInfo.Products.forEach((product) => {
                this.rowList.push({
                  Amount: (product.UnitPrice / 100).toFixed(2),
                  Quantity: product.Quantity,
                  Description: product.Description,
                })
              })
              this.Taxes = Number((this.mainInfo.TaxesValue / 100).toFixed(2))
              this.Discount = Number(
                (this.mainInfo.DiscountValue / 100).toFixed(2)
              )
              if (this.mainInfo.Status !== 0) {
                this.onlyReadInfo = true
                this.isCollapsed = false
              }
              console.log(
                "Main Info:",
                this.mainInfo,
                "IsCollapsed",
                this.isCollapsed
              )
              this.reportService.getEditInvoiceFiles(this.Key_edit).subscribe(
                (data) => {
                  this.loader = true
                  console.log(data)
                  if (data.ResultCode === 0) {
                    this.filesUpload = data.ReturnObject
                  } else {
                    this.filesUpload = []
                  }
                },
                (error) => {
                  console.log(error)
                },
                () => {
                  this.canDeleteRow = true
                  this.loader = false
                }
              )
            }
          }
        },
        (error) => {
          console.log(error)
        },
        () => {
          this.loader = false
        }
      )
    }
  }
  fileDownload = "#"
  fileHrefDownload = ""
  downloadFile(file) {
    const fileName = file.Name
    let byteCharacters = atob(file.Content)
    let byteNumbers = new Array(byteCharacters.length)

    for (var i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i)
    }

    let byteArray = new Uint8Array(byteNumbers)

    let blob = new Blob([byteArray], { type: file.Type })

    if (navigator.msSaveBlob) {
      let filename = file.Name
      navigator.msSaveBlob(blob, filename)
    } else {
      const downloadLink = document.createElement("a")

      downloadLink.href = URL.createObjectURL(blob)

      downloadLink.download = fileName // 'picture'
      downloadLink.click()
    }
  }
  allEmailsArray: Array<Emails> = [
    {
      Name: "All",
      Email: "Test@gmail.com",
    },
  ]
  shownEmails: Array<Emails>
  displayedContactColumns: Array<string> = [
    "Name",
    "Email",
    "Phone",
    "Address",
    "Notes",
  ]
  preventInput(event) {
    this.Email.setValue("")
  }
  public totalContacts // for display pagination from api
  public contactsPerPage // for display pagination from api
  isDownloadingFile: boolean = false
  public currentContactsPage = 0

  public handlePage(e: any) {
    this.currentPage = e.pageIndex
    this.paginationItemPerPage = e.pageSize
    this.getContacts()
  }
  contactDataSource = new MatTableDataSource() // array of datas
  public paginationItems // for display pagination from api
  public paginationItemPerPage // for display pagination from api

  public currentPage = 0
  chooseRow(row) {
    this.Email.setValue(row.Email)
    this.Name.setValue(row.Name)
    this.PhoneNumber.setValue(row.Phone)
    this.Address.setValue(row.Address)
    this.expandContacts = true
    this.existingEmail = true
    this.hideContactButton = true

    this.dialog.closeAll()
  }
  hideContactButton: boolean = false
  checkEmail() {
    if (this.existingEmail && this.Email.valid) {
      return true
    } else if (!this.existingEmail && this.NewEmail.valid) {
      return true
    } else {
      return false
    }
  }
  makeExpanded() {
    if (this.expandContacts && this.existingEmail) {
      this.existingEmail = true
      this.expandContacts = false
    } else {
      this.existingEmail = !this.existingEmail
      this.expandContacts = !this.expandContacts
      this.Name.setValue("")
      this.PhoneNumber.setValue("")
      this.Address.setValue("")
      this.NewEmail.setValue("")
    }
  }
  contactClick(contact) {
    this.expandContacts = true
    this.existingEmail = true
    this.Name.setValue(contact.Name)
    this.hideContactButton = true
    this.PhoneNumber.setValue(contact.Phone)
    this.Address.setValue(contact.Address)
  }
  getContacts() {
    this.loading = true
    this.invoicingService
      .getContactsListForCreate(
        this.currentPage + 1,
        this.paginationItemPerPage,
        this.ContactFilterForm.value
      )
      .subscribe(
        (data) => {
          //console.log(data);
          let datas = []
          this.paginationItems = 0
          if (data.ResultCode === 0) {
            if (data.ReturnObject !== null) {
              datas = data.ReturnObject.Contacts
              this.paginationItems = data.ReturnObject.Fetch.Cnt
            }
          }
          this.contactDataSource = new MatTableDataSource(datas) // array of datas
          this.loading = false
        },
        (error) => {
          this.loading = false
          console.log(error)
        },
        () => {}
      )
  }
  getAllEmails() {
    this.invoicingService.getAllEmails().subscribe(
      (data) => {
        this.loader = true
        if (data.ResultCode === 0) {
          if (data.ReturnObject !== null) {
            this.allEmailsArray = data.ReturnObject.Contacts
            this.shownEmails = this.allEmailsArray
          }
        }
      },
      (error) => {
        console.log(error)
      },
      () => {
        this.loader = false
      }
    )
  }
  CloseDialog(): void {
    this.dialog.closeAll()
  }
  isLoadingDetailInfo: boolean = false
  openModal(templateRef) {
    this.isLoadingDetailInfo = false
    this.dialog.open(templateRef, {
      panelClass: "modal_transaction",
      minHeight: "580px",
    })
  }

  ContactFilterForm: FormGroup

  @ViewChild("file") file

  addFiles() {
    this.file.nativeElement.click()
  }

  onFilesAdded(event) {
    let file
    for (var index in event.target.files) {
      if (event.target.files.hasOwnProperty(index)) {
        file = event.target.files[index]
        this.addFilesQuery(file)
      }
    }
  }

  addFilesQuery(file) {
    let type = file.type
    let name = file.name
    let size = file.size
    console.log("file size:", size)
    this.isDownloadingFile = true
    if (size > 8000000) {
      let theme = ""
      let body = ""
      this.translate.get("INVOICE.THEME_FILE_FAIL").subscribe((text) => {
        theme = text
      })
      this.translate.get("INVOICE.BODY_FILE_FAIL").subscribe((text) => {
        body = text
      })
      this.toastr.error(body, theme)

      return false
    }

    !type.length ? (type = file.name.split(".").pop()) : (type = file.type)
    let reader: any
    target: EventTarget
    reader = new FileReader()
    let code = ""
    reader.readAsDataURL(file) // read file as data url
    reader.onload = (event) => {
      // called once readAsDataURL is completed
      code = event.target.result.split(",")[1]
      console.log(event)
      this.filesUpload.push({
        Name: name,
        Type: type,
        Content: code,
      })
    }
    reader.onloadend = () => {
      console.log("File was uploaded!")
      this.isDownloadingFile = false
    }
    console.log(this.filesUpload)
  }

  progress
  uploading = false
  permissions;
  ngOnInit(): void {
    let ls = new SecureLS() // for Secure Local storage
    this.permissions = ls.get("permissions")[this.route.data["value"].pathCheck]

    if (ls.get("info") !== null) {
      this.Info = ls.get("info")
    }
    this.paginationItemPerPage = 10
    this.ContactFilterForm = new FormGroup({
      ContactName: new FormControl(""),
      ContactEmail: new FormControl(""),
    })
    this.dateFromInput = new FormControl({ value: new Date(), disabled: true })
    this.dateToInput = new FormControl({ value: new Date(), disabled: true })

    this.getInvoiceInfo()
    this.getAllEmails()
    this.functionInit()
  }
  ngOnDestroy() {
    localStorage.setItem("dublicateFlag", "false")
  }

  functionInit(){ 
    this.salePaymentTypeLists = [ 
    {
      text: "Sale",
      value: 1,
    },
    {
      text: "Installment",
      value: 2,
    },
    {
      text: "Account",
      value: 3,
    }];

    for(let i=0; i< this.salePaymentTypeLists.length; i++)
    {      
      this.translate.get("SALES.SALE_TYPE_"+(i+1)).subscribe((text) => {
        this.salePaymentTypeLists[i].text = text;
      });
    }  
    this.installmentId = 0;
    this.installmentIdCntrl.setValue(0);
    this.installmentIdCntrl.updateValueAndValidity();
  }

  onSalePaymentTypeChange(event)
  {
      this.installmentId = 0;
        if(event.value !== 2)        
          this.installmentIdCntrl.clearValidators()        
        else
        {
          this.installmentIdCntrl.setValidators([Validators.required,Validators.min(2),Validators.max(24),]);
          this.installmentIdCntrl.markAsTouched();
        }

        this.installmentIdCntrl.updateValueAndValidity();       
  }
}
