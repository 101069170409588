import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith, first } from 'rxjs/operators';
import { MatPaginator } from "@angular/material/paginator";
import { MatDialog } from "@angular/material/dialog";
import { MatTableDataSource } from "@angular/material/table";
import { ReportsDatasService } from '../../_services/reportsdata.service';
import { DatePipe } from '@angular/common';
import { TransactionService } from '../../_services/transaction.service';
import { Globals } from '../../globals/globals';
/** Constants used to fill up our data base. */

export interface ItemList {
    Value: string;
    Name: string;
}


@Component({
    templateUrl: 'deposit-operations.component.html',
    styleUrls: ['reports.scss'],
    providers: [DatePipe, Globals]
})
export class DepositOperationsComponent implements OnInit {

    public paginationItems; // for display pagination from api
    public paginationItemPerPage; // for display pagination from api

    public currentPage = 0;
    isLoadingDetailInfo: boolean = true; // spinner


    //-------------------------------------
    Transactions_Datas;
    dealerListOptions: ItemList[]; // dealer List
    loading = false; // for disable fast response click
    pageEvent; // for fix error of pageEvent in Visual Studio
    // For change pages

    minDate = new Date(2000, 0, 1); // Date picker min date
    maxDate = new Date(new Date().getFullYear() + 5, 0, 1); // Date picker max date
    events: string[] = []; // Date picker
    dealerFControl = new FormControl(); // Dealer


    dealerFilteredOptions: Observable<ItemList[]>; // dealer

    currentTime = new Date();
    //----------------- list of columns 
    displayedColumns: string[] = ['Ident', 'Source', 'Destination', 'TransactionDate', 'TransferedBy', 'Amount', 'Currency', 'Comments'];

    dataSource = new MatTableDataSource(); // array of datas
    @ViewChild(MatPaginator) paginator: MatPaginator;

    constructor(public dialog: MatDialog,
        // Make service for post api datas
        private datePipe: DatePipe,
        private reportsDataService: ReportsDatasService,
        private transactionService: TransactionService,
        private globals: Globals
    ) {

    }

    FilterForm: FormGroup; // Form initial
    //dealerDropValidator(controls): ValidatorFn {
    //    return (control: AbstractControl): { [key: string]: boolean } | null => {
    //        if ((control.value.length > 0) && (controls.indexOf(control.value) < 0)) {
    //            return { 'dealer': true };
    //        }
    //        return null;
    //    };
    //}
    ngOnInit() {

        this.paginationItemPerPage = 10;

        this.InitDealerFilter();

        this.FilterForm = new FormGroup({
            'Dealer': new FormControl(), //, [this.dealerDropValidator(this.options)]),
            'dateFrom': new FormControl({ value: this.currentTime, disabled: true }, Validators.required),
            'dateTo': new FormControl({ value: this.currentTime, disabled: true }, Validators.required),
        });
        this.getFirstDatas();
    }
    //----------------------  
    public handlePage(e: any) {
        this.currentPage = e.pageIndex;
        this.paginationItemPerPage = e.pageSize;
        this.getFirstDatas();

    }
    // function for change tables page

    //----------------------- get a dealer list and put to option's 
    InitDealerFilter() {
        // let dealers = localStorage.getItem('dealerAllList');
        // if (dealers === null) {
        //     this.transactionService.getDealerDatasAsList().pipe(first())
        //         .subscribe(
        //             data => {
        //                 localStorage.setItem('dealerAllList', JSON.stringify(data.ReturnObject));
        //                 this.dealerListOptions = data.ReturnObject.map(x => ({ Value: x.Id.toString(), Name: x.Name }));
        //                 this.dealerListOptions.unshift({ Value: '2', Name: 'All' });
        //                 this.dealerFilteredOptions = this.dealerFControl.valueChanges
        //                     .pipe(
        //                         startWith<string | ItemList>(''),
        //                         map(value => typeof value === 'string' ? value : value.Name),
        //                         map(name => name ? this._filter(name) : this.dealerListOptions.slice())
        //                     );//dealer
        //                 this.dealerFControl.setValue(this.dealerListOptions[0]);
        //             },
        //             error => {
        //                 console.log(error);
        //             });
        // } else {
        //     let ds = JSON.parse(dealers);
        //     this.dealerListOptions = ds.map(x => ({ Value: x.Id.toString(), Name: x.Name }));
        //     this.dealerListOptions.unshift({ Value: '2', Name: 'All' });
        //     this.dealerFilteredOptions = this.dealerFControl.valueChanges
        //         .pipe(
        //             startWith<string | ItemList>(''),
        //             map(value => typeof value === 'string' ? value : value.Name),
        //             map(name => name ? this._filter(name) : this.dealerListOptions.slice())
        //         );//dealer
        //     this.dealerFControl.setValue(this.dealerListOptions[0]);
        // }
    }

    getFirstDatas() {
        this.loading = true;
        let start = this.datePipe.transform(this.FilterForm.controls.dateFrom.value, 'yyyy-MM-ddT00:00:00');
        let finish = this.datePipe.transform(this.FilterForm.controls.dateTo.value, 'yyyy-MM-ddT23:59:59');
        // Take datas from form
        let dealerId;

        if ((typeof (this.dealerFControl.value) === 'string') || (this.dealerFControl.value === null)) {
            dealerId = '2';
        }
        else {
            dealerId = this.dealerFControl.value.Value;
        }

        let currentPage = this.currentPage + 1;

        this.reportsDataService.getAllDeposit(dealerId, start, finish, currentPage, this.paginationItemPerPage)
            .pipe(first())
            .subscribe(
                data => {
                    console.log(data);
                    if (data.ResultCode === 0) {
                        let obj = data.ReturnObject;
                        if (obj !== null) {
                            console.log(obj);
                            this.Transactions_Datas = obj.DepositOperations;
                            this.dataSource = new MatTableDataSource(this.Transactions_Datas); // array of datas
                            this.paginationItems = obj.Pagination.ItemTotal;
                        }
                        else {
                            this.paginationItems = 0;
                            this.Transactions_Datas = [];
                            this.dataSource = new MatTableDataSource(this.Transactions_Datas); // array of datas	
                        }
                    }
                    this.loading = false;
                },
                error => {
                    this.loading = false;
                    console.log(error);
                });
    }
    onSubmit() {
        this.getFirstDatas();
    }

    displayDealer(item?: ItemList): string | undefined {
        return item ? item.Name : undefined;
    }

    private _filter(item: string): ItemList[] {
        const filterValue = item.toLowerCase();
        return this.dealerListOptions.filter(option => option.Name.toLowerCase().indexOf(filterValue) === 0);
    }
    //----------------------
    PaginationCurentPageReset(e: any) {
        this.currentPage = 0;
    }
    //------------------- private helpers 
    // convenience getter for easy access to form fields
    get f() { return this.FilterForm.controls; }


    modalDatas = {};// for display datas in modal

}