import { Component, OnInit, ViewChild, HostListener, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';

import { AlertService, UserService, ProviderService } from '../../_services';
import { environment } from "../../../environments/environment";

@Component({
  templateUrl: "payment-page.component.html",
  styleUrls: ["payment-page.scss"],
})
export class PaymentPageComponent implements OnInit {
  basUrl: string = environment.apiUrl;

  providerTopList;
  providerGroupList;
  //providerList;
  isLoading: boolean = false; // Disable page when is loading datas
  target_height; // for change height
  //For collapse
  target; // for disable margin

  last_expanded; // Fix bug with expand
  payment_list_width;

  @ViewChild("insideCategorie") parentDiv: ElementRef;
  @ViewChild("payment_lists") paymentLists: ElementRef;

  // toggle(expanded) {
  //     console.log(expanded.expanded);
  //     expanded.expanded = !expanded.expanded;
  // }
  fixExpandedHeight() {
    let fix_height = document.querySelectorAll(
      ".mat-expansion-panel .mat-expanded"
    ) as NodeListOf<HTMLElement>;
    fix_height[0].setAttribute("style", "height : inherit !important");
    this.last_expanded = fix_height[0];
  }

  collapse(): void {
    let take_fix_height = document.getElementsByClassName(
      "main-col"
    ) as HTMLCollectionOf<HTMLElement>;
    let fixed_height = take_fix_height[0].offsetHeight + "px";
    this.last_expanded.setAttribute("style", "height : inherit !important");
  }
  // End fix bug with expand
  destroyed(): void {
    this.target.style.marginBottom = "1%";
    this.target.classList.remove("expanded");
    this.target = null;
  }

  expanded(): void {
    let target_margin = document.getElementsByClassName(
      "mat-expansion-panel-spacing"
    ) as HTMLCollectionOf<HTMLElement>;
    let target = document.querySelectorAll(
      ".mat-expansion-panel-spacing .tables-content"
    ) as NodeListOf<HTMLElement>;
    let target_height = target[0].offsetHeight;
    let current_margin = target_height + 30;
    let parent = <HTMLElement>(<any>target_margin[0].parentElement);
    parent.style.marginBottom = current_margin + "px";
    parent.classList.add("expanded");
    this.target = parent;

    this.fixExpandedHeight();
  }
  // End for collapse
  constructor(private providerService: ProviderService) {}

  getDatas() {
    let prvTop = localStorage.getItem("providerTop");
    if (prvTop === null) {
      this.providerService
        .TopList()
        .pipe(first())
        .subscribe(
          (data) => {
            this.providerTopList = data;
            localStorage.setItem("providerTop", JSON.stringify(data));
          },
          (error) => {
            console.log(error);
          }
        );
    } else this.providerTopList = JSON.parse(prvTop);

    let prvGroup = localStorage.getItem("providerGroup");
    if (prvGroup === null) {
      this.providerService
        .GroupList()
        .pipe(first())
        .subscribe(
          (data) => {
            this.providerGroupList = data;
            console.log(this.providerGroupList);
            this.isLoading = true;
            localStorage.setItem("providerGroup", JSON.stringify(data));
            setTimeout((_) => {
              if (this.parentDiv) {
              }
            });
          },
          (error) => {
            console.log(error);
            this.isLoading = true;
          }
        );
    } else {
      this.providerGroupList = JSON.parse(prvGroup);
      console.log(this.providerGroupList);
      /*     for (let i = 0; i < this.providerGroupList.length; i++) {
                this.providerGroupList[i].expanded = false;
            } */
      this.isLoading = true;
    }
  }

  ngOnInit() {
    console.log("init a payment page ...");
    this.isLoading = false;
    this.getDatas();
  }
}