import {
  Component,
  HostListener,
  OnInit,
  Input,
  OnDestroy,
} from "@angular/core";
import { navItems } from "./../../_nav";
import { TranslateService, LangChangeEvent } from "@ngx-translate/core";
import * as screenfull from "screenfull";
import { MainService } from "../../_services/main.service";
import { ProfileService } from "../../_services/profile.service";
import { Router, ActivatedRoute } from "@angular/router";
import * as SecureLS from "secure-ls";
import { MatDialog } from "@angular/material/dialog";
import { Title } from "@angular/platform-browser";
import { DateAdapter } from "@angular/material/core";
import { environment } from "../../../environments/environment";

@Component({
  selector: "app-dashboard",
  templateUrl: "./default-layout.component.html",
})
export class DefaultLayoutComponent implements OnInit, OnDestroy {
  basUrl: string = environment.apiUrl;

  public navItems = navItems;
  public sidebarMinimized = true;
  private changes: MutationObserver;
  public element: HTMLElement = document.body;
  mobileSidebarToggler;
  currentLang;
  dropdown1;
  dropdownContactMenu;
  dropdown3;
  dropdown4;
  dropdown5;
  dropdown6;
  dropdown7;
  dropdownIn;

  constructor(
    public dialog: MatDialog,
    public translate: TranslateService,
    private mainService: MainService,
    private profileService: ProfileService,
    private router: Router,
    private titleService: Title,
    private dateAdapter: DateAdapter<any>,

    public route: ActivatedRoute
  ) {
    if (localStorage.getItem("currentLang")) {
      this.currentLang = localStorage.getItem("currentLang");
    } else {
      this.currentLang = translate.getBrowserLang();
    }
    translate.addLangs(["en", "tr", "ru"]);
    const browserLang = this.currentLang; // This variable change lang
    translate.use(browserLang.match(/en|tr|ru/) ? browserLang : "tr");

    translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.currentLang = event.lang;
      localStorage.setItem("currentLang", this.currentLang);
      this.dateAdapter.setLocale(this.currentLang);
    }); // Detect changes on the page
  }

  // Variables
  loadingNotifications: boolean = false;
  notificationQuantity = 0;
  permissions = {};
  notificationLoading = false;
  Info;
  showMenu: boolean = false;
  messageOpened;
  // End Variables
  notifications = [];
  interval;

  getNotificationQuantity() {
    this.notificationQuantity = 0;

    this.mainService.getQuantityNotification().subscribe(
      (data) => {
        if (data.ResultCode === 0) {
          //console.log('Notification was setted.');
          if (data.ReturnObject.Inline != null) {
            this.notificationQuantity = data.ReturnObject.Inline.UnReadCount;
          }
        }
        this.interval = setTimeout(() => {
          this.getNotificationQuantity();
        }, 60000);
      },
      (error) => {
        console.log(error);
      }
    );
  }

  getNotifications() {
    this.loadingNotifications = true;
    this.notificationQuantity = 0;
    this.notifications = [];
    // Get all notifications
    this.mainService.getNotifications().subscribe(
      (data) => {
        if (data.ResultCode === 0) {
          if (data.ReturnObject.Items != null) {
            this.notifications = data.ReturnObject.Items;
          }
          if (data.ReturnObject.Inline != null) {
            this.notificationQuantity = data.ReturnObject.Inline.UnReadCount;
          }
        }
        this.loadingNotifications = false;
      },
      (error) => {
        this.loadingNotifications = false;
        console.log(error);
      }
    );
  }

  readNotification(templateRef, message) {
    this.notificationQuantity = 0;

    //console.log(message);
    this.mainService.markAsRead(message.Key).subscribe(
      (data) => {
        if (data.ReturnObject.Inline != null) {
          this.notificationQuantity = data.ReturnObject.Inline.UnReadCount;
        }
      },
      (error) => {
        console.log(error);
      }
    );
    this.messageOpened = message;
    this.dialog.open(templateRef, {
      panelClass: "modal_notification",
    });
  }
  public setTitle(newTitle: string) {
    this.titleService.setTitle(newTitle);
  }
  // End Variables
  getCurrentTranslateRoute() {
    let str = this.titleService.getTitle().match(/.+\|/)[0].slice(0, -2),
      dms = "Cardplus Cep Pos";
    this.translate.get(`GLOBAL.TITLE_POPCY`).subscribe((text) => {
      dms = text;
    });
    str.match(/GLOBAL\./)
      ? this.translate.get(str).subscribe((text) => {
          str = text;
        })
      : str;
    this.setTitle(`${str}  | ${dms}`);
    return str;
  }
  toggleFull() {
    if (screenfull.isEnabled) {
      screenfull.toggle();
    }
  }

  CloseDialog(): void {
    this.dialog.closeAll();
  }

  getUserDatas() {
    this.mainService
      .getProfile()
      .pipe()
      .subscribe(
        (data) => {
          //console.log(data);
          if (data.ResultCode === 0) {
            this.Info = data.ReturnObject;
            let ls = new SecureLS(); // for Secure Local storage
            ls.set("info", this.Info);
            //console.log(this.Info);
          }
          this.showMenu = true;
        },
        (error) => {
          console.log(error);
          this.showMenu = false;
        },
        () => {
          this.getRoles();
        }
      );
  }

  getRoles() {
    let ls = new SecureLS(); // for Secure Local storage

    this.profileService
      .getUserRights()
      .pipe()
      .subscribe(
        (data) => {
          console.log(data);
          if (data.ResultCode === 0) {
            let test_permissions = data.ReturnObject;
            //test_permissions['invoice'] = {};
            ls.set("permissions", test_permissions);
          }
        },
        (error) => {
          console.log(error);
          this.showMenu = false;
        },
        () => {
          if (ls.get("permissions") !== null) {
            this.permissions = ls.get("permissions");
            this.showMenu = true;
            let path = this.route.children[0].data["value"].pathCheck;
            if (
              typeof this.permissions[path] === "undefined" &&
              typeof path !== "undefined"
            ) {
              this.router.navigate(["/dashboard"]);
            }
          } else {
            this.router.navigate(["/login"]);
          }
        }
      );
  }

  ngOnInit() {
    //console.log('default-layout loading');
    this.getNotificationQuantity();
    this.getUserDatas();
  }

  ngOnDestroy() {
    clearInterval(this.interval);
  }
}
