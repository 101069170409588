import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, FormControl, Validators, AbstractControl, ValidatorFn, FormGroupDirective, NgForm } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith, first } from 'rxjs/operators';
import { AlertService, UserService, ProviderService } from '../../_services';
//import { MatPaginator, MatTableDataSource, MatDatepickerInputEvent, MatDialog, DateAdapter, ErrorStateMatcher } from '@angular/material';
import { DataSource } from '@angular/cdk/table';
import { DatePipe } from '@angular/common';
/** Constants used to fill up our data base. */
import * as SecureLS from 'secure-ls';
import { SettingsService } from '../../_services/settings.service';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';


@Component({
    templateUrl: 'notifications.component.html',
    styleUrls: ['settings.scss'],
    providers: [DatePipe]
})



export class NotificationsComponent implements OnInit {
    private markFormGroupTouched(formGroup: FormGroup) {
        (<any>Object).values(formGroup.controls).forEach(control => {
            control.markAsTouched();

            if (control.controls) {
                this.markFormGroupTouched(control);
            }
        });
    }
    constructor(
        public toastr: ToastrService,
        public translate: TranslateService,
        private formBuilder: FormBuilder,
        private settingsService: SettingsService,
    ) {

    }
    loading: boolean = false;
    Info;
    loading_page: boolean = false;
    NotificationList: FormGroup;
    serviceProviders;

    saveNotifications() {
        this.loading = true;
        let datas = [];
        this.serviceProviders.forEach(changedNot => {
            datas.push({ GroupNumber: changedNot.GroupNumber, Inline: Number(this.NotificationList.value['Inline_' + changedNot.Id]), EMAIL: Number(this.NotificationList.value['EMAIL_' + changedNot.Id]) })
        });
        console.log(datas); // need to send
        this.settingsService.saveNotificationSettings(datas).pipe(first())
            .subscribe(
                data => {
                    console.log(data);
                    if (data.ResultCode !== 0) {
                        this.loading = false;
                        let theme = '';
                        let body = '';
                        data.ResultMessage === null ? body = '' : body = data.ResultMessage;
                        this.translate.get('PROFILE.THEME_NOTIFICATIONS_FORM_ERROR').subscribe((text) => { theme = text; });
                        this.toastr.error(body, theme);
                    }
                    else {
                        let theme = '';
                        let body = '';
                        this.translate.get('PROFILE.THEME_NOTIFICATIONS_FORM_SUCCESS').subscribe((text) => { theme = text; });
                        this.translate.get('PROFILE.BODY_NOTIFICATIONS_FORM_SUCCESS').subscribe((text) => { body = text; });
                        this.toastr.success(body, theme);
                    }
                },
                error => {
                    let body = '';
                    this.translate.get('PROFILE.BODY_NOTIFICATIONS_FORM_WRONG').subscribe((text) => { body = text; });
                    this.toastr.error(error, body);
                    this.loading = false;
                    console.log(error);
                });
    }

    getNotificationSettings() {
        this.settingsService.getNotificationSettings().pipe(first())
            .subscribe(
                data => {
                    this.loading = true;
                    console.log(data);
                    if (data.ResultCode === 0) {
                        this.serviceProviders = data.ReturnObject;
                        if (this.serviceProviders.length) {
                            let counter = 0;
                            this.serviceProviders.forEach(service => {
                                service['Id'] = counter;
                                this.serviceProviders
                                this.NotificationList.addControl('Inline_' + service.Id, new FormControl(Boolean(service.InLine))); // Initialize form rules
                                this.NotificationList.addControl('EMAIL_' + service.Id, new FormControl(Boolean(service.EMAIL))); // Initialize form rules
                                counter++;
                            });
                            this.NotificationList.valueChanges.subscribe(() => {
                                this.loading = false;
                            });
                        }
                    }
                    this.loading_page = true;
                },
                error => {
                    console.log(error);
                    this.loading_page = true;
                });
    }

    ngOnInit() {
        this.NotificationList = this.formBuilder.group({
        });

        this.getNotificationSettings();
        let ls = new SecureLS(); // for Secure Local storage
        if (ls.get('info') !== null) {
            this.Info = ls.get('info');
        }
    }
}