import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { map } from "rxjs/operators";
import { AuthenticationService } from "./authentication.service";

// const httpOptions = {
//   headers: new HttpHeaders({
//     'Content-Type':  'application/x-www-form-urlencoded;charset=UTF-8',
//     'Accept-Language': 'en-EN',
// })
// };
@Injectable()
export class DashboardService {
  constructor(
    private http: HttpClient,
    private authService: AuthenticationService
  ) {}

  // Transactions Datas

  getFile(date_from, date_to, interval, format) {
    if (format === "pdf") {
      return this.http.get(
        this.authService.basUrl +
          `/api/Dashboard/ExportPdf?PeriodType=` +
          interval +
          `&DateStart=` +
          date_from +
          `&DateEnd=` +
          date_to,
        {
          responseType: "blob",
          headers: { "Accept-Language": this.GetLang() },
        }
      );
    }
    return this.http.get(
      this.authService.basUrl +
        `/api/Dashboard/ExportExcel?PeriodType=` +
        interval +
        `&DateStart=` +
        date_from +
        `&DateEnd=` +
        date_to,
      {
        responseType: "blob",
        headers: { "Accept-Language": this.GetLang() },
      }
    );
  }

  getWidgetsData(start, end, period) {
    return this.http
      .get<any>(
        this.authService.basUrl +
          `/api/Dashboard/WebWidget?pStartDate=${start}&pEndDate=${end}&PeriodType=${period}`,
        {
          headers: { "Accept-Language": this.GetLang() },
        }
      )
      .pipe(
        map((response) => {
          if (this.authService.checkTokenResponse()) {
            return response;
          }
        })
      );
  }

  getMainChartDatas(start, finish, period) {
    return this.http
      .get<any>(
        this.authService.basUrl +
          `/api/Dashboard/OrderChart?PeriodType=` +
          period +
          `&DateStart=` +
          start +
          `&DateEnd=` +
          finish,
        { headers: { "Accept-Language": this.GetLang() } }
      )
      .pipe(
        map((response) => {
          if (this.authService.checkTokenResponse()) {
            return response;
          }
        })
      );
  }

  getSummary() {
    return this.http
      .get<any>(this.authService.basUrl + `/api/Dashboard/OrderSummary`, {
        headers: { "Accept-Language": this.GetLang() },
      })
      .pipe(
        map((response) => {
          if (this.authService.checkTokenResponse()) {
            return response;
          }
        })
      );
  }

  getOverview() {
    return this.http
      .get<any>(this.authService.basUrl + `/api/Dashboard/Overview`, {
        headers: { "Accept-Language": this.GetLang() },
      })
      .pipe(
        map((response) => {
          if (this.authService.checkTokenResponse()) {
            return response;
          }
        })
      );
  }

  private GetLang() {
    switch (localStorage.getItem("currentLang")) {
      case "ru":
        return "ru-RU";
      case "tr":
        return "tr-TR";
    }
    return "en-US";
  }
}
