import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'AmountPipeEx'
})
export class AmountPipeEx implements PipeTransform {
    transform(value: any, currency: string = ''): string {
        if (currency === undefined)
            currency = '';
        if (typeof value === 'number')
            return (value / 100).toFixed(2) + ' ' + currency;
            return value;
    }
}
