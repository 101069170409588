import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators, FormControl, FormGroupDirective, NgForm } from '@angular/forms';
import { first } from 'rxjs/operators';

import { AlertService, UserService } from '../../_services';
//import { ErrorStateMatcher } from '@angular/material/core';
import { ProfileService } from '../../_services/profile.service';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';

@Component({
	templateUrl: 'newpassword.component.html',
	styleUrls: ['settings.scss']
})
export class NewPasswordComponent implements OnInit {
	loading: boolean = false;
	submitted: boolean = false;
	constructor(
		private formBuilder: FormBuilder,
		private router: Router,
		public translate: TranslateService,
		public toastr: ToastrService,
		private profileService: ProfileService) {

		this.ChangePasswordForm = this.formBuilder.group({
			'OldPassword': ['', Validators.required],
			'Password': ['', [Validators.required, Validators.pattern(/^(?=(.*[a-z]){1,})(?=(.*[\d]){1,})(?=(.*[\W]){1,})(?!.*\s).{8,32}$/), Validators.minLength(8), Validators.maxLength(32)]],
			'RepeatPassword': ['', [Validators.required]]
		});
	}

	ChangePasswordForm: FormGroup;

	ngOnInit() {

	}

	checkPasswords(group: FormGroup) { // here we have the 'passwords' group and check repeat password | Custom validation
		let password = group.controls.Password.value;
		let repeatpassword = group.controls.RepeatPassword.value;

		return password === repeatpassword ? null : { notSame: true }
	}


	onSubmit() {
		console.log(this.ChangePasswordForm);
		this.submitted = true;
		this.ChangePasswordForm.markAsTouched;
		if (this.ChangePasswordForm.valid === true) {
			this.loading = true;
			this.submitted = false;
			this.profileService.profileChangePassword(this.ChangePasswordForm.value.OldPassword, this.ChangePasswordForm.value.Password, this.ChangePasswordForm.value.RepeatPassword)
				.pipe(first())
				.subscribe(
					data => {
						console.log(data);
						if (data.ResultCode === 0) {
							console.log('Change Password');
							this.loading = false;
							this.ChangePasswordForm.reset();
							let theme = '';
							let body = '';
							this.translate.get('PROFILE.THEME_NEWPASSWORD_SUCCESS').subscribe((text) => { theme = text; });
							this.translate.get('PROFILE.BODY_NEWPASSWORD_SUCCESS').subscribe((text) => { body = text; });
							this.toastr.success(body, theme);
						}
						else {
							this.loading = false;
							let theme = '';
							let body = '';
							data.ResultMessage === null ? body = '' : body = data.ResultMessage;
							this.translate.get('PROFILE.THEME_NEWPASSWORD_ERROR').subscribe((text) => { theme = text; });
							this.toastr.error(body, theme);
						}
					},
					error => {
						this.loading = false;
						let theme = '';
						this.translate.get('PROFILE.THEME_NEWPASSWORD_ERROR').subscribe((text) => { theme = text; });
						this.toastr.error(error, theme);
						console.log(error);
					});
		}
	}
}